import { ChangeEvent, FormEvent, useState } from "react";
import { LogInFooter } from "../components/LogInFooter/LogInFooter";
import { LogInForm, LoginFormInputs } from "../components/LogInForm/LogInForm";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth/useAuth";
import { userLogin } from "../apis/energyNetApi";
import { LogInHeader } from "../components/LogInHeader/LogInHeader";
import { ResponsiveLayout } from "../components/Shared/ResponsiveLayout";
import { useSignUpStep } from "../hooks/useSignUpStep/useSignUpStep";
import { useTranslation } from "react-i18next";
import { isAxiosError } from "axios";

export const LogIn = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [formInputs, setFormInputs] = useState<LoginFormInputs>({
    email: "",
    password: ""
  });

  const { t } = useTranslation();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    (event.target as HTMLInputElement).setCustomValidity("");
    setFormInputs({
      ...formInputs,
      [event.target.name]: event.target.value,
    });
  };

  const [busy, setBusy] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [signUpStep, setSignUpStep] = useSignUpStep();

  if (signUpStep) {
    // clear signup step in case they switched accounts
    setSignUpStep(null);
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setErrorMessage("");
    setBusy(true);

    const logindata: LoginFormInputs = {
      email: formInputs.email.trim(),
      password: formInputs.password,
    };

    try {
      const response = await userLogin(logindata.email, logindata.password);
      if (!response.data.Token) {
        setBusy(false);
        setErrorMessage("There was a system error logging in. Please try again later.");
        return;
      }
      login(response.data.Token, logindata.email);
    } catch (error: unknown) {
      setBusy(false);

      if (isAxiosError(error)) {
        if (error.response?.status === 423) {
          setErrorMessage(t("Your account has been locked out due to excessive authentication failures. Please try again later."));
          return;
        }
      }
      setErrorMessage(t("You have entered an invalid username or password"));
    }
  };

  const handleSignUpButtonClick = () => {
    navigate("/signup");
  };

  return (
    <>
      <ResponsiveLayout>
        <>
          <LogInHeader />
          <div className={"form-contents-container"}>
            <LogInForm
              inputs={formInputs}
              onChange={handleChange}
              onSubmit={handleSubmit}
              busy={busy}
              errorMessage={errorMessage}
              onSignUpButtonClick={handleSignUpButtonClick}
            />
          </div>
        </>
      </ResponsiveLayout>
      <LogInFooter />
    </>
  );
};
