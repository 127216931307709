import { useTranslation } from "react-i18next";
import IconButton from "../Shared/IconButton";
import infoButton from "../../static/ui/infoIcon.svg";
import ToggleSwitch from "../Shared/ToggleSwitch";
import { useState } from "react";
import useBrandConfig from "../../hooks/useBrandConfig";
import isDayBeforeMonth from "../../helpers/isDayBeforeMonth";

export type DemandResponseEventProps = {
  id: string;
  startDateUtc: string;
  endDateUtc: string;
  optOut: boolean;
  extraData?: string;
  showModal: () => void;
  changeOptOut: (id: string, optOut: boolean) => void;
};

function formatDate(startDateUtc: Date, endDateUtc: Date, dayBeforeMonth: boolean): string {
  // these conversions change the utc to local time
  const startMonth = startDateUtc.getMonth() + 1;
  const startDay = startDateUtc.getDate();
  const endMonth = endDateUtc.getMonth() + 1;
  const endDay = endDateUtc.getDate();

  const startHour = startDateUtc.getHours();
  const startMinutes = startDateUtc.getMinutes();
  const endHour = endDateUtc.getHours();
  const endMinutes = endDateUtc.getMinutes();

  if (isNaN(startHour) || isNaN(startMinutes) || isNaN(endHour) || isNaN(endMinutes)) {
    return "Date is invalid";
  }

  const startTime = `${startHour % 12 || 12}${startMinutes === 0 ? "" : startMinutes < 10 ? `:0${startMinutes}` : `:${startMinutes}`}${startHour < 12 ? 'am' : 'pm'}`;
  const endTime = `${endHour % 12 || 12}${endMinutes === 0 ? "" : endMinutes < 10 ? `:0${endMinutes}` : `:${endMinutes}`}${endHour < 12 ? 'am' : 'pm'}`;
  const monthDayFormat = (month: number, day: number) => dayBeforeMonth ? `${day}/${month}` : `${month}/${day}`;

  if (startDay === endDay) {
    return `${monthDayFormat(startMonth, startDay)} ${startTime} - ${endTime}`;
  } else {
    return `${monthDayFormat(startMonth, startDay)} ${startTime} - ${monthDayFormat(endMonth, endDay)} ${endTime}`;
  }
}

const DemandResponseEvent = ({ id, startDateUtc, endDateUtc, optOut, extraData, showModal, changeOptOut }: DemandResponseEventProps) => {
  const { t } = useTranslation();
  const startDateLocal = new Date(startDateUtc);
  const endDateLocal = new Date(endDateUtc);
  const [newOptOutState, setNewOptOutState] = useState(optOut);
  const { demandResponseName } = useBrandConfig();
  //const dayBeforeMonth = useMemo(() => isDayBeforeMonth(), []);
  const dayBeforeMonth = isDayBeforeMonth();

  let dateString = formatDate(startDateLocal, endDateLocal, dayBeforeMonth);

  const handleDemandResponseToggleClick = () => {
    setNewOptOutState(!optOut);
    changeOptOut(id, !optOut);
  };

  return (
    // convert startDateUtc and endDateUtc to local time
    <div className="bg-[#00E16C]/[.10] rounded-[13px] w-full h-[92px] mt-[10px]">
      <div className="pt-[10px] flex flex-row justify-left items-center">
        <div className="pl-[15px] text-sm">{demandResponseName}</div>
        {extraData &&
          <div className="pl-[15px] text-xs text-[#929292]">{extraData}</div>}
      </div>
      <div className="flex flex-row justify-between items-center pt-[10px] pl-[15px] ">
        <div className="flex items-center">
          <div className="h-[19px] w-[19px] mr-[10px] md:mr-[20px]">
            <IconButton image={infoButton} onClick={showModal} altText={demandResponseName} />
          </div>
          <div className="text-lg md:text-xl">{dateString}</div>
        </div>
        <ToggleSwitch
          testId={`demand-switch-${id}`}
          checked={!optOut} loading={newOptOutState !== optOut}
          handleChangeToggle={handleDemandResponseToggleClick}
          stateText={!optOut ? t("YES") : t("NO")} />
      </div>
    </div>
  );
};

export default DemandResponseEvent;