import { usePlacesWidget } from "react-google-autocomplete";
import getEnv from "../../env";
import { useState } from "react";

export type Address = {
  addressLineOne: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  latitude?: number;
  longitude?: number;
};

export const convertGoogleAddressComponentsToAddress = (
  googleAddressComponents: google.maps.GeocoderAddressComponent[],
  latitude?: number,
  longitude?: number
): Address => {
  const address: Address = {
    addressLineOne: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
    latitude,
    longitude,
  };
  let streetNumber = "";
  let route = "";
  let neighborhood = "";
  googleAddressComponents.forEach((googleAddressComponent) => {
    const { short_name, long_name } = googleAddressComponent;
    googleAddressComponent.types.forEach((componentType) => {
      switch (componentType) {
        case "street_number":
          streetNumber = short_name;
          break;
        case "route":
          route = short_name;
          break;
        case "neighborhood":
          neighborhood = short_name;
          break;
        case "locality":
        case "postal_town":
          address.city = long_name;
          break;
        case "administrative_area_level_1":
          address.state = long_name;
          break;
        case "country":
          address.country = long_name;
          break;
        case "postal_code":
          address.postalCode = short_name;
          break;
      }
    });
  });

  if (streetNumber && route) {
    address.addressLineOne = `${streetNumber} ${route}`;
  } else if (streetNumber) {
    address.addressLineOne = streetNumber;
  } else if (route) {
    address.addressLineOne = route;
  }
  if (neighborhood) {
    address.addressLineOne += ` ${neighborhood}`;
  }

  return address;
};

// UI uses full country names, but Google uses country codes
const convertCountryToGoogleCountryCode = (country: string): string => {
  switch (country.toLowerCase()) {
    case "ireland":
      return "ie";
    case "united states":
      return "us";
    default:
      return country;
  };
}

const useAddressAutoCompleteWidget = ({
  defaultCountry,
  onAddressSelected }:
  { onAddressSelected: (address: Address) => void, defaultCountry: string }) => {
  const [countryCode, setCountryCode] = useState<string>(convertCountryToGoogleCountryCode(defaultCountry));
  const { ref } = usePlacesWidget<HTMLInputElement>({
    apiKey: getEnv().GOOGLE_MAPS_API_KEY,
    onPlaceSelected: (place) => {
      if (place && place.address_components !== undefined) {
        const latitude = place.geometry?.location?.lat();
        const longitude = place.geometry?.location?.lng();

        onAddressSelected(
          convertGoogleAddressComponentsToAddress(place.address_components, latitude, longitude)
        );
      }
    },
    options: {
      types: ["address"],
      componentRestrictions: { country: countryCode },
    },
  });

  const handleCountryChange = (newCountry: string) => {
    setCountryCode(convertCountryToGoogleCountryCode(newCountry));
  };

  return { ref, handleCountryChange };
}

export const getGoogleLatLng = async (address: string): Promise<{ latitude: number, longitude: number } | undefined> => {
  try {
    const response = await fetch(`/geocode?address=${encodeURIComponent(address)}`);
    const data = await response.json();

    if (data.results && data.results.length > 0) {
      const { lat, lng } = data.results[0].geometry.location;
      return { latitude: lat, longitude: lng }
    }
  } catch (error) {
    console.error("Error getting lat/lng from Google", error);
  }
  return undefined;
};

export default useAddressAutoCompleteWidget;
