export const PillButton = ({ text, icon, img, onClick }:
  { text: string; icon?: string; img?: string; onClick: () => void }) => {
  return (
    <button
      className={`font-sans-bold text-lg h-[32px] bg-transparent
                  text-action flex justify-between items-center
                  border-2 border-action rounded-[30px] py-[1px] px-[6px]`}
      onClick={onClick}
    >
      <span className={"ml-[18px] mr-[20px]"}>
        {text}
      </span>
      {(icon || img) &&
        <span className={"text-[24px] mr-[10px] ml-0"}>{icon || <img src={img} alt={text} />}</span>
      }
    </button>
  )
};
