import getEnv from "../env";

export const VERIFY_EMAIL_URL = getEnv().BACKEND_API + "/api/v3/Access/VerifyEmail";

export const SIGN_UP_URL = getEnv().BACKEND_API + "/api/v3/User/CreateSiteUser";

export const SIGN_UP_URL_RECAPTCHA = getEnv().BACKEND_API + "/api/v3/User/CreateSiteUser2";

export const LOG_IN_URL = getEnv().BACKEND_API + "/api/v3/Access/SiteLogin";

export const CHANGE_PROGRAM_URL = getEnv().BACKEND_API + "/api/v3/User/ChangeProgram";

export const USER_INFO_URL = getEnv().BACKEND_API + "/api/v3/User/Info";

export const USER_CHANGE_NOTIFICATION_PHONE_URL =
  getEnv().BACKEND_API + "/api/v3/User/changeNotificationPhoneNumber";

export const REQUEST_PASSWORD_RESET_URL =
  getEnv().BACKEND_API + "/api/v3/User/RequestPasswordReset";

export const LOG_OUT_URL = getEnv().BACKEND_API + "/api/v3/Access/Logout";

export const GET_DEVICES_URL =
  getEnv().BACKEND_API + "/api/v3/AccountDevices/GetDevices";

export const GET_DEVICE_STATUS_URL =
  getEnv().BACKEND_API + "/api/v3/AccountDevices/GetDeviceStatus";

export const GET_STATUS_URL =
  getEnv().BACKEND_API + "/api/v3/AccountDevices/GetStatus";

export const GET_CAR_STATE_URL =
  getEnv().BACKEND_API + "/api/v3/AccountDevices/GetCarState";

export const DELETE_DEVICE_URL =
  getEnv().BACKEND_API + "/api/v3/AccountDevices/DeleteDevice";

export const GET_DEVICE_PREFERENCES_URL =
  getEnv().BACKEND_API + "/api/v3/AccountDevices/GetDevicePreferences";

export const SET_DEVICE_PREFERENCES_URL =
  getEnv().BACKEND_API + "/api/v3/AccountDevices/SetDevicePreferences";

export const GET_OPT_IN_AFTER_URL =
  getEnv().BACKEND_API + "/api/v3/AccountDevices/GetOptInAfter";

export const SET_OPT_IN_AFTER_URL =
  getEnv().BACKEND_API + "/api/v3/AccountDevices/SetOptInAfter";

export const ADD_UNSUPPORTED_EV_URL =
  getEnv().BACKEND_API + "/api/v3/AccountDevices/AddUnsupportedEV";

export const GET_CHARGING_LOCATIONS_URL =
  getEnv().BACKEND_API + "/api/v3/ChargingLocation/GetChargingLocations";

export const ADD_CHARGING_LOCATION_URL =
  getEnv().BACKEND_API + "/api/v3/ChargingLocation/AddChargingLocations";

export const SEND_WEB_CLIENT_ACCESS_TOKEN =
  getEnv().BACKEND_API + "/api/v3/Access/SendWebClientAccessToken";

export const SEND_ACCESS_TOKEN =
  getEnv().BACKEND_API + "/api/v3/Access/SendAccessToken";

export const SEND_AUTH_CODE =
  getEnv().BACKEND_API + "/api/v3/Access/SendAuthCode";

export const GET_DEVICE_ERRORS_URL =
  getEnv().BACKEND_API + "/api/v3/AccountDevices/GetDeviceErrors";

export const GET_TESLA_TOKENS_URL =
  getEnv().BACKEND_API + "/api/v3/AccountDevices/GetTeslaTokens";
export const GET_DEVICE_CHARGING_SESSIONS_URL =
  getEnv().BACKEND_API + "/api/v3/AccountDevices/GetDeviceChargingSessions";

export const GET_CHARGING_INTERVALS_URL =
  getEnv().BACKEND_API + "/api/v3/AccountDevices/GetChargingIntervals";

export const GET_CHARGING_ENERGY_SUMMARY_URL =
  getEnv().BACKEND_API + "/api/v3/AccountDevices/GetChargingEnergySummary";

export const GET_USER_DEVICES_URL = (programCode: string, fromDate: string, toDate: string) =>
  `https://some-admin-api/api/v4/AdminDashboard/user-devices/program-code/${programCode.toLowerCase()}/from-date/${fromDate}/to-date/${toDate}`;

export const GET_ENERGY_SUMMARY_URL = (programCode: string, fromDate: string, toDate: string) =>
  `https://some-admin-api/api/v4/AdminDashboard/energy-summary/program-code/${programCode}/from-date/${fromDate}/to-date/${toDate}`;

export const DELETE_MY_DATA_URL =
  getEnv().BACKEND_API + "/api/v3/User/DeleteMyData";

export const ADD_USER_REFERRAL =
  getEnv().BACKEND_API + "/api/v3/User/AddUserReferral";

export const GET_DEMAND_RESPONSE_EVENT_URL =
  getEnv().BACKEND_API + "/api/v3/DemandResponse/demand-response-event";

export const GET_DEMAND_RESPONSE_EVENTS_BY_DATE_URL = (eventNotifyStartDateTimeUtc: string, eventEndDateTimeUtc: string) =>
  getEnv().BACKEND_API + `/api/v3/DemandResponse/demand-response-event/notification-start-date-utc/${eventNotifyStartDateTimeUtc}/event-end-date-utc/${eventEndDateTimeUtc}`;

export const PUT_DEMAND_RESPONSE_EVENTS_OPT_OUT_ALL = (demandResponseEventId: string, optOut: boolean) =>
  getEnv().BACKEND_API + `/api/v3/DemandResponse/demand-response-event/id/${demandResponseEventId}/opt-out/${optOut}`;

export const PUT_DEMAND_RESPONSE_EVENTS_OPT_OUT_VEHICLE_URL = (demandResponseEventId: string, deviceGuid: string, optOut: boolean) =>
  getEnv().BACKEND_API + `/api/v3/DemandResponse/demand-response-event/id/${demandResponseEventId}/vehicle/guid/${deviceGuid}/opt-out/${optOut}`;

export const GET_SMARTCAR_VIRTUAL_KEY_PAIRED_STATUS =
  getEnv().BACKEND_API + `/api/v3/Access/GetSmartcarVirtualKeyPairedStatus`;