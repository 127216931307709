import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import carReducer, { CarState } from './carSlice';
import chargingLocationReducer, { ChargingLocationState } from './chargingLocationSlice';
import userInfoReducer, { UserInfoState } from './userInfoSlice';
import authReducer, { AuthState } from './authSlice';
import virtualKeyReducer, { VirtualKeyState } from './virtualKeySlice';
import api from '../admin/store/apiModule';
import demandResponseReducer, { DemandResponseState } from './demandResponseSlice';

interface RootState {
  authSlice: AuthState;
  carSlice: CarState;
  chargingLocationSlice: ChargingLocationState
  userInfoSlice: UserInfoState;
  demandResponseSlice: DemandResponseState;
  virtualKeySlice: VirtualKeyState;
};

// this technique allows us to combine reducers generated by createSlice() with reducers generated by createApi()
const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  authSlice: authReducer,
  carSlice: carReducer,
  chargingLocationSlice: chargingLocationReducer,
  userInfoSlice: userInfoReducer,
  demandResponseSlice: demandResponseReducer,
  virtualKeySlice: virtualKeyReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      // immutableCheck: { warnAfter: 128 },
      immutableCheck: false,
    }).concat(api.middleware),
});

const createTestStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        // immutableCheck: { warnAfter: 128 },
        immutableCheck: false,
      }).concat(api.middleware),
  });
};

export default store;

export type { RootState };
export { createTestStore };
