import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './rootState';
import { useSelector } from 'react-redux';
import { resetState } from './actions';
import { DeviceDTO } from '../apis/energyNetTypes';

const TIMEOUT_DURATION = 5 * 60 * 1000;

export type DeviceKeyState = { device: DeviceDTO, paired: boolean | null, keyNeeded: boolean };

export type VirtualKeyState = {
  deviceKeys: DeviceKeyState[] | undefined;
  lastUpdated: number | null;
};

const initialState: VirtualKeyState = {
  deviceKeys: undefined,
  lastUpdated: null
};

const virtualKeySlice = createSlice({
  name: 'virtualKeySlice',
  initialState,
  reducers: {
    addDeviceKeys: (state, action: PayloadAction<DeviceKeyState[]>) => {
      state.deviceKeys = action.payload;
      state.lastUpdated = Date.now();
    },
  },
  extraReducers: (builder) => builder.addCase(resetState, () => initialState)
});


export const { addDeviceKeys } = virtualKeySlice.actions;
export default virtualKeySlice.reducer;

export const useVirtualKeys = (): DeviceKeyState[] | undefined =>
  useSelector((state: RootState) => {
    const { deviceKeys, lastUpdated } = state.virtualKeySlice;
    if (lastUpdated && Date.now() - lastUpdated < TIMEOUT_DURATION) {
      return deviceKeys;
    }
    return undefined;
  }
  );
