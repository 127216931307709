import { ChangeEvent, FormEvent } from "react";
import { Button } from "../Shared/Button";
import { TextInput } from "../Shared/TextInput";
import { useTranslation } from 'react-i18next';

export type LoginFormInputs = {
  email: string;
  password: string;
};

export const LogInForm = ({
  inputs,
  onChange,
  onSubmit,
  busy,
  errorMessage,
  onSignUpButtonClick,
}: {
  inputs: LoginFormInputs;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
  busy: boolean;
  errorMessage: string;
  onSignUpButtonClick: () => void;
}) => {
  const { t } = useTranslation();
  const title = t('Please fill out this field.');
  return (
    <form method={"post"} onSubmit={onSubmit}>
      {errorMessage && <p aria-errormessage={errorMessage} className={"text-red-500 text-lg"}>
        {errorMessage}</p>}
      <div className={"pb-[5px] md:pb-[20px]"}>
        <TextInput name={"email"} label={t("Email")} value={inputs.email} type={"text"} title={title} onChange={onChange} required={true}
          onInvalid={(e) =>
            (e.target as HTMLInputElement).setCustomValidity(
              t("Please enter an email address")
            )
          } />
      </div>
      <div className={"pb-[5px] md:pb-[20px]"}>
        <TextInput name={"password"} label={t("Password")} value={inputs.password} type={"password"} title={title} required={true} onChange={onChange} onInvalid={(e) =>
          (e.target as HTMLInputElement).setCustomValidity(
            t("Please enter a password")
          )
        } />
      </div>
      <div className={"form-buttons-container"}>
        <div className={"form-button-container"}>
          <Button text={t(busy ? "Logging In" : "Log In")} busy={busy} variant={"primary"} submit={true} />
        </div>

        <div className={"form-button-container"}>
          <span>{t("Don't have an account?")}</span>
          <button onClick={onSignUpButtonClick} className={"text-action text-lg font-sans-bold ml-[4px]"}>
            {t("Sign Up.")}
          </button>
        </div>
      </div>
    </form>
  );
};
