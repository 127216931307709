import { ChangeEvent, useState } from "react";
import {
  SignUpForm,
  SignUpFormInputs,
} from "../components/SignUpForm/SignUpForm";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../hooks/useAuth/useAuth";
import { AddUserReferral, SignUpData, userSignUp, validateEmail } from "../apis/energyNetApi";
import { SignUpHeader } from "../components/SignUpHeader/SignUpHeader";
import getEnv from "../env";
import { ResponsiveLayout } from "../components/Shared/ResponsiveLayout";
import { useSignUpStep } from "../hooks/useSignUpStep/useSignUpStep";
import useBrandConfig from "../hooks/useBrandConfig";
import { useTrackEvent } from "../hooks/useTrackEvent/useTrackEvent";
import { useTranslation } from "react-i18next";
import { QueryString } from "../constants/queryString";

const env = getEnv().ENVIRONMENT;

type SignUpProps = {
  messageComponent?: JSX.Element;
  submitButtonText?: string;
  submitButtonStyles?: string;
  customSignupHandler?: (signupData: SignUpData) => void;
};

export const SignUp = (props: SignUpProps) => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [searchParams] = useSearchParams();
  const [formInputs, setFormInputs] = useState<SignUpFormInputs>({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    consent: false,
    referral: searchParams.get(QueryString.Referrer) || ""
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [busy, setBusy] = useState(false);
  const [, setSignUpStep] = useSignUpStep();
  const config = useBrandConfig();
  const siteKey = getEnv().RECAPTCHA_SITE_KEY;
  const trackEvent = useTrackEvent();
  const { t } = useTranslation();

  // test users in development, on coopusa or if they are a flexcharging employee.
  const isTestUser = env === "development" || env === "dev" || config.programName === "COOPUSA" || formInputs.email.indexOf("@flexcharging.com") !== -1;

  const validateEmailAddress = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formInputs.email || !emailRegex.test(formInputs.email)) {
      setErrorMessage(t("Please provide a valid email address."));
      return false;
    } else {
      setErrorMessage("");
      return true;
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    (event.target as HTMLInputElement).setCustomValidity("");
    setFormInputs({
      ...formInputs,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeConsent = (value: boolean) => {
    setFormInputs({
      ...formInputs,
      consent: value,
    });
  };

  const handleChangeToken = (token: string | null) => {
    setFormInputs({
      ...formInputs,
      token: token || "",
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setErrorMessage("");
    setBusy(true);

    if (!validateEmailAddress()) {
      setBusy(false);
      return;
    }

    if (config.termsAndConditionsConsentRequired && !formInputs.consent) {
      setErrorMessage(t("You must agree to the terms and conditions."));
      setBusy(false);
      return;
    }

    if (siteKey && !formInputs.token) {
      setErrorMessage(t("Please validate that you are not a robot."));
      setBusy(false);
      return;
    }

    const signUpData: SignUpData = {
      email: formInputs.email.trim(),
      password: formInputs.password,
      firstName: formInputs.firstName,
      lastName: formInputs.lastName,
      program: config.programName,
      driverCultureName: navigator.language || "en-US",
      useMetric: true,
      isTestUser,
      recaptchaToken: formInputs.token,
    };


    try {
      await validateEmail(signUpData.email);
    }
    catch {
      setErrorMessage(t('duplicate email error', { email: signUpData.email }));
      setBusy(false);
      return;
    }

    try {
      const response = await userSignUp(signUpData);
      if (response.status !== 200) {
        setErrorMessage(t("Sign up failed. Please try again."));
        setBusy(false);
        return;
      }

      login(response.data.Token, signUpData.email);


      if (config.hasSignUpReferralInput && formInputs.referral) {
        // save the referral email to db
        trackEvent("Referral", { email: signUpData.email, referral: formInputs.referral });
        await AddUserReferral(response.data.Token, signUpData.email, config.utilityName, config.programName, formInputs.referral);
      }

      if (props.customSignupHandler) {
        props.customSignupHandler(signUpData);
      }
      else {
        setSignUpStep("accountcreated");
        navigate("/accountcreated");
        trackEvent("SignUp", { email: signUpData.email });
      }

    } catch (error: any) {
      setBusy(false);
      setErrorMessage(error.response?.data?.message || t("There was an internal error attempting to sign up. Please try again later.")); // TODO: UNTESTED!!!
    }
  };

  const handleLogInButtonClick = () => {
    navigate("/login");
  };

  return (
    <ResponsiveLayout>
      <>
        <SignUpHeader />
        {props.messageComponent}
        <div className={"form-contents-container"}>
          <SignUpForm
            inputs={formInputs}
            onChange={handleChange}
            onChangeConsent={config.termsAndConditionsConsentRequired ? handleChangeConsent : undefined}
            onChangeToken={handleChangeToken}
            onSubmit={handleSubmit}
            busy={busy}
            errorMessage={errorMessage}
            onLogInButtonClick={handleLogInButtonClick}
            recaptchaSiteKey={siteKey}
            showTermsAndConditionsOnPage={config.termsAndConditionsShowOnPage}
            programName={config.programName}
            termsAndConditionText={config.termsAndConditionText}
            {...props}
          />
        </div>
      </>
    </ResponsiveLayout>
  );
};
