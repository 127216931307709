import { ResponsiveLayout } from "../components/Shared/ResponsiveLayout";
import TermsAndConditionsText from "../components/SignUpFooter/TermsAndConditions";
import useBrandConfig from "../hooks/useBrandConfig";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BackButton } from "../components/Shared/BackButton";
import { BasicPageHeader } from "../components/Shared/BasicPageHeader";
import { useAuth } from "../hooks/useAuth/useAuth";

const TermsAndConditions = () => {
  const config = useBrandConfig();
  const { user } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleBackClick = () => {
    navigate("/account");
  };

  return (
    <ResponsiveLayout>
      <>

        {user &&
          <BackButton text={t("Back")} onClick={handleBackClick} />
        }
        <div className="flex justify-between items-center">
          <div className="border-b border-solid border-default pb-[20px] mb-[20px] w-full md:w-fit">
            <BasicPageHeader title={t(config.termsAndConditionsTitle)} />
          </div>
        </div>
        <TermsAndConditionsText program={config.programName} inScrollBox={false} />
      </>
    </ResponsiveLayout>
  );
};

export default TermsAndConditions;