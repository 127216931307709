import React, { FC, ReactElement } from "react";
import { EvCard, EvCardProps } from "../EvCard/EvCard";
import { Spinner } from "../Shared/Spinner";

export type EvCardListProps = { evList: EvCardProps[], loading: boolean };

export const EvCardList: FC<EvCardListProps> = ({ evList, loading }): ReactElement => {
  return (
    <>
      {loading &&
        <div className="fixed flex justify-center items-center w-full h-full top-0 left-0 bg-white opacity-70 z-50">
          <Spinner size="lg" />
        </div>
      }
      <div className={"flex flex-wrap gap-x-[12px] gap-y-[0]"}>
        {evList.map((ev) => (
          <EvCard
            key={ev.manufacturer}
            logo={ev.logo}
            manufacturer={ev.manufacturer}
            onClick={ev.onClick}
          />
        ))}
      </div>
    </>
  );
};
