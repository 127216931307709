import { ChangeEvent, FormEvent, useState } from "react";
import { Button } from "../Shared/Button";
import { TextInput } from "../Shared/TextInput";
import ReCAPTCHA from "react-google-recaptcha";
import { SignUpFooter } from "../SignUpFooter/SignUpFooter";
import TermsAndConditionsText from "../SignUpFooter/TermsAndConditions";
import Checkbox from "../Shared/Checkbox";
import useBrandConfig from "../../hooks/useBrandConfig";
import { t } from "i18next";
import i18next from "i18next";

export type SignUpFormInputs = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  token?: string;
  consent: boolean;
  referral: string;
};

export const SignUpForm = ({
  inputs,
  onChange,
  onChangeToken,
  onChangeConsent,
  onSubmit,
  busy,
  errorMessage,
  onLogInButtonClick,
  recaptchaSiteKey,
  showTermsAndConditionsOnPage,
  termsAndConditionText,
  programName,
  submitButtonText,
  submitButtonStyles,
}: {
  inputs: SignUpFormInputs;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onChangeConsent?: (value: boolean) => void;
  onChangeToken: (token: string | null) => void;
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
  busy: boolean;
  errorMessage: string;
  onLogInButtonClick: () => void;
  recaptchaSiteKey?: string;
  showTermsAndConditionsOnPage: boolean;
  termsAndConditionText: string;
  programName: string;
  messageComponent?: JSX.Element;
  submitButtonText?: string;
  submitButtonStyles?: string;
}) => {
  const config = useBrandConfig();
  const [message] = useState(t('Please fill out this field.'));
  return (
    <form method={"post"} onSubmit={onSubmit}>
      {errorMessage && (
        <p className={"error-message text-red-500 pb-[20px]"}>{errorMessage}</p>
      )}
      <div className={"pb-[5px] md:pb-[20px] multi-row-input-container"}>
        <div className={"multi-input-container"}>
          <TextInput name={"firstName"} label={t("First Name")} value={inputs.firstName} type={"text"} title={message} required={true}
            onChange={onChange}
            onInvalid={(e) =>
              (e.target as HTMLInputElement).setCustomValidity(
                t("Your first name is required")
              )
            } />
        </div>
        <div className={"multi-input-container"}>
          <TextInput name={"lastName"} label={t("Last Name")} value={inputs.lastName} type={"text"} title={message} required={true}
            onChange={onChange}
            onInvalid={(e) =>
              (e.target as HTMLInputElement).setCustomValidity(
                t("Your last name is required")
              )
            } />
        </div>
      </div>
      <div className={"pb-[5px] md:pb-[20px]"}>
        <TextInput name={"email"} label={t("Email")} value={inputs.email} type={"text"} title={message} required={true}
          onChange={onChange}
          onInvalid={(e) =>
            (e.target as HTMLInputElement).setCustomValidity(
              t("You must provide a valid email address")
            )
          } />
      </div>
      <div className={"pb-[20px]"}>
        <TextInput name={"password"} label={t("Password")} value={inputs.password} type={"password"} title={message} required={true}
          pattern="^[^ ]{6,128}$" // min 6 max 128 no whitespace
          onChange={onChange}
          onInvalid={(e) =>
            (e.target as HTMLInputElement).setCustomValidity(
              t("Your password must be at least 6 characters long and not contain spaces")
            )
          } />
      </div>
      {config.hasSignUpReferralInput &&
        <div className="pt-[9px]">
          <span className="text-sm">{t("If someone referred you, enter their email here.")}</span>
          <div className={"form-row"}>
            <TextInput name={"referral"} label={t("Email Address of Referrer")} value={inputs.referral} type={"text"} title={message} required={false}
              onChange={onChange} />
          </div>
        </div>
      }
      {showTermsAndConditionsOnPage ?
        <div>
          <div className={"m-auto mt-[50px]"}>
            <div className="border-[#D6D6D6] bg-[#F1F1F1] px-[30px] font-sans-bold flex flex-col items-center">
              <div className="pb-[20px] pt-[30px] text-lg text-center">{config.termsAndConditionsTitle}</div>
              <TermsAndConditionsText program={programName} inScrollBox={true} />
              {onChangeConsent &&
                <div className="py-[40px] flex items-center hover:cursor-pointer" onClick={() => onChangeConsent(!inputs.consent)}>
                  <Checkbox selected={!!inputs.consent} id="terms" />
                  <label htmlFor="terms" className={"text-lg text-center pt-[4px]"}>{termsAndConditionText}</label>
                </div>
              }
            </div>
          </div>
          {recaptchaSiteKey &&
            <div className={"form-row flex justify-center mt-[25px]"}>
              <ReCAPTCHA sitekey={recaptchaSiteKey} onChange={onChangeToken} hl={i18next.language} />
            </div>
          }

        </div>
        :
        <>
          {config.termsAndConditionsLink && !config.termsAndConditionsShowOnPage &&
            <div className={"m-auto mt-[30px] flex justify-center"}>
              <a
                className={"link text-lg text-action font-sans-bold"}
                href={config.termsAndConditionsLink}
                target="_blank"
                rel="noreferrer"
              >
                {config.termsAndConditionsLinkText}
              </a>
            </div>
          }
          {onChangeConsent &&
            <div className="pt-[20px] flex items-center justify-center hover:cursor-pointer" onClick={() => onChangeConsent(!inputs.consent)}>
              <Checkbox selected={!!inputs.consent} id="terms" />
              <label htmlFor="terms" className={"text-lg text-center pt-[4px]"}>{termsAndConditionText}</label>
            </div>
          }
          {recaptchaSiteKey &&
            <div className={"form-row flex justify-center mt-[15px] md:mt-[30px]"}>
              <ReCAPTCHA sitekey={recaptchaSiteKey} onChange={onChangeToken} hl={i18next.language} />
            </div>
          }

        </>
      }
      <div className={"mt-[15px] md:mt-[30px] m-auto max-w-[510px] flex justify-center"}>
        <div className={"flex flex-col items-center"}>
          <div className="w-[240px] md:mb-[10px] flex justify-center">
            <Button variant="primary" text={t(busy ? "Signing Up" : (submitButtonText || "Sign Up"))} submit={true} busy={busy} fullWidth={showTermsAndConditionsOnPage} extraStyles={submitButtonStyles} />
          </div>
          <div className={"form-button-container"}>
            <span>{t("Already have an account?")}</span>
            <button onClick={onLogInButtonClick} className={"text-action text-lg font-sans-bold ml-[4px]"}>
              {t("Log In.")}
            </button>
          </div>
        </div>
      </div>
      <SignUpFooter />
    </form >
  );
};
