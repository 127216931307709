import { Link } from "react-router-dom";
import useBrandConfig from "../../hooks/useBrandConfig";

export const LogInFooter = () => {
  const config = useBrandConfig();

  return (
    <div className={"m-[30px] md:m-[40px] flex flex-col flex-wrap"}>
      <div className={"m-auto pb-[8px]"}>
        <p className={"text-sm"}>{config.loginForgotPasswordText}</p>
      </div>
      <div className={"m-auto"}>
        <Link
          className={"link text-lg text-action font-sans-bold"}
          to={"/forgotpassword"}
        >
          {config.loginForgotPasswordLink}
        </Link>
      </div>
    </div>
  );
};
