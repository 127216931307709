import { useTranslation } from 'react-i18next';
import { useTrackEvent } from '../../hooks/useTrackEvent/useTrackEvent';



const VirtualKeyInstructionsMessage = () => {
  const trackEvent = useTrackEvent();
  const { t } = useTranslation();
  const handleVirtuaKeyClick = () => {
    trackEvent("ViewVirtualLink");
    window.open("https://wiki.flexcharging.com/en/public/userguides/guides/evision/tesla-signup", "_blank");
  };

  return (
    <div>
      <div className="text-sm bg-[#EFF3F3] p-[10px] flex flex-col md:items-center">
        <div className="font-sans-bold">{t("Tesla Virtual Key Setup Recommended for Program Participation")}</div>
        <div className="pb-[11px]">
          {t("Please set up your Tesla Virtual Key by clicking the link below. We won’t be able to manage your vehicle’s charging until you do.")}
        </div>
        <button className="bg-action rounded-[8px] font-sans-bold text-white h-[33px] flex items-center justify-center md:w-[300px] underline" onClick={handleVirtuaKeyClick}>
          {t("Set Up Tesla Virtual Key") + " >>"}
        </button>
      </div>
    </div>
  );
};

export default VirtualKeyInstructionsMessage;