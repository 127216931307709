import { useTrackEvent } from "../hooks/useTrackEvent/useTrackEvent";
import { useEffect, lazy, Suspense } from "react";
import useBrandConfig from "../hooks/useBrandConfig";
import capitalize from "../helpers/capitalize";

const Landing = () => {
  const trackevent = useTrackEvent();
  useEffect(() => {
    trackevent("LandingPage");
  }, [trackevent]);
  const config = useBrandConfig();

  // dynamically load the right landing page based on the brand
  const LandingComponent = lazy(() => import(`./LandingPages/${capitalize(config.programName)}Landing`));

  return (
    <Suspense>
      <LandingComponent />
    </Suspense>
  )
};

export default Landing;