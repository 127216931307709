import React from "react";
import { useAuth } from "../hooks/useAuth/useAuth";
import { Navigate, useLocation } from "react-router-dom";
import { Header } from "../components/Header/Header";

export const AuthenticatedLayout = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const { user } = useAuth();
  const location = useLocation();

  if (!user) {
    const returnurl = location.pathname ? `?returnUrl=${location.pathname}${location.search}` : "";
    return <Navigate to={"/login" + returnurl} />;
  }

  return (
    <>
      <Header />
      {children}
    </>
  );
};
