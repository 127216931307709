import { FC, ReactElement } from "react";

export type EvCardProps = {
  logo?: string;
  manufacturer: string;
  onClick: () => void;
};

export const EvCard: FC<EvCardProps> = ({
  logo,
  manufacturer,
  onClick,
}): ReactElement => {
  return (
    <div className={`flex items-center w-full md:w-[calc(50%_-_8px)] lg:w-[calc(33.33%_-_16px)]
                      border-2 border-solid border-action
                      h-[66px] mt-[10px] rounded-[10px]
                      hover:bg-action hover:bg-opacity-5 hover:cursor-pointer`}
      onClick={onClick}>
      <div className={"flex items-center h-[50px] w-[50px] mx-[30px] my-[10px]"}>
        <img className={"m-auto max-h-[50px] max-w-[50px]"} alt={`${manufacturer} logo`} src={logo} />
      </div>
      <div className={"border-l border-action h-3/5 mr-[25px]"} />
      <div className={"text-xl"}>{manufacturer}</div>
    </div>
  );
};
