import { FunctionComponent } from "react";

type InformationCardProps = {
    header: string;
    dollars?: string;
    body: string;
    footer?: string;
}

export const RewardsProgramInformationCard: FunctionComponent<InformationCardProps> = ({ header, dollars, body, footer }) => {
    return (
        <div className="rounded-xl border-2 border-solid border-default pt-[30px] pr-[26px] pb-[20px] pl-[27px] mb-[12px] flex-1">
            <div className="flex flex-row justify-between items-center pb-[12px] font-sans-bold text-2xl">
                <div className="w-[75%]">
                    <h2>{header}</h2>
                </div>
                {dollars &&
                    <div>{dollars}</div>
                }
            </div>
            <div className="text-lg py-[12px] border-t border-b border-default">
                <p>{body}</p>
            </div>
            {footer ?
                <div className="font-sans-light text-sm text-right pt-[20px]">
                    <p>
                        {footer.split('.').map((item, index) =>
                            (<span key={index}>{item}<br /></span>))
                        }
                    </p>
                </div>
                : <br />
            }
        </div>
    );
}

// {dollars.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: dollars > 1 ? 0 : 2 })}