import { ChangeEvent, FormEvent } from "react";
import { Button } from "../Shared/Button";
import { TextInput } from "../Shared/TextInput";
import { useTranslation } from 'react-i18next';

export const ResetPasswordForm = ({
  inputs,
  onChange,
  onSubmit,
  busy,
  message,
  onLogInButtonClick,
}: {
  inputs: {
    email: string;
  };
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
  busy: boolean;
  message: string;
  onLogInButtonClick: () => void;
}) => {
  const { t } = useTranslation();
  const title = t('Please fill out this field.');
  return (
    <form method={"post"} onSubmit={onSubmit}>
      {message && <p className={message.startsWith('Please') ? "text-danger" : "form-message"}>{t(message)}</p>}
      <div className={"form-row"}>
        <TextInput name={"email"} label={t("Email")} value={inputs.email} type={"text"} title={title} required={true}
          onChange={onChange}
          onInvalid={(e) =>
            (e.target as HTMLInputElement).setCustomValidity(
              t("You must provide a valid email address")
            )
          }
        />
      </div>
      <div className={"form-buttons-container"}>
        <div className={"form-button-container"}>
          <Button
            text={busy ? t("Requesting Password Reset") : t("Request Password Reset")}
            busy={busy}
            variant={"primary"}
            submit={true}
          />
        </div>
        <div className={"form-button-container"}>
          <Button variant="secondary" text={t("Log In")} onClick={onLogInButtonClick} busy={busy} />
        </div>
      </div>
    </form>
  );
};
