import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector'

i18n.languages = ['en', 'es'];

// call init to do the detection of language. 
// this uses the browser navigator.language to detect the user language
// unless the language has been set manually with the query string ?lng=es
// in which case it will be stored in local storage
i18n.use(LanguageDetector).init();

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  resources: {
    en: {
      translations: require('./locales/en/translations.json')
    },
    es: {
      translations: require('./locales/es/translations.json')
    }
  },
  ns: ['translations'],
  defaultNS: 'translations',
});

var testLanguageCode: "es" | "en" | undefined = undefined;

export const getCurrentLanguageCode = (): "es" | "en" => {
  if (testLanguageCode) {
    return testLanguageCode;
  }
  return (i18n.language === 'en' || i18n.language === 'es') ? i18n.language : 'en';
};

export const setTestLanguageCode = (languageCode: "es" | "en") => {
  testLanguageCode = languageCode;
};

export default i18n;