import React, { ChangeEvent, FormEvent, useCallback, useState } from "react";
import { Button } from "../Shared/Button";
import { TextInput } from "../Shared/TextInput";
import useAddressAutoCompleteWidget, { Address } from "../../hooks/useAddressAutoCompleteWidget/useAddressAutoCompleteWidget";
import { useTranslation } from 'react-i18next';
import useBrandConfig from "../../hooks/useBrandConfig";
import Checkbox from "../Shared/Checkbox";
import questionIcon from "../../static/ui/question-mark-is-small.svg";
import getCountryConfig from "../../helpers/countryConfigs";
import PhoneInput, { Country, isPossiblePhoneNumber } from 'react-phone-number-input'; // Import the PhoneInput component from 'react-phone-number-input'
import 'react-phone-number-input/style.css'; // Import the default styles for react-phone-number-input

export type AddLocationInputs = {
  addressLineOne: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  utilityAccountId: string;
  phoneNumber: string;
  smsConsent: boolean;
  latitude?: number;
  longitude?: number;
};

// just put in some european countries for now
const countryList: Country[] = ["US", "IE", "GB", "CA", "AU", "FR", "DE", "ES", "IT", "NL", "SE", "NO", "DK", "FI", "BE", "AT", "CH", "PT", "PL", "CZ", "SK", "HU", "RO", "BG", "GR", "HR", "SI", "RS", "BA", "MK", "ME", "XK", "AL", "MX"];

export const AddLocationForm = ({
  inputs,
  onChange,
  onSubmit,
  onCancel,
  onAddressSelected,
  busy,
  editExistingLocation,
  pillButton
}: {
  inputs: AddLocationInputs;
  onChange: (
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>
  ) => void;
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
  onCancel?: () => void;
  onAddressSelected: (address: AddLocationInputs) => void;
  busy: boolean;
  editExistingLocation: boolean;
  pillButton?: boolean;
}) => {
  const onAddressAutoCompleteSelected = useCallback((address: Address) => {
    // convert to AddLocationInputs
    onAddressSelected({
      addressLineOne: address.addressLineOne,
      city: address.city,
      state: address.state,
      postalCode: address.postalCode,
      country: address.country,
      utilityAccountId: inputs.utilityAccountId, // keep the utility account id because its not set by google
      phoneNumber: inputs.phoneNumber,
      smsConsent: inputs.smsConsent,
      latitude: address.latitude,
      longitude: address.longitude
    });
  }, [inputs.utilityAccountId, inputs.phoneNumber, inputs.smsConsent, onAddressSelected]);

  const config = useBrandConfig();
  const { ref, handleCountryChange } = useAddressAutoCompleteWidget({
    onAddressSelected: onAddressAutoCompleteSelected, defaultCountry: inputs.country || config.defaultCountry
  });
  const { t } = useTranslation();
  const [isTermsVisible, setIsTermsVisible] = useState(false);
  const [showPhoneNumberError, setShowPhoneNumberError] = useState(false);

  const handlePhoneNumberChange = (value: string) => {

    if (showPhoneNumberError && isPossiblePhoneNumber(value)) {
      setShowPhoneNumberError(false);
    }

    // make a fake input ChangeEvent to pass to the onChange function
    onChange({
      target: {
        name: "phoneNumber",
        value: value,
      } as unknown as EventTarget
    } as ChangeEvent<HTMLInputElement>);
  };

  const onCountryChange = (e: ChangeEvent<HTMLSelectElement>) => {
    // tell the autocomplete widget to update the country
    handleCountryChange(e.target.value);

    // tell the parent component that the country has changed
    onChange(e);
  };

  const handleConsentChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange({
      target: {
        name: "smsConsent",
        value: (!inputs.smsConsent),
      } as unknown as EventTarget
    } as ChangeEvent<HTMLInputElement>);
  };

  const togglePopup = () => {
    setIsTermsVisible(!isTermsVisible);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // we only validate the length the phone number, not the digits
    if (config.hasPhoneNumberInput && (inputs.phoneNumber ? !isPossiblePhoneNumber(inputs.phoneNumber) : config.phoneNumberRequired)) {
      setShowPhoneNumberError(true);
      return;
    };

    onSubmit(event);
  };

  const countryConfig = getCountryConfig(inputs.country);

  return (
    <form method={"post"} onSubmit={handleSubmit} >
      <div className={"form-row"}>
        <TextInput ref={ref} name={"addressLineOne"} placeholder={t("Enter a location")} label={t("Address")} title={t("Please enter an address")} value={inputs.addressLineOne} type={"text"} required={true} onChange={onChange}
        />
      </div>
      <div className={"form-row"}>
        <TextInput name={"city"} label={t("City")} value={inputs.city} type={"text"} required={true} pattern={countryConfig.cityRegex.source}
          title={t("Please enter a city")} onChange={onChange}
        />
      </div>
      <div className={"form-row multi-row-input-container flex-wrap md:flex-nowrap"}>
        <div className={"flex-1 md:basis-1/3"}>
          <TextInput name={"state"} label={t(countryConfig.stateTitle)} value={inputs.state} type={"text"} required={true}
            pattern={countryConfig.stateRegex.source} onChange={onChange}
            title={t("Please enter a valid " + countryConfig.stateTitle.toLowerCase())}
          />
        </div>
        <div className={"flex-1 md:basis-1/3"}>
          <TextInput name={"postalCode"} label={t(countryConfig.postalCodeTitle)} value={inputs.postalCode} type={"text"} required={true}
            pattern={countryConfig.postalCodeRegex.source} onChange={onChange}
            title={t("Please enter a valid " + countryConfig.postalCodeTitle.toLowerCase())}
          />
        </div>
        <div className="basis-full flex-1 md:basis-1/3">
          <label className={"text-sm text-action"} htmlFor={"country"}>
            {t("Country")}
          </label>
          <br />
          {/*  We will change the country name when we go live for Spanish.  */}
          <select
            className={`w-full h-[35px] md:h-[45px] text-lg p-[5px] 
                      box-border border-action border-solid border
                      focus:outline-2 hover:outline-2  
                      focus:outline hover:outline
                      focus:outline-action hover:outline-action
                      transition-all duration-300`}
            id={"country"}
            name={"country"}
            required={true}
            onChange={onCountryChange}
            value={inputs.country}
          >
            {/* Only show the default country in the list */}
            <option value={config.defaultCountry}>{config.defaultCountry}</option>
          </select>
        </div>
      </div>
      {config && config.hasAccountIdInput &&
        <div className={"form-row"}>
          {config.locationAccountIdLabel &&
            <div className="text-lg font-sans-bold pt-[5px]">{t(config.locationAccountIdLabel)}</div>
          }
          <TextInput name={"utilityAccountId"} label={config.locationAccountIdName} title={config.locationAccountIdName}
            value={inputs.utilityAccountId || ""} type={"text"} required={config.accountIdRequired} onChange={onChange}
            onInvalid={(e) =>
              (e.target as HTMLInputElement).setCustomValidity(
                t("Please enter a valid " + config.locationAccountIdName)
              )}
          />
          {config.locationAccountIdDescription &&
            <div className="text-lg mt-[5px]">{config.locationAccountIdDescription}</div>
          }
        </div>
      }
      {config && config.hasPhoneNumberInput &&
        <div className={"form-row flex md:flex-row flex-col md:items-center"}>
          <div>
            <label className={"text-sm text-action"} htmlFor={"phoneNumber"}>
              {t("Phone Number")}
            </label>
            <PhoneInput className={`h-[35px] md:h-[45px] text-lg p-[5px] 
                    box-border border-action border-solid border
                    focus:outline-2 hover:outline-2  
                    focus:outline hover:outline
                    focus:outline-action hover:outline-action
                    transition-all duration-300`}
              id={"phoneNumber"}

              international={config.defaultCountryCode !== "US"}
              defaultCountry={config.defaultCountryCode as any as Country}
              countries={countryList}
              placeholder="Enter phone number"
              value={inputs.phoneNumber || ""}
              rules={{ required: config.phoneNumberRequired }}
              onChange={handlePhoneNumberChange}
              onBlur={() => { setShowPhoneNumberError(!!inputs.phoneNumber && !isPossiblePhoneNumber(inputs.phoneNumber)) }}
              onInvalid={() => { setShowPhoneNumberError(true) }}
            />
            {showPhoneNumberError &&
              <span className="text-sm text-error">{t("Please enter a valid phone number")}</span>
            }
          </div>

          <div className="flex flex-row items-center">
            <div className="text-lg mt-[25px] md:ml-[20px]">
              <Checkbox selected={inputs.smsConsent} id="smsConsent" onChange={handleConsentChange} />
            </div>
            <div className="flex flex-row items-center">
              <label htmlFor="smsConsent" className="text-lg mt-[20px]">{t("Consent to Receive Text Notifications")}</label>
              <img src={questionIcon} alt="question mark" className="ml-[5px] mt-[20px] h-[20px]" onClick={togglePopup} />
              <div className="relative">
                {isTermsVisible &&
                  <div onClick={togglePopup} id="popupDiv" className="absolute bg-[#D9D9D9] p-2 rounded-md text-sm whitespace-normal top-0 right-full mr-6 mt-6 shadow-md z-10">
                    <span>{config.smsConsentTerms} </span>
                    <a target="_blank" rel="noreferrer" className="text-action" href="https://www.flexcharging.com/terms-of-use">https://www.flexcharging.com/terms-of-use</a>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      }
      <div className={"form-buttons-container"}>
        <div className={"form-button-container"}>
          <Button
            variant="primary"
            submit={true}
            busy={busy}
            text={editExistingLocation ?
              (busy ? t("Saving Changes") : t("Save Changes")) :
              (busy ? t("Adding Location") : t("Add Location"))}
            pill={!!pillButton}
          />
        </div>

        {onCancel &&
          <div className={"form-button-container mb-[20px]"}>
            <Button
              variant="secondary"
              text={t("Cancel")}
              onClick={onCancel}
            />
          </div>
        }
      </div>
    </form >
  );
};
