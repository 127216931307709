export type ToggleSwitchProps = {
  checked: boolean;
  loading?: boolean;
  handleChangeToggle: () => void;
  stateText: string;
  testId?: string;
};

const ToggleSwitch = ({ checked, loading, handleChangeToggle, stateText, testId }: ToggleSwitchProps) => {
  return (
    <label className={"flex items-center relative w-max cursor-pointer select-none mr-[16px]" + (loading ? " opacity-40" : "")}>
      <input
        onChange={() => { !loading && handleChangeToggle() }}
        checked={checked}
        aria-checked={checked}
        type="checkbox"
        data-testid={testId}
        className={`appearance-none transition-colors cursor-pointer w-[105px] h-[35px] rounded-full 
      ${checked ? "bg-checkmark border-white border-[1px]" : "bg-white border-action border-[1px]"}`} />
      {checked ?
        <span className="absolute text-sm uppercase left-[20px] top-[9px] text-white">{stateText}</span> :
        <span className="absolute text-sm uppercase right-[10px] text-gray-400">{stateText}</span>
      }
      <span className={`w-[30px] h-[30px] right-[72px] absolute rounded-full transform transition-transform bg-white 
   ${checked ? " translate-x-[69px]" : " border-action border-4"}`} />
    </label>
  );
}

export default ToggleSwitch;