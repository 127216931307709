export const PillTab = ({ id, label, scale, selected, dark }: { id: string; label: string; scale: string; selected: boolean; dark?: boolean }) => {
  return <li role="presentation">
    <a
      href={`#tabs-${scale}-${id}]`}
      className={dark ? "font-sans-bold my-2 rounded-full block px-[20px] pb-[3px] pt-[5px] text-sm leading-tight focus:isolate focus:border-transparent data-[te-nav-active]:text-action data-[te-nav-active]:bg-black" :
        "my-2 rounded-full block border-2 border-transparent px-[20px] pb-[3px] pt-[5px] text-sm leading-tight focus:isolate focus:border-transparent data-[te-nav-active]:border-[#CCCCCC] data-[te-nav-active]:bg-white"}
      id={`tabs-${scale}-tab-${id}`}
      data-te-toggle="pill"
      data-te-target={`#tabs-${scale}-${id}`}
      // include attribute  data-te-nav-active only if selected is true, otherwise omit it completely
      {...(selected ? { "data-te-nav-active": "" } : {})}
      role="tab"
      aria-controls={`tabs-${scale}-${id}`}
      aria-selected={selected}
    >{label}</a>
  </li>;
};
