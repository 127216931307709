import allCarMakes from "./allCarMakes";

export const getLogo = (name: string) => {
  if (!name) return "";

  // make is the same except for the case and the underscore
  // const noUnderscoreName = name.toUpperCase().replaceAll("_", "");
  const make = allCarMakes.find((make) => make.value === name.toUpperCase());

  if (!make) return "";

  return make.logo;
};
