import { FC, ReactElement, useEffect, useMemo, useState } from "react";
import deleteButton from "../../static/ui/car delete gray x.svg";
import { ChargeEnergySummaryDTO } from "../../apis/energyNetTypes";
import ChargingChart from "../ChargingChart/ChargingChart";
import { useTranslation } from "react-i18next";

import {
  Tab,
  initTE,
} from "tw-elements";
import useBrandConfig from "../../hooks/useBrandConfig";
import { PillTab } from "./PillTab";
import formatDate from "../../helpers/formatDate";

const getDeviceStatusClass = (status?: string) => {
  if (status === "Connected") {
    return "text-checkmark";
  } else if (status === "Disconnected") {
    return "text-error";
  } else {
    return "text-default";
  }
};

/*
const formatLastSeen = (lastSeen?: string) => {
  // this is broken at the moment, don't show it
  if (lastSeen) {
    const lastSeenDate = new Date(lastSeen);
    const lastSeenMonth = (lastSeenDate.getMonth() + 1)
      .toString()
      .padStart(2, "0");
    const lastSeenYear = lastSeenDate
      .getFullYear()
      .toString()
      .substring(2);

    return `${lastSeenMonth}/${lastSeenYear}`;
  }
  return "";
};
*/

export type DashboardDeviceProps = {
  id: string;
  logo: string;
  model: string;
  friendlyName?: string;
  vin?: string;
  status?: string;
  dailyChargeEnergySummary?: ChargeEnergySummaryDTO[];
  monthlyChargeEnergySummary?: ChargeEnergySummaryDTO[];
  lastSeen?: Date;
  onRemove: (id: string) => void;
};

export function fixCapitalization(name: string) {
  // split words on space or underscore
  const words = name.split(/[_ ]/);
  const convertedWords = words.map((word) => {
    if (word === word.toUpperCase() && /^[A-Za-z]+$/.test(word)) {
      return word.charAt(0) + word.slice(1).toLowerCase();
    } else {
      return word;
    }
  });
  return convertedWords.join(" ");
}

export const DashboardDevice: FC<DashboardDeviceProps> = ({
  id,
  logo,
  model,
  friendlyName,
  vin,
  status,
  dailyChargeEnergySummary,
  monthlyChargeEnergySummary,
  lastSeen,
  onRemove,
}): ReactElement => {
  const deviceStatusClass = useMemo(() => getDeviceStatusClass(status), [status]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { t, i18n } = useTranslation();
  const statusString = t(status ?? "Loading...");
  const config = useBrandConfig();
  const wide = windowWidth > 1000;
  const chartHeightPx = wide ? 170 : 120;

  useEffect(() => {
    initTE({ Tab });
  }, []);

  const hasDailyChargingData = !!(dailyChargeEnergySummary && dailyChargeEnergySummary.some(energy => energy.OffPeakEnergy > 0 || energy.OnPeakEnergy > 0));
  const hasMonthlyChargingData = !!(monthlyChargeEnergySummary && monthlyChargeEnergySummary.some(energy => energy.OffPeakEnergy > 0 || energy.OnPeakEnergy > 0));

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={"border-2 border-action rounded-[12px] p-[16px] mb-[12px]"}>
      <div className="flex items-center justify-between pt-[5px] pb-[6px]">
        <div className="flex items-center">
          <img
            className={"max-h-[32px] max-w-[32px] ml-[10px]"}
            alt={`${model} logo`}
            src={logo}
          />
          <span className={"ml-[10px] md:ml-[20px] text-lg font-sans-bold"}>{fixCapitalization(friendlyName || model)}</span>
          {vin &&
            <span className="text-xs pl-[5px] md:pl-[10px] text-default font-sans-light">{t("VIN ")}{vin?.substring(vin.length - 4)}</span>
          }
        </div>


        {/* // Here is In Pvrea case show remove vehicle otherwise it will show delete icon */}
        {config.dashboardDeleteVehicleText ? (
          <button
            className="text-[#F45] font-sans-bold"
            onClick={() => onRemove(id)}
          >{t("Remove Vehicle")}</button>
        ) : (
          <button>
            <img
              className="max-w-[20px] max-h-[20px]"
              src={deleteButton}
              alt={t("Remove Vehicle")}
              onClick={() => onRemove(id)}
            />
          </button>
        )}
      </div>
      <hr className={"my-[10px] border-t border-[#c1c1c1]"} />
      <div className={"flex items-end justify-between"}>
        <div className="flex flex-col">
          <span className="text-sm text-opacity-50 text-default pb-[5px] font-sans-light">{t("Status")}</span>
          <span className={`text-lg font-sans-bold ${deviceStatusClass}`}>
            {statusString}
          </span>
        </div>

        <div className={"text-sm font-sans-light text-[#929292]"}>
          {statusString === "Disconnected" && typeof (lastSeen) === "object" && t("Last seen on " + formatDate(lastSeen))}
        </div>
      </div>

      {config.dashboardChargingCharts && (hasDailyChargingData || hasMonthlyChargingData) &&
        <>
          <div className="w-full bg-[#EFF3F3] flex flex-row justify-center pt-[20px] mt-[10px]">
            <div className="bg-[#EBEBEB] rounded-full px-[10px]">
              <ul className="flex list-none flex-row flex-wrap border-b-0 pl-0 gap-[20px] font-medium"
                role="tablist"
                data-te-nav-ref>
                <PillTab id={id} label={t("Week View")} scale="weekly" selected={hasDailyChargingData} />
                <PillTab id={id} label={t("Month View")} scale="monthly" selected={!hasDailyChargingData} />
              </ul>
            </div>
          </div>

          {dailyChargeEnergySummary &&
            <div
              className={(hasDailyChargingData ? "opacity-100 " : "opacity-0 ") + "hidden transition-opacity duration-150 ease-linear data-[te-tab-active]:block"}
              id={`tabs-weekly-${id}`}
              role="tabpanel"
              data-te-tab-active={hasDailyChargingData ? "" : undefined}
              aria-labelledby={`tabs-weekly-tab-${id}`}>
              <div className="bg-[#EFF3F3] rounded-b-xl  pb-[10px]">
                <div className="flex flex-row justify-between mx-[10px] md:mx-[20px] py-[20px]">
                  <div className="font-sans-bold text-sm">{t("Weekly Charging")}</div>
                  {dailyChargeEnergySummary.length > 0 &&
                    <div className="text-sm font-sans-light">{formatDate(dailyChargeEnergySummary[0].Date)} {t("thru")} {formatDate(dailyChargeEnergySummary[dailyChargeEnergySummary.length - 1].Date)}</div>}
                </div>
                <div className="md:px-[10px]">
                  <ChargingChart
                    data={dailyChargeEnergySummary.map(energy => {
                      return {
                        date: wide ? t(energy.Date.toLocaleDateString(i18n.language, { weekday: 'short' })) : energy.Date.toLocaleDateString(i18n.language, { weekday: 'short' })[0],
                        offPeak: energy.OffPeakEnergy,
                        onPeak: energy.OnPeakEnergy
                      }
                    })}
                    height={chartHeightPx}
                    margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                    barSize={wide ? 20 : 10}
                  />
                </div>
              </div>
            </div>
          }
          {monthlyChargeEnergySummary &&
            <div
              className={(!hasDailyChargingData ? "opacity-100 " : "opacity-0 ") + "hidden transition-opacity duration-150 ease-linear data-[te-tab-active]:block"}
              id={`tabs-monthly-${id}`}
              role="tabpanel"
              data-te-tab-active={!hasDailyChargingData ? "" : undefined}
              aria-labelledby={`tabs-monthly-tab-${id}`}>
              <div className="bg-[#EFF3F3] rounded-b-xl pb-[10px]">
                <div className="flex flex-row justify-between mx-[10px] md:mx-[20px] py-[20px]">
                  <div className="font-sans-bold text-sm">{t("Monthly Charging")}</div>
                  <div className="text-sm font-sans-light">{formatDate(monthlyChargeEnergySummary[0].Date, false)} {t("thru")} {formatDate(monthlyChargeEnergySummary[monthlyChargeEnergySummary.length - 1].Date, false)}</div>
                </div>
                <div className="md:px-[10px]">
                  <ChargingChart
                    data={monthlyChargeEnergySummary.map(energy => {
                      return {
                        date: wide ? t(energy.Date.toLocaleDateString(i18n.language, { month: 'short' })) : energy.Date.toLocaleDateString(i18n.language, { month: 'short' })[0],
                        offPeak: energy.OffPeakEnergy,
                        onPeak: energy.OnPeakEnergy
                      }
                    })}
                    height={chartHeightPx}
                    margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                    barSize={wide ? 20 : 10}
                  />
                </div>
              </div>
            </div>
          }
        </>
      }
    </div >
  );
};
