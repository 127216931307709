import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Car } from './types/car';
import { RootState } from './rootState';
import { useSelector } from 'react-redux';
import { resetState } from './actions';


export interface CarState {
  cars?: Car[];
  managedChargingEnabled: boolean;
  optOutEnabled: boolean;
  firstManagableCar?: Car;
  loading: boolean;
}

const initialState: CarState = {
  cars: undefined,
  managedChargingEnabled: false,
  optOutEnabled: false,
  firstManagableCar: undefined,
  loading: true
};

const carSlice = createSlice({
  name: 'carSlice',
  initialState,
  reducers: {
    updateCars: (state, action: PayloadAction<Car[] | undefined>) => {
      state.cars = action.payload;
    },
    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    updateManagedChargingEnabled: (state, action: PayloadAction<boolean>) => {
      state.managedChargingEnabled = action.payload;
    },
    updateOptOutEnabled: (state, action: PayloadAction<boolean>) => {
      state.optOutEnabled = action.payload;
    },
    updateFirstManagableCar: (state, action: PayloadAction<Car | undefined>) => {
      state.firstManagableCar = action.payload;
    }
  },
  extraReducers: (builder) => builder.addCase(resetState, () => initialState),
});

export const { updateCars, updateLoading: updateCarsLoading, updateManagedChargingEnabled, updateOptOutEnabled, updateFirstManagableCar } = carSlice.actions;
export default carSlice.reducer;

export const useCars = (): Car[] | undefined => useSelector((state: RootState) => state.carSlice.cars);
export const useManagedChargingEnabled = (): boolean => useSelector((state: RootState) => state.carSlice.managedChargingEnabled);
export const useOptOutEnabled = (): boolean => useSelector((state: RootState) => state.carSlice.optOutEnabled);
export const useFirstManagableCar = (): Car | undefined => useSelector((state: RootState) => state.carSlice.firstManagableCar);
export const useCarsLoading = (): boolean => useSelector((state: RootState) => state.carSlice.loading);

