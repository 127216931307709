import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './rootState';
import { useSelector } from 'react-redux';
import { UserInfoDTO } from '../apis/energyNetTypes';
import { resetState } from './actions';

export interface UserInfoState {
  userInfo?: UserInfoDTO;
}

const initialState: UserInfoState = {
  userInfo: undefined,
};

const useInfoSlice = createSlice({
  name: 'userInfoSlice',
  initialState,
  reducers: {
    updateUserInfo: (state, action: PayloadAction<UserInfoDTO | undefined>) => {
      state.userInfo = action.payload;
    }
  },
  extraReducers: (builder) => builder.addCase(resetState, () => initialState),
});

export const { updateUserInfo } = useInfoSlice.actions;
export default useInfoSlice.reducer;

export const useUserInfo = (): UserInfoDTO | undefined => useSelector((state: RootState) => state.userInfoSlice.userInfo);

