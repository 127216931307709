import React from "react";

type TextInputProps = {
  name: string;
  label: string;
  value: string;
  type: React.HTMLInputTypeAttribute;
  required?: boolean;
  pattern?: string;
  title?: string;
  placeholder?: string;
  fullHeight?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onInvalid?: (e: React.FormEvent<HTMLInputElement>) => void;
};

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>((props, ref) => {
  const { name, label, value, type, required, pattern, placeholder, fullHeight, title, onChange, onInvalid } = props;
  return (
    <div>
      {label &&
        <div>
          <label className={"text-sm text-action"} htmlFor={name}>
            {label}
          </label>
          <br />
        </div>
      }
      <input
        className={`w-full text-lg p-[5px] 
                    box-border border-action border-solid border
                    focus:outline-2 hover:outline-2  
                    focus:outline hover:outline
                    focus:outline-action hover:outline-action
                    transition-all duration-300 ${fullHeight ? " h-full" : " h-[35px] md:h-[45px]"}`}
        id={name}
        name={name}
        type={type}
        required={required}
        onChange={onChange}
        onInvalid={onInvalid}
        value={value}
        ref={ref}
        pattern={pattern}
        title={title}
        placeholder={placeholder}
      />
    </div>
  );
});
