import { useRef } from "react";
import Modal from "../Shared/Modal";
import useBrandConfig from "../../hooks/useBrandConfig";
import { useTranslation } from 'react-i18next';
import questionIcon from "../../static/ui/question-mark-is-small.svg"; // Import question mark icon
import ToggleSwitch from "../Shared/ToggleSwitch";

export type DashboardManageChargingProps = {
  managedChargingEnabled: boolean;
  optOutEnabled: boolean;
  onOverride: (managedChargingEnable: boolean, optOutEnable: boolean) => void;
};

export const DashboardManageCharging: React.FC<DashboardManageChargingProps> = ({
  managedChargingEnabled,
  optOutEnabled,
  onOverride,
}) => {

  const handleChangeToggle = () => {
    if (managedChargingEnabled && !optOutEnabled) {
      changeChargingButtonRef.current?.click();
    }
    else {
      // turn charging back on, no need to confirm
      onOverride(true, false);
    }

  };

  // Function to handle managed charging help toggle
  const togglePopup = () => {
    infoModalButtonRef.current?.click();
  };

  const config = useBrandConfig();
  const changeChargingButtonRef = useRef<HTMLButtonElement>(null);
  const infoModalButtonRef = useRef<HTMLButtonElement>(null);

  const chargingOn = managedChargingEnabled && !optOutEnabled;
  const { t } = useTranslation();

  return (
    <div className={`w-full h-[64px] flex flex-row justify-between items-center border-t border-b border-black 
        ${chargingOn ? "" : "bg-gray-100"}`}>

      <div className="container flex flex-row items-center">
        <span className={(config.dashboardManagedChargingText.length > "Managed Charging".length ? "text-sm" : "text-lg") + " font-sans-bold md:pl-[20px] md:text-xl"}>
          {config.dashboardManagedChargingText}
        </span>
        <div className="relative">
          <img src={questionIcon} alt="question mark" className="h-[20px] ml-[5px] w-[30px] cursor-pointer" onClick={togglePopup} />
        </div>
      </div>

      <ToggleSwitch checked={chargingOn} testId="charging-toggle" handleChangeToggle={handleChangeToggle} stateText={chargingOn ? t("ON") : (managedChargingEnabled && optOutEnabled ? t("PAUSED") : t("OFF"))} />

      <Modal id="take-charge-modal" label="Enable Manual Charging" openButtonRef={changeChargingButtonRef} noPadding cancelButton>
        <div className={"flex flex-col items-center text-center"}>
          <div className="p-4">
            <div className={"text-lg"}>{t("You’ve opted to turn off app-managed charging.")}</div>
            <div className={"text-lg mt-[20px] font-sans-bold"}>{t("Would you like this change to be permanent?")}</div>
          </div>
          <div className={"flex justify-center mt-[5px] border-t w-full border-gray-400"} data-te-modal-dismiss>
            <button onClick={() => onOverride(false, false)} className={"text-action w-1/2 py-[10px] text-lg border-r border-gray-400"}>{t("Yes")}</button>
            <button onClick={() => onOverride(true, true)} className={"text-action w-1/2 py-[10px] text-lg"}>{t("No")}</button>
          </div>
        </div>
      </Modal>

      <Modal id="charging-info-modal" label="Other" openButtonRef={infoModalButtonRef} cancelButton={"Close"} wide>
        <div className="p-[10px] mt-[40px]">
          <div className={"font-sans-bold text-lg pb-[10px]"}>{config.dashboardManagedChargingText}</div>
          <div className="pb-[10px]">{t("'On' will leave your vehicles opted in for managed charging events.")}</div>
          <div className="pb-[10px]">{t("'Off' will opt your vehicles  out for managed charging events indefinitely.")}</div>
          <div className="pb-[10px]">{t("'Paused' will opt your vehicles out of managed charging events for the next 12 hours before reverting back to 'On'.")}</div>
        </div>
      </Modal>
    </div >
  );
};


