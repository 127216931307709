import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import reportWebVitals from "./reportWebVitals";
import { App } from "./components/App/App";
import { HelmetProvider } from "react-helmet-async";

import "./fonts/Roboto/Roboto-Regular.ttf";
import "./fonts/Roboto/Roboto-Light.ttf";
import "./fonts/Roboto/Roboto-Bold.ttf";
import "./fonts/Roboto/RobotoCondensed-Regular.ttf";

import './i18n/config';

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
