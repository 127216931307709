import { RefObject } from "react";
import Modal from "../Shared/Modal";
import { Button } from "../Shared/Button";
import { useTranslation } from 'react-i18next';


const VirtualKeyInstructionsModal = ({ openButtonRef }:
  {
    openButtonRef?: RefObject<HTMLButtonElement>,
  }) => {

  const { t } = useTranslation();
  const handleVirtuaKeyClick = () => {
    window.open("https://wiki.flexcharging.com/en/public/userguides/guides/evision/tesla-signup", "_blank");
  };

  return (
    <Modal id="virtual-key-instructions" label="Tesla Virtual Key Instructions" openButtonRef={openButtonRef} cancelButton wide>
      <div>
        <h2>Virtual Key Setup Required for Program Participation</h2>
        <p>
          Set up your Tesla Virtual Key by clicking the link below.
          We won’t be able to manage your vehicle’s charging or enroll you in incentives programs until you do.
        </p>
        <Button variant="primary" text={t("Set Up Tesla Virtual Key") + " >>"} onClick={handleVirtuaKeyClick} />
      </div>
    </Modal>
  );
};

export default VirtualKeyInstructionsModal;