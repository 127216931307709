import audiLogo from "../static/logos/AUDI.png";
import bmwLogo from "../static/logos/BMW.png";
import cadillacLogo from "../static/logos/CADILLAC.png";
import chevroletLogo from "../static/logos/CHEVROLET.png";
import chryslerLogo from "../static/logos/CHRYSLER.png";
import cupraLogo from "../static/logos/CUPRA.png";
import fordLogo from "../static/logos/FORD.png";
import hyundaiLogo from "../static/logos/HYUNDAI.png";
import jaguarLogo from "../static/logos/JAGUAR.png";
import jeepLogo from "../static/logos/JEEP.png";
import kiaLogo from "../static/logos/KIA.png";
import landroverLogo from "../static/logos/LANDROVER.png";
import lexusLogo from "../static/logos/LEXUS.png";
import lincolnLogo from "../static/logos/LINCOLN.png";
import mercedesLogo from "../static/logos/MERCEDESBENZ.png";
import miniLogo from "../static/logos/MINI.png";
import porscheLogo from "../static/logos/PORSCHE.png";
import nissanLogo from "../static/logos/NISSAN.png";
import renaultLogo from "../static/logos/RENAULT.png";
import rivianLogo from "../static/logos/RIVIAN.png";
import teslaLogo from "../static/logos/TESLA.png";
import toyotaLogo from "../static/logos/TOYOTA.png";
import volkswagenLogo from "../static/logos/VOLKSWAGEN.png";
import volvoLogo from "../static/logos/VOLVO.png";
import otherLogo from "../static/logos/OTHER.png";

export type Make = {
  name: string,
  value: string,
  logo: string,
  managed: boolean,
  europe?: boolean,
};

const allCarMakes: Make[] = [
  { name: "Audi", value: "AUDI", logo: audiLogo, managed: false },
  { name: "BMW", value: "BMW", logo: bmwLogo, managed: true },
  { name: "Cadillac", value: "CADILLAC", logo: cadillacLogo, managed: false },
  { name: "Chevrolet", value: "CHEVROLET", logo: chevroletLogo, managed: false },
  { name: "Chrysler", value: "CHRYSLER", logo: chryslerLogo, managed: false },
  { name: "Cupra", value: "CUPRA", logo: cupraLogo, managed: true, europe: true },
  { name: "Ford", value: "FORD", logo: fordLogo, managed: true },
  { name: "Hyundai", value: "HYUNDAI", logo: hyundaiLogo, managed: true },
  { name: "Jaguar", value: "JAGUAR", logo: jaguarLogo, managed: true },
  { name: "Jeep", value: "JEEP", logo: jeepLogo, managed: false },
  { name: "Kia", value: "KIA", logo: kiaLogo, managed: true },
  { name: "Land Rover", value: "LANDROVER", logo: landroverLogo, managed: true },
  { name: "Lexus", value: "LEXUS", logo: lexusLogo, managed: true },
  { name: "Lincoln", value: "LINCOLN", logo: lincolnLogo, managed: false },
  { name: "Mercedes-Benz", value: "MERCEDES_BENZ", logo: mercedesLogo, managed: true },
  { name: "Mini", value: "MINI", logo: miniLogo, managed: true },
  { name: "Nissan", value: "NISSAN", logo: nissanLogo, managed: true },
  { name: "Porsche", value: "PORSCHE", logo: porscheLogo, managed: false },
  { name: "Renault", value: "RENAULT", logo: renaultLogo, managed: false },
  { name: "Rivian", value: "RIVIAN", logo: rivianLogo, managed: false },
  { name: "Tesla", value: "TESLA", logo: teslaLogo, managed: true },
  { name: "Toyota", value: "TOYOTA", logo: toyotaLogo, managed: true },
  { name: "Volkswagen", value: "VOLKSWAGEN", logo: volkswagenLogo, managed: true },
  { name: "Volvo", value: "VOLVO", logo: volvoLogo, managed: false },
  { name: "Other", value: "OTHER", logo: otherLogo, managed: true }
];

export default allCarMakes;