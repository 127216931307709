import useBrandConfig from "../../hooks/useBrandConfig";
import { MulticolorHeadline } from "../Shared/MulticolorHeadline";

export const LogInHeader = () => {
  const config = useBrandConfig();
  return (
    <MulticolorHeadline
      firstSentence={config.loginHeadlineOne}
      secondSentence={config.loginHeadlineTwo} />
  );
};
