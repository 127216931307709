import { MouseEventHandler } from "react";

export type ButtonProps = {
  text: string;
  variant: "primary" | "secondary";
  pill?: boolean;
  noBorder?: boolean;
  fullWidth?: boolean;
  busy?: boolean;
  shadow?: boolean;
  disabled?: boolean;
  testId?: string;
  submit?: boolean;
  extraStyles?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>
};

export const Button = ({ text, busy, disabled, variant: style, pill, noBorder, fullWidth, shadow, testId, submit, extraStyles, onClick }: ButtonProps) => {

  const primary = style === "primary";
  const borderClass = noBorder ? "border-none" : ((busy || disabled) ? "border-gray-500" : "border-action");
  const bgClass = (busy || disabled) ? (primary ? "bg-gray-500" : "bg-transparent") : (primary ? "bg-action" : "bg-transparent");
  const textClass = primary ? "text-white" : ((busy || disabled) ? "text-gray-500" : "text-action");
  const shadowClass = shadow ? "shadow-md shadow-gray-500" : "";

  return (
    <button className={`${extraStyles} font-sans-bold text-lg ${fullWidth ? "w-full" : (pill ? "px-[20px]" : "w-[226px]")} border-[3px] 
        ${pill ? "rounded-full py-[2px]" : "rounded-md py-[8px]"}
        ${borderClass} ${bgClass} ${textClass} ${shadowClass}`}
      type={submit ? "submit" : "button"}
      disabled={busy || disabled}
      data-testid={testId}
      onClick={onClick}
    >
      {busy ? `${text}...` : text}
    </button>);
};
