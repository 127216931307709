import { useNavigate } from "react-router-dom";
import { lazy, Suspense } from "react";
import { RewardsProgramInformationCard } from "../components/RewardsProgramInformationCard/RewardsProgramInformationCard";
import { BackButton } from "../components/Shared/BackButton";
import { BasicPageHeader } from "../components/Shared/BasicPageHeader";
import { ResponsiveLayout } from "../components/Shared/ResponsiveLayout";
import useBrandConfig from "../hooks/useBrandConfig";
import { t } from "i18next";
import capitalize from "../helpers/capitalize";

export const Rewards = () => {
  const navigate = useNavigate();
  const config = useBrandConfig();

  const handleBackClick = () => {
    navigate("/dashboard");
  };


  // dynamically load the program info component based on the brand if is configured
  const ProgramInfo = config.hasProgramInfo && lazy(() => import(`./ProgramInfo/${capitalize(config.programName)}Info`));

  return (
    <ResponsiveLayout>
      <>
        <BackButton text={t("Back")} onClick={handleBackClick} />
        <div className={"mb-[24px]"}>
          <BasicPageHeader title={config.rewardsHeadline} />
        </div>
        {[0, 1, 2, 3].map((row) => {
          return (
            <div key={row} className={"lg:flex lg:gap-3"}>
              {config.rewardsCards.filter((card) => card.row === row).map((card, index) => {
                return (
                  <RewardsProgramInformationCard
                    key={index}
                    header={card.title}
                    dollars={card.amount}
                    body={card.text}
                    footer={card.subtext}
                  />
                )
              })}
            </div>)
        })}

        {ProgramInfo &&
          <div className="py-[40px]">
            <Suspense>
              <ProgramInfo />
            </Suspense>
          </div>
        }
      </>
    </ResponsiveLayout >
  );
};
