type CountryConfig = {
  postalCodeRegex: RegExp,
  postalCodeTitle: string,
  stateRegex: RegExp,
  stateTitle: string,
  cityRegex: RegExp
  phonePlaceholder: string,
  phoneRegex: RegExp
};

const countryConfigs: { [key: string]: CountryConfig } = {
  "United States": {
    postalCodeRegex: /^[\d\s-]+$/,
    postalCodeTitle: "Postal Code",
    stateRegex: /^[^0-9,]*$/,
    stateTitle: "State",
    cityRegex: /^[^0-9,]*$/,
    phonePlaceholder: "xxx-xxx-xxxx",
    phoneRegex: /\+?1?[-\s(]?\d{3}[-\s)]?\d{3}[-\s]?\d{4}/
  },
  "Ireland": {
    postalCodeRegex: /^[A-Z0-9\s]+$/,
    postalCodeTitle: "Eircode",
    stateRegex: /^[a-zA-Z0-9,\s.]*$/,
    stateTitle: "County",
    cityRegex: /^[a-zA-Z0-9,\s.]*$/,
    phonePlaceholder: "",
    phoneRegex: /^(?:(?:\+)?353(?:-|\s*)?|)(?:[1-9]\s*\d(?:\s*\d|-){5,8})\s*$/
  }
};

const getCountryConfig = (country: string): CountryConfig => {
  return countryConfigs[country] || countryConfigs["United States"];
};

export default getCountryConfig;