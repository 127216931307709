import smartlookClient from 'smartlook-client'

const isTrackedEnv = process.env.NODE_ENV === 'production';
let isInitialized = false;

export const initTracking = () => {
  if (!isInitialized && isTrackedEnv) {
    smartlookClient.init("3742ef0954a7958c257279749f86d7dd6ad267b0");
    isInitialized = true;
  }
};

export const trackError = (message: string, error?: any) => {
  if (isTrackedEnv && isInitialized) {
    if (error) {
      console.log(message, error);
      smartlookClient.error(`${message} : " ${JSON.stringify(error)}`);
    }
    else {
      console.log(message);
      smartlookClient.error(message);
    }
  }
};

export const trackUser = (email: string) => {
  // only need to pass this once and smartlook will match it with sessions from then on.
  if (isTrackedEnv) {
    if (!email || email.endsWith('@flexcharging.com') || email.endsWith('@flex.com')) {
      return;
    };

    initTracking();
    smartlookClient.identify(email, {});
  }
};