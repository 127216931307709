import useBrandConfig from "../../hooks/useBrandConfig";
import { getImageURL } from "../../brand-config";
import { useAuth } from "../../hooks/useAuth/useAuth";

const HeaderTitle = () => {
  const config = useBrandConfig();

  return (
    (config.headerProgramNameLineOne && config.headerProgramNameLineTwo) ?
      <div>
        <div className={"flex justify-center flex-col align-middle h-[45px]"}>
          <span className={"text-sm font-bold text-brandsecondary tracking-wide"}>{config.headerProgramNameLineOne}</span>
          <span className={"text-sm font-bold text-brandprimary mt-[-4px] tracking-wide"}>{config.headerProgramNameLineTwo}</span>
        </div>
      </div> :
      <div>
        <div className={"flex justify-center flex-col h-full"}>
          <img
            className={config.headerImageAutoScale ? "h-[45px] md:h-[56px]" : "h-[45px]"}
            alt={config.headerLeftImageAlt}
            src={getImageURL("rewardsProgram", config.programName)} />
        </div>
      </div>
  );
};

const HeaderLogos = () => {
  const config = useBrandConfig();

  const HeaderImg = () => <img className={config.headerImageAutoScale ? "h-[45px] md:h-[56px]" : "h-[45px]"}
    alt={config.headerRightImageAlt || config.websiteTitle}
    src={getImageURL("logo", config.programName)}
  />;

  return (
    <div>
      {config.logoLinkToRewardsPage ?
        <a href="/rewards">
          <HeaderImg />
        </a> :
        <HeaderImg />
      }
    </div>
  );
};

export const Header = () => {
  const { isImpersonating, email } = useAuth();
  return (
    <div className={"my-[60px] md:mb-[70px]"}>
      {isImpersonating &&
        <span className="text-sm text-red-500">Impersonating user {email}</span>
      }
      <div className={"flex justify-between"}>
        <HeaderTitle />
        <HeaderLogos />
      </div>
    </div>
  );
};
