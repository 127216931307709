import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useAuth } from "../useAuth/useAuth";
import useBrandConfig from "../useBrandConfig";
import { useCallback } from "react";

// hook to encupsulate the app insights track event function
export const useTrackEvent = () => {
  const appInsights = useAppInsightsContext();
  const config = useBrandConfig();
  const { email, testUser, isImpersonating } = useAuth();

  // need a useCallback here so it doesn't force re-renders over and over
  return useCallback((name: string, properties: { [key: string]: any } = {}) => {
    if (isImpersonating) {
      // don't pollute tracking with impersonation events
      return;
    }

    if (appInsights && !testUser) {
      appInsights.trackEvent({ name }, { email: email, program: config.programName, ...properties });
    }
  }, [appInsights, email, config.programName, testUser, isImpersonating]);
};
