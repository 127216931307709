import { ChangeEvent, RefObject, useRef, useState } from "react";
import Modal from "./Modal";
import { TextInput } from "./TextInput";
import { Button } from "./Button";
import useBrandConfig from "../../hooks/useBrandConfig";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../hooks/useAuth/useAuth";

// modal to show entry of unsupport car type. 
// @param openButtonRef: optional ref to click to open (otherwise put data-te-target="#other-modal-input" on your button)
// @param handleAddOtherVehicle: callback when a name is entered
const UnsupportedEVModal = ({ openButtonRef, handleAddOtherVehicle }:
  {
    openButtonRef?: RefObject<HTMLButtonElement>,
    handleAddOtherVehicle: (make: string, model: string, email: string) => void
  }) => {
  const config = useBrandConfig();
  const { t } = useTranslation();
  const [unsupportedEvMake, setUnsupportedEvMake] = useState("");
  const [unsupportedEvModel, setUnsupportedEvModel] = useState("");
  const [email, setEmail] = useState("");
  const otherModalThanksButtonRef = useRef<HTMLButtonElement>(null);
  const { email: loggedInEmail } = useAuth();

  const handleChangeUnsupportedMake = (event: ChangeEvent<HTMLInputElement>) => {
    event.target.setCustomValidity("");
    setUnsupportedEvMake(event.target.value);
  };

  const handleChangeUnsupportedModel = (event: ChangeEvent<HTMLInputElement>) => {
    event.target.setCustomValidity("");
    setUnsupportedEvModel(event.target.value);
  };

  const handleChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    event.target.setCustomValidity("");
    setEmail(event.target.value);
  };

  const clickSubmit = () => {
    handleAddOtherVehicle(unsupportedEvMake, unsupportedEvModel, email);

    // clear the values in case they come back
    setUnsupportedEvMake("");
    setUnsupportedEvModel("");
  };

  return (
    <div>
      <Modal id="other-modal-input" label="Other" openButtonRef={openButtonRef} cancelButton wide>
        <div className={"flex flex-col items-center text-left"}>
          <div className={"text-lg mt-[20px]"}>{config.addVehicleOtherModalText}</div>
          <div className="mt-[10px] w-full">
            <TextInput name="manufacturer" label={t("Enter Vehicle Make")} placeholder={t("Vehicle Make")} type="text" value={unsupportedEvMake} required onChange={handleChangeUnsupportedMake} />
          </div>
          <div className="mt-[10px] w-full">
            <TextInput name="name" label={t("Enter Vehicle Model")} placeholder={t("Vehicle Model")} type="text" value={unsupportedEvModel} required onChange={handleChangeUnsupportedModel} />
          </div>
          {!loggedInEmail &&
            <>
              <div className="mt-[20px]">{t("Provide your email address and we'll let you know as soon as your make and model becomes available.")}</div>
              <div className="mt-[20px] w-full">
                <TextInput name="email" label={t("Email Address")} placeholder={t("Email")} type="text" value={email} onChange={handleChangeEmail} />
              </div>
            </>
          }
          <div className="w-[80px] mt-[30px]" data-te-modal-dismiss>
            <Button text={t("SUBMIT")} variant="primary" testId="ok-button-add" disabled={!unsupportedEvMake || !unsupportedEvModel} pill fullWidth
              onClick={() => { otherModalThanksButtonRef.current?.click() }} />
          </div>
        </div>
      </Modal>

      <Modal id="other-modal-thanks" label="Other" openButtonRef={otherModalThanksButtonRef}>
        <div className={"flex flex-col items-center text-center"}>

          {/* Do not remove blank space after a full stop. Due to this message, "Thank you for your interest.Unfortunately," */}
          <div className={"text-lg mt-[20px]"}>{t("Thank you for your interest. ")}&nbsp;
            {t((email || loggedInEmail) ? "unsupported EV error" : "unsupported EV error no email", { unsupportedEvMake: `${unsupportedEvMake} ${unsupportedEvModel}` })}
          </div>
          <div className={"flex justify-between mt-[30px] mb-[20px] w-[135px]"} data-te-modal-dismiss>
            <Button text="OK" variant="primary" testId="ok-button-thankyou" pill fullWidth onClick={clickSubmit} />
          </div>
        </div>
      </Modal>
    </div>);
};

export default UnsupportedEVModal;