import { t } from "i18next";
export const SignUpFooter = () => {
  return (
    <div className={"mt-[30px] mb-[30px] flex flex-col flex-wrap"}>
      <div className={"m-auto text-lg text-center"}>
        FlexCharging&nbsp;
        <a
          className={"link text-action font-sans-bold"}
          href={"https://www.flexcharging.com/terms-of-use"}
          target="_blank"
          rel="noreferrer"
        >
          {t("Terms Of Service")}
        </a>
        &nbsp;and&nbsp;
        <a
          className={"link text-action font-sans-bold"}
          href={"https://www.flexcharging.com/privacy-policy"}
          target="_blank"
          rel="noreferrer"
        >
          {t("Privacy Policy")}
        </a>
      </div>
    </div>
  );
};
