import { RefObject, useEffect } from "react";
import { Modal, initTE } from "tw-elements";
import { t } from "i18next";

// @param id: string - The id of the modal
// @param title: string - The title of the modal
// @param label: string - The label of the modal
// @param noPadding: boolean - Whether the modal should have padding
// @param wide: boolean - Whether the modal should be extra wide
// @param cancelButton: boolean - Whether the modal should have a cancel button or string - The text of the cancel button
// @param children: JSX.Element - The UI of the modal
// @param openButtonRef: RefObject<HTMLButtonElement> - a button ref that will open the modal when click() is called
const ModalComponent = ({ id, title, label, noPadding, wide, cancelButton, children, openButtonRef }:
  { id: string, label: string, title?: string, noPadding?: boolean, wide?: boolean, cancelButton?: (boolean | string), children: JSX.Element; openButtonRef?: RefObject<HTMLButtonElement> }) => {
  useEffect(() => {
    initTE({ Modal });
  }, []);
  return (
    <div
      data-te-modal-init
      className={`fixed top-0 left-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none`}
      id={id}
      aria-hidden="true"
      aria-label={label}
    >
      <div
        role="dialog"
        data-te-modal-dialog-ref
        className={`${wide ? "w-[320px]" : "w-[270px]"} pointer-events-none relative translate-y-[-50px] opacity-0 transition-all duration-300 ease-in-out mx-auto mt-7 min-[576px]:max-w-[500px]`}>
        <div
          className={`min-[576px]:shadow-[0_0.5rem_1rem_rgba(#000, 0.15)] pointer-events-auto relative flex w-full flex-col 
                      rounded-xl border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600`}>
          {title &&
            <div
              className="flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
              <h5
                className="text-xl font-medium leading-normal text-neutral-800 dark:text-neutral-200"
                id="exampleModalLabel">
                {title}
              </h5>
              <button
                type="button"
                className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                data-te-modal-dismiss
                aria-label="Close">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="h-6 w-6">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          }
          <div className={`relative flex-auto ${noPadding ? "" : "p-4"}`} data-te-modal-body-ref>
            {cancelButton &&
              <button className={"text-lg font-sans-bold text-right ml-auto pt-[10px] pr-[20px] text-action w-full"} data-te-modal-dismiss type="button">
                {typeof cancelButton === 'string' ? t(cancelButton) : t("Cancel")}
              </button>
            }
            {children}
          </div>
        </div>
      </div>
      {openButtonRef &&
        <button
          className="hidden"
          data-te-toggle="modal"
          data-te-target={`#${id}`}
          ref={openButtonRef}
        />
      }
    </div>
  );
};
;
export default ModalComponent;