// zendesk webwidget configuration
// doc: https://developer.zendesk.com/api-reference/widget/settings/
//      https://developer.zendesk.com/api-reference/widget/core/

// this zendesk interface wasn't getting put on the window object so explicitly refrerence it
/// <reference path="../../node_modules/zendesk-widget-typings/index.d.ts" />

export const setupZenDesk = (email: string | null, name: string | null) => {
  const zE = window.zE;
  if (!zE) return;

  if (email) {

    zE("webWidget", "show");

    zE("webWidget", "prefill", {
      name: {
        value: name,
      },
      email: {
        value: email,
        readOnly: true // can't edit email.. so we can always match the account
      }
    });
  }
  else {
    // only show widget for authenticated users to avoid spam bots    
    zE("webWidget", "hide");
  }

  zE("webWidget", "updateSettings", {
    webWidget: {
      // TODO: obtain this color from the tailwind config. currently gets runtime error if I import it.
      color: {
        launcher: "#00A8CD", // color of the launcher button
        launcherText: "#FFFFFF", // color of the button text
        header: '#00A8CD', // color of the header in the form      
      },
      launcher: {
        label: {
          'en-US': 'Help',
          es: "Ayuda"
        },
      }
    }
  });
}
