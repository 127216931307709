import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './rootState';
import { useSelector } from 'react-redux';
import { resetState } from './actions';

// this is auth state for the admin Auth0 account, not the user sites.
export interface AuthState {
  token: string | null;
  program: string | null;
  programList: string[] | undefined;
}

const initialState: AuthState = {
  token: null,
  program: null,
  programList: undefined
};

const authSlice = createSlice({
  name: 'tokenSlice',
  initialState,
  reducers: {
    updateToken: (state, action: PayloadAction<string | null>) => {
      state.token = action.payload;
    },
    updateProgram: (state, action: PayloadAction<string | null>) => {
      state.program = action.payload;
      state.programList = action.payload?.split(',');
    }
  },
  extraReducers: (builder) => builder.addCase(resetState, () => initialState),
});

export const { updateToken, updateProgram } = authSlice.actions;
export default authSlice.reducer;

export const useToken = (): string | null => useSelector((state: RootState) => state.authSlice.token);

// supports multiple program separated by commas
export const useProgram = (): string[] => useSelector((state: RootState) => state.authSlice.programList || []);

