function isDayBeforeMonth(): boolean {
  const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'numeric', year: 'numeric' };
  const formatter = new Intl.DateTimeFormat(navigator.language, options);
  const parts = formatter.formatToParts(new Date());

  const dayIndex = parts.findIndex(part => part.type === 'day');
  const monthIndex = parts.findIndex(part => part.type === 'month');

  return dayIndex < monthIndex;
}

export default isDayBeforeMonth;