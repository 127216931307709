import { useLocation } from "react-router-dom";
import { getBrandConfig } from "../brand-config";

export const RootLayout = ({
  brand,
  wide = false,
  children,
}: {
  brand?: string,
  wide?: boolean,
  children: JSX.Element;
}) => {
  const location = useLocation();
  const config = getBrandConfig();
  const customLandingColor = (config.hasLandingPage && location.pathname === "/") ? " bg-gradient-to-r from-[#2BD8E1] to-[#49FDBB]" : "";

  // brand is a also class that will pick the theme in tailwind.config.js
  // wide true is for the admin 
  return (

    wide ?
      <div className={`h-screen mx-[50px] lg:mx-[100px] ${brand} bg-page`}>
        <div className={`text-default`}>
          {children}
        </div>
      </div >
      :
      <div className={`grid grid-cols-12 ${brand} bg-page ${customLandingColor}`}>
        <div className={`col-start-2 col-span-10 md:col-start-3 md:col-span-8 text-default`}>
          {children}
        </div>
      </div>
  )
}