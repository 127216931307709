import { useNavigate } from "react-router-dom";
import greenCheckmark from "../static/ui/greenCheckmark.png";
import { ResponsiveLayout } from "../components/Shared/ResponsiveLayout";
import { MulticolorHeadline } from "../components/Shared/MulticolorHeadline";
import { Button } from "../components/Shared/Button";
import { useSignUpStep } from "../hooks/useSignUpStep/useSignUpStep";
import useBrandConfig from "../hooks/useBrandConfig";
import { useEffect } from "react";
import { t } from "i18next";

export const LocationInstructions = () => {
  const navigate = useNavigate();
  const config = useBrandConfig();
  const [signUpStep, setSignUpStep] = useSignUpStep();

  useEffect(() => {
    if (signUpStep)
      setSignUpStep("locationinstructions");
  }, [signUpStep, setSignUpStep]);

  const handleContinueClick = () => {
    setSignUpStep("addlocation");
    navigate("/addlocation");
  };

  return (
    <ResponsiveLayout>
      <>
        <MulticolorHeadline firstSentence={config.locationInstructionsHeadlineOne} secondSentence={config.locationInstructionsHeadlineTwo} breakBetween />
        <div className={"flex flex-col md:items-center text-2xl md:text-4xl mt-[60px] md:mt-[85px]"}>
          <div className="flex">
            <div className={"flex w-[40px]"}>
              <img
                className={"w-[25px] mt-auto mb-auto"}
                alt={t("EV Connected")}
                src={greenCheckmark}
              />
            </div>
            <h3 className={"md:pl-[10px]"}>
              {config.signUpStepOne}
            </h3>
          </div>
          <h3 className={"font-sans-bold pt-[14px] md:pt-[40px] "}>
            <span>2</span>
            <span className={"ml-[25px]"}>{config.signUpStepTwo}</span>
          </h3>
        </div>

        <div className={"mt-[60px] mb-[20px] flex justify-center"}>
          <Button variant="primary" text={t("Add Location")} onClick={handleContinueClick} />
        </div>
      </>
    </ResponsiveLayout>
  );
};
