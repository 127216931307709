///summary
/// This file defines a React component named ResetPassword, which handles the password reset functionality in a web application.

/// Imports
import React, { ChangeEvent, useState } from "react"; // Importing necessary modules from React
import { useNavigate } from "react-router-dom"; // Importing the useNavigate hook from React Router
import { ResetPasswordForm } from "../components/ResetPasswordForm/ResetPasswordForm"; // Importing the ResetPasswordForm component
import { requestPasswordReset } from "../apis/energyNetApi"; // Importing the requestPasswordReset function from energyNetApi
import { ResponsiveLayout } from "../components/Shared/ResponsiveLayout"; // Importing the ResponsiveLayout component
import { BasicPageHeader } from "../components/Shared/BasicPageHeader"; // Importing the BasicPageHeader component
import useBrandConfig from "../hooks/useBrandConfig"; // Importing the useBrandConfig hook

/// ResetPassword Component
export const ResetPassword = () => {
  // Initializing variables
  const navigate = useNavigate(); // useNavigate hook for navigation
  const config = useBrandConfig(); // Custom hook to fetch brand configuration

  // State variables managed by useState hook
  const [formInputs, setFormInputs] = useState({
    email: "",
  });
  const [showRequestSentMessage, setShowRequestSentMessage] = useState(false);
  const [busy, setBusy] = useState(false);
  const [requestSentMessage, setrequestSentMessage] = useState("");

  // Function to validate email format
  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formInputs.email || !emailRegex.test(formInputs.email)) {
      setrequestSentMessage("Please provide a valid email address.");
      return false;
    } else {
      setrequestSentMessage("");
      return true;
    }
  };

  // Event handler for input changes
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    (event.target as HTMLInputElement).setCustomValidity("");
    setFormInputs({
      ...formInputs,
      [event.target.name]: event.target.value,
    });
  };

  // Event handler for form submission
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setBusy(true);
    if (validateEmail()) {
      try {
        setrequestSentMessage("If that email address is on file, you should receive a link in your inbox in the next few minutes. Follow that link to update your password.");
        await requestPasswordReset(formInputs.email);
      } catch (e) {
        // raises 404 when email invalid, but do not want to message user for security reasons
      }
    }
    setShowRequestSentMessage(true);
    setBusy(false);
  };

  // Event handler for login button click
  const handleLogInButtonClick = () => {
    navigate("/login");
  };

  // Rendering JSX
  return (
    <ResponsiveLayout>
      <>
        <BasicPageHeader title={config.resetPasswordHeadline} />
        <div className={"form-contents-container"}>
          {/* ResetPasswordForm component */}
          <ResetPasswordForm
            inputs={formInputs}
            onChange={handleChange}
            onSubmit={handleSubmit}
            busy={busy}
            message={showRequestSentMessage ? requestSentMessage : ""}
            onLogInButtonClick={handleLogInButtonClick}
          />
        </div>
      </>
    </ResponsiveLayout>
  );
};
