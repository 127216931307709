export type Environment = {
  ENVIRONMENT: string;
  BACKEND_API: string;
  ADMIN_API: string;
  ENERGY_NET_API_BASIC_AUTH_USERNAME: string;
  ENERGY_NET_API_BASIC_AUTH_PASSWORD: string;
  SMARTCAR_CLIENT_ID: string;
  SMARTCAR_REDIRECT: string;
  SMARTCAR_TEST_MODE: boolean;
  BRAND?: string;
  GOOGLE_MAPS_API_KEY: string;
  INSIGHTS_CONNECTION_STRING: string;
  RECAPTCHA_SITE_KEY: string;
  AUTH0_DOMAIN: string;
  AUTH0_CLIENT_ID: string;
  AUTH0_AUDIENCE: string;
};

const {
  REACT_APP_ENVIRONMENT,
  REACT_APP_BACKEND_API,
  REACT_APP_ADMIN_API,
  REACT_APP_ENERGY_NET_API_BASIC_AUTH_USERNAME,
  REACT_APP_ENERGY_NET_API_BASIC_AUTH_PASSWORD,
  REACT_APP_SMARTCAR_CLIENT_ID,
  REACT_APP_SMARTCAR_REDIRECT,
  REACT_APP_SMARTCAR_TEST_MODE,
  REACT_APP_BRAND,
  REACT_APP_GOOGLE_MAPS_API_KEY,
  REACT_APP_INSIGHTS_CONNECTION_STRING,
  REACT_APP_RECAPTCHA_SITE_KEY,
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_AUDIENCE
} = (window as any).__env__;

const getBrand = () => {
  // TODO - change environment variable to be more generic
  if ((REACT_APP_ENVIRONMENT === "production" || REACT_APP_ENVIRONMENT === "development") && window?.location?.hostname) {
    // in production, brand is driven by hostname 
    const parts = window.location.hostname.toLowerCase().split(".");
    if (parts.length === 3 && parts[1] === "flexcharging" && parts[2] === "com") {
      return parts[0];
    }
  };
  return REACT_APP_BRAND;
};

const getRedirectUrl = () => {
  if (REACT_APP_ENVIRONMENT === "development") {
    if (window.location.hostname === "localhost") {
      return `${REACT_APP_SMARTCAR_REDIRECT}http://localhost:3000`;
    }
    else {
      return `${REACT_APP_SMARTCAR_REDIRECT}https://evision.flexcharging.com`;
    }
  }
  return REACT_APP_SMARTCAR_REDIRECT;
};

const env: Environment = {
  ENVIRONMENT: REACT_APP_ENVIRONMENT,
  BACKEND_API: REACT_APP_BACKEND_API,
  ADMIN_API: REACT_APP_ADMIN_API,
  ENERGY_NET_API_BASIC_AUTH_USERNAME:
    REACT_APP_ENERGY_NET_API_BASIC_AUTH_USERNAME,
  ENERGY_NET_API_BASIC_AUTH_PASSWORD:
    REACT_APP_ENERGY_NET_API_BASIC_AUTH_PASSWORD,
  SMARTCAR_CLIENT_ID: REACT_APP_SMARTCAR_CLIENT_ID,
  SMARTCAR_REDIRECT: getRedirectUrl(),
  SMARTCAR_TEST_MODE: REACT_APP_SMARTCAR_TEST_MODE === "true",
  BRAND: getBrand(),
  GOOGLE_MAPS_API_KEY: REACT_APP_GOOGLE_MAPS_API_KEY,
  INSIGHTS_CONNECTION_STRING: REACT_APP_INSIGHTS_CONNECTION_STRING,
  RECAPTCHA_SITE_KEY: REACT_APP_RECAPTCHA_SITE_KEY,
  AUTH0_DOMAIN: REACT_APP_AUTH0_DOMAIN,
  AUTH0_CLIENT_ID: REACT_APP_AUTH0_CLIENT_ID,
  AUTH0_AUDIENCE: REACT_APP_AUTH0_AUDIENCE
};

const getEnv = () => env;

export default getEnv;
