import { RefObject } from "react";
import Modal from "../Shared/Modal"
import { useTranslation } from "react-i18next";

const DemandResponseInfoModal = ({ openButtonRef }: { openButtonRef?: RefObject<HTMLButtonElement>, }) => {
  const { t, i18n } = useTranslation();
  const text = i18n.language === "es" ?
    "La respuesta a la demanda ofrece una oportunidad para que los consumidores jueguen un papel significativo en la operación de la red eléctrica al reducir o cambiar su uso de electricidad durante los períodos pico en respuesta a tarifas basadas en el tiempo u otras formas de incentivos financieros. Los programas de respuesta a la demanda están siendo utilizados por algunos planificadores y operadores del sistema eléctrico como opciones de recursos para equilibrar la oferta y la demanda. Dichos programas pueden reducir el costo de la electricidad en los mercados mayoristas y, a su vez, llevar a tarifas minoristas más bajas." :
    "Demand response provides an opportunity for consumers to play a significant role in the operation of the electric grid by reducing or shifting their electricity usage during peak periods in response to time-based rates or other forms of financial incentives. Demand response programs are being used by some electric system planners and operators as resource options for balancing supply and demand. Such programs can lower the cost of electricity in wholesale markets, and in turn, lead to lower retail rates. ";
  return (
    <Modal id="dr-modal" label="Other" openButtonRef={openButtonRef} cancelButton={"Close"} wide>
      <div className="p-[10px] mt-[40px]">
        <div className={"font-sans-bold text-lg pb-[10px]"}>{t("Understanding Demand Response")}</div>
        <div>{text}</div>
      </div>
    </Modal>
  )
};

export default DemandResponseInfoModal;