import { ChargeEnergySummaryDTO } from "./energyNetTypes";

// this function generates random energy data for a device in the same format at GetChargingEnergySummary from the energyNet API
export const GenerateRandomChargingEnergySummary = async (startDate: Date, endDate: Date, interval: "daily" | "monthly", deviceVin: string) => {

  // seeded random number generator
  function mulberry32(a: number) {
    return function () {
      let t = a += 0x6D2B79F5;
      t = Math.imul(t ^ (t >>> 15), t | 1);
      t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
      return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
    }
  }

  // make some random energy data seeded by the device vin
  const randomEnergyData: ChargeEnergySummaryDTO[] = [];
  let date = new Date(startDate);

  // set the random seed
  let seed = 0;
  for (let i = 0; i < deviceVin.length; i++) {
    seed += deviceVin.charCodeAt(i);
  }

  const getRand = mulberry32(seed);
  const factor = interval === "daily" ? 1 : 30;
  while (date < endDate) {
    randomEnergyData.push({
      Date: date,
      OnPeakEnergy: getRand() * 1 * factor,
      MidPeakEnergy: getRand() * 0,
      OffPeakEnergy: (2 - getRand()) * 5 * factor,
      UnknownEnergy: getRand() * 0
    });
    date = new Date(date);

    if (interval === "daily") {
      date.setDate(date.getDate() + 1);
    } else {
      date.setMonth(date.getMonth() + 1);
    }
  }
  return randomEnergyData;
}

