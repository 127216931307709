import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { DeleteMyData, getChargingLocations } from "../apis/energyNetApi";
import { BackButton } from "../components/Shared/BackButton";
import { BasicPageHeader } from "../components/Shared/BasicPageHeader"
import { PillButton } from "../components/Shared/PillButton";
import { ResponsiveLayout } from "../components/Shared/ResponsiveLayout";
import { Spinner } from "../components/Shared/Spinner";
import { hasAccountKey, useAuth } from "../hooks/useAuth/useAuth";
import logOutArrow from "../static/ui/logOutarrow.svg";
import { useDispatch } from "react-redux";
import { updateChargingLocation, useChargingLocation } from "../store/chargingLocationSlice";
import useBrandConfig from "../hooks/useBrandConfig";
import { useTranslation } from 'react-i18next';
import { useUserInfo } from "../store/userInfoSlice";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import Modal from "../components/Shared/Modal";
import warningIcon from "../static/ui/warningIcon.svg";
import { Button } from "../components/Shared/Button";
import { useTrackEvent } from "../hooks/useTrackEvent/useTrackEvent";
import useLocalStorageState from "use-local-storage-state";

export const Account = () => {
  const { user, email, logout } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useChargingLocation();
  const config = useBrandConfig();
  const { t } = useTranslation();
  const userInfo = useUserInfo();
  const trackevent = useTrackEvent();
  const [, setHasAccount] = useLocalStorageState<true | null>(hasAccountKey, { defaultValue: null });
  const deleteSuccessfulButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (!user) {
      return;
    }

    if (!location) {
      getChargingLocations(user)
        .then((response) => {
          if (response.data.length === 0) {
            dispatch(updateChargingLocation(undefined));
          } else {
            dispatch(updateChargingLocation(response.data[0]));
          }
        })
        .catch((error) => {
          console.log(
            `error calling '${getChargingLocations}': ${error}`
          );
        });
    }

  }, [user, dispatch, location]);

  const onLogOutButtonClick = () => {
    logout();
    navigate("/login")
  };

  const handleBackClick = () => {
    navigate("/dashboard");
  };

  const onEditButtonClick = () => {
    navigate("/editlocation");
  };

  const onTermsAndConditionsButtonClick = () => {
    navigate("/account/termsandconditions");
  };

  const handleConfirmDeleteMyData = async () => {
    if (user) {
      trackevent("DeleteMyData");
      try {
        await DeleteMyData(user, false, false);
        trackevent("DeleteMyDataSuccess");
        deleteSuccessfulButtonRef.current?.click();
      }
      catch {
        trackevent("DeleteMyDataFailed");
      }
    }
  };

  const handleDeleteMyDataSuccess = () => {
    // logout but all clear the hasAccount flag in local storage so it goes to landing or sign up
    logout();
    setHasAccount(null);
    navigate("/");
  };

  return (
    <ResponsiveLayout>
      <>
        <BackButton text={t("Back")} onClick={handleBackClick} />
        <div className="flex justify-between items-center">
          <BasicPageHeader title={t("Account")} />
          <PillButton text={t("Log Out")} img={logOutArrow} onClick={onLogOutButtonClick} />
        </div>

        {(!location || !email) ?
          <div className="h-[120px]">
            <Spinner />
          </div> :
          <div>
            <div className="border-t border-solid border-default mt-[40px]">
              <div className="text-sm mt-[15px]">{t("Email Address")}</div>
              <div className="text-2xl mt-[13px]">
                {email}
              </div>
            </div>
            <div className="border-t border-solid border-default mt-[20px] min-h-[177px]">
              <div className="flex justify-between">
                <div className="text-sm mt-[15px]">{config.addLocationHeader}</div>
                <button className="text-lg mt-[15px] font-sans-bold text-action"
                  onClick={onEditButtonClick}>
                  {t("Edit")}
                </button>
              </div>
              <div className="text-2xl mt-[13px] mb-[17px]">
                <div>
                  {location?.Address}
                </div>
                <div>
                  {location?.City}{location?.City && ","} {location?.State}
                </div>
                <div>
                  {location?.PostalCode}
                </div>
                <div>
                  {location?.Country}
                </div>
              </div>
              {config.hasAccountIdInput &&
                <div>
                  <div className="text-sm mt-[20px]">{config.locationAccountIdName}</div>
                  <div className="text-2xl mt-[13px] mb-[17px]">
                    {location?.UtilityAccountId || t("Not Set")}
                  </div>
                </div>
              }
              {config.hasPhoneNumberInput &&
                <div>
                  <div className="text-sm mt-[20px]">{t("Phone Number")}</div>
                  <div className="text-2xl mt-[13px] mb-[17px] mr-[20px] inline-block">
                    {userInfo?.PhoneNumber ?
                      <span>{formatPhoneNumberIntl(userInfo?.PhoneNumber)}</span> :
                      <button className="text-lg mt-[15px] font-sans-bold text-action"
                        onClick={onEditButtonClick}>
                        {t("Enter Account Phone Number")}
                      </button>}
                  </div>
                  {userInfo?.PhoneNumber &&
                    <div className="text-sm mt-[13px] mb-[17px] inline-block">
                      {t("Text Notifications") + ": " + (userInfo?.EnableSMSNotifications ? t("On") : t("Off"))}
                    </div>
                  }
                </div>
              }
              {config.termsAndConditionsShowOnPage &&
                <div className="border-t border-solid border-default mt-[10px]">
                  <button className="text-lg my-[10px] font-sans-bold text-action text-right w-full underline"
                    onClick={onTermsAndConditionsButtonClick}>
                    {t(config.termsAndConditionsTitle)}
                  </button>
                </div>
              }
              {config.hasDeleteMyData &&
                <>
                  <Modal id="confirm-delete-modal" label="Delete My Data" cancelButton>
                    <div className={"flex flex-col items-center text-center"}>
                      <div className={"mb-[15px] mt-[20px]"}><img src={warningIcon} alt="warning" /></div>
                      <div className={"text-lg"}>{t("You are about to delete all of your program data. This cannot be undone.")}</div>
                      <div className={"text-lg"}>{t("Deleting your data means that all information about your vehicle and historical charging data will be lost.")}</div>
                      <div className={"flex justify-between mt-[30px] mb-[20px] w-[135px]"} data-te-modal-dismiss>
                        <Button text={t("DELETE DATA")} variant="primary" pill fullWidth onClick={handleConfirmDeleteMyData} />
                      </div>
                    </div>
                  </Modal>

                  <Modal id="delete-successful-modal" label="Data Deleted" openButtonRef={deleteSuccessfulButtonRef}>
                    <div className={"flex flex-col items-center text-center"}>
                      <div className={"text-lg"}>{t("Your data was successfully removed from the system and you will now be logged out.")}</div>
                      <div className={"flex justify-between mt-[30px] mb-[20px] w-[135px]"} data-te-modal-dismiss>
                        <Button text={t("Log Out")} variant="primary" pill fullWidth onClick={handleDeleteMyDataSuccess} />
                      </div>
                    </div>
                  </Modal>

                  <div className="border-t border-b border-solid border-default" data-te-toggle="modal"
                    data-te-target="#confirm-delete-modal">
                    <button className="text-lg my-[10px] font-sans-bold text-action text-right w-full underline">
                      {t("Delete My Data")}
                    </button>
                  </div>
                </>
              }
              <div className="h-[100px]">

              </div>
            </div>
          </div>
        }
      </>
    </ResponsiveLayout >
  );
};
