import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { V4AdminDailyMetrics, V4AdminMetricsParams, V4AdminEnergySummary, V4AdminEnergySummaryParams, V4AdminGetDevicesParams, V4AdminGetDevicesResponse, V4AdminHourlyMetrics, V4AdminDailyChargingEnergySummaryDTO, GetDemandResponseEventsRequestDTO, DemandResponseEventInfoDTO, DemandResponseEventResultDTO, ChargeLocationSubMeterDTO } from '../apis/EnergyNetV4ApiTypes';
import { format } from 'date-fns';
import { RootState } from '../../store/rootState';
import getEnv from '../../env';

//
// This is the API interface used by the admin site that calls api v4 /AdminDashboard and /DemandResponse endpoints.
// It uses the Redux Toolkit Query library to create a set of hooks with automatic caching by parameters.
//

const api = createApi({
  reducerPath: 'api',
  keepUnusedDataFor: 300,
  baseQuery: fetchBaseQuery({
    baseUrl: getEnv().ADMIN_API + '/api/v4',
    prepareHeaders: (headers: Headers, { getState }) => {
      // Retrieve the bearer token from the auth slice where it is stored
      const rootState = getState() as RootState;
      const token = rootState.authSlice?.token;

      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }

      return headers;
    }
  }),
  endpoints: (builder) => ({
    energySummary: builder.query<V4AdminEnergySummary, V4AdminEnergySummaryParams>({
      query: ({ programCode, fromDate, toDate, makes, includeTestUsers }) => {
        const dayAfterToDate = toDate ? new Date(toDate) : null;
        if (dayAfterToDate) {
          dayAfterToDate.setDate(dayAfterToDate.getDate() + 1);
        }
        const formattedFromDate = fromDate ? format(fromDate, 'MM-dd-yyyy') : "null";
        const formattedToDate = dayAfterToDate ? format(dayAfterToDate, 'MM-dd-yyyy') : "null";
        let path = programCode ?
          `/AdminDashboard/energy-summary/program-code/${programCode.toLowerCase()}/from-date/${formattedFromDate}/to-date/${formattedToDate}` :
          `/AdminDashboard/energy-summary/from-date/${formattedFromDate}/to-date/${formattedToDate}`;
        if (makes && makes.length > 0) {
          // add all makes to the path in their own query strings make=make1&make=make2&make=make3
          path += "?" + makes.map((make) => (`make=${make}`)).join('&');
        }
        if (includeTestUsers) {
          path += `?includeTestUsers=true`;
        }
        return path;
      }
    }),
    getDevices: builder.query<V4AdminGetDevicesResponse, V4AdminGetDevicesParams>({
      query: ({ programCode, fromDate, toDate, makes, isActive, connectedStatus, includeTestUsers, search, pageNumber, pageSize, sortDirection, sortColumn }) => {
        const dayAfterToDate = toDate ? new Date(toDate) : null;
        if (dayAfterToDate) {
          dayAfterToDate.setDate(dayAfterToDate.getDate() + 1);
        }
        const formattedFromDate = fromDate ? format(fromDate, 'MM-dd-yyyy') : "null";
        const formattedToDate = dayAfterToDate ? format(dayAfterToDate, 'MM-dd-yyyy') : "null";
        let path = programCode ?
          `/AdminDashboard/user-devices/program-code/${programCode.toLowerCase()}/from-date/${formattedFromDate}/to-date/${formattedToDate}` :
          `/AdminDashboard/user-devices/from-date/${formattedFromDate}/to-date/${formattedToDate}`
        const queryString = [];
        queryString.push("includeMeta=true");
        if (makes && makes.length > 0) {
          // add all makes to the path in their own query strings make=make1&make=make2&make=make3      
          makes.forEach((make) => {
            queryString.push(`make=${make}`);
          });
        }
        if (isActive !== null) {
          queryString.push(`isActive=${isActive}`);
        }
        if (connectedStatus && connectedStatus.length > 0) {
          queryString.push(`connectedStatus=${connectedStatus.join(',')}`);
        }
        if (includeTestUsers) {
          queryString.push('includeTestUsers=true');
        }
        if (search) {
          queryString.push(`search=${search}`);
        }
        if (pageNumber) {
          queryString.push(`pageNumber=${pageNumber}`);
        }
        if (pageSize) {
          queryString.push(`pageSize=${pageSize}`);
        }
        if (sortDirection) {
          queryString.push(`sortDirection=${sortDirection}`);
        }
        if (sortColumn) {
          queryString.push(`sortColumn=${sortColumn}`);
        }
        if (queryString.length > 0) {
          path += "?" + queryString.join('&');
        }

        return path;
      },
    }),
    setUtilityAccountId: builder.mutation<void, { email: string, utilityAccountId: string, chargeLocationGuid: string }>({
      query: ({ email, utilityAccountId, chargeLocationGuid }) => ({
        url: `/AdminDashboard/user/charge-location/guid/${chargeLocationGuid}`,
        method: 'PUT',
        body: {
          UserEmailAddress: email,
          UtilityAccountId: utilityAccountId
        },
      }),
      async onQueryStarted({ email, utilityAccountId, chargeLocationGuid }, { dispatch, queryFulfilled }) {
        try {
          //dispatch(api.util.updateQueryData('getDevices', )  setUtilityAccountId({ email, utilityAccountId }));
          // Wait for the actual API request to complete
          await queryFulfilled;
        } catch (err) {
          // If the API request fails, you can revert the optimistic update here
          console.error('Error updating utility account ID:', err);
        }
      },
    }),
    getDailyMetrics: builder.query<V4AdminDailyMetrics, V4AdminMetricsParams>({
      query: ({ fromDate, toDate, hashedDeviceIds }) => {
        const formattedFromDate = fromDate ? format(fromDate, 'MM-dd-yyyy') : "null";
        const formattedToDate = toDate ? format(toDate, 'MM-dd-yyyy') : "null";
        let path = `/AdminDashboard/daily-metrics/from-date/${formattedFromDate}/to-date/${formattedToDate}`;
        if (hashedDeviceIds && hashedDeviceIds.length > 0) {
          // add all hashedDeviceIds to the path in their own query strings hashedDeviceId=hash1&hashedDeviceId=hash2&hashedDeviceId=hash3
          // must encode because hashed device ids can have + and /
          path += "?" + hashedDeviceIds.map((hashedDeviceId: string) => (`hashedDeviceIds=${encodeURIComponent(hashedDeviceId)}`)).join('&');
        }
        return path;
      }
    }),
    getHourlyMetrics: builder.query<V4AdminHourlyMetrics, V4AdminMetricsParams>({
      query: ({ fromDate, toDate, hashedDeviceIds }) => {
        const formattedFromDate = fromDate ? format(fromDate, 'MM-dd-yyyy') : "null";
        const formattedToDate = toDate ? format(toDate, 'MM-dd-yyyy') : "null";
        let path = `/AdminDashboard/hourly-metrics/from-date/${formattedFromDate}/to-date/${formattedToDate}`;
        if (hashedDeviceIds && hashedDeviceIds.length > 0) {
          // add all hashedDeviceIds to the path in their own query strings hashedDeviceId=hash1&hashedDeviceId=hash2&hashedDeviceId=hash3
          // must encode because hashed device ids can have + and /
          path += "?" + hashedDeviceIds.map((hashedDeviceId: string) => (`hashedDeviceIds=${encodeURIComponent(hashedDeviceId)}`)).join('&');
        }
        return path;
      }
    }),
    getDailyChargingEnergy: builder.query<V4AdminDailyChargingEnergySummaryDTO, V4AdminMetricsParams>({
      query: ({ fromDate, toDate, hashedDeviceIds }) => {
        const formattedFromDate = fromDate ? format(fromDate, 'MM-dd-yyyy') : "null";
        const formattedToDate = toDate ? format(toDate, 'MM-dd-yyyy') : "null";
        let path = `/AdminDashboard/daily-charging-energy/from-date/${formattedFromDate}/to-date/${formattedToDate}`;

        return {
          url: path,
          method: 'POST',
          body: {
            hashedDeviceIds: hashedDeviceIds
          }
        }
      }
    }),
    getImpersonationToken: builder.query<string, { userId: string }>({
      query: ({ userId }) => {
        let path = `/AdminDashboard/impersonation-token/user-id/${userId}`;
        return path;
      }
    }),
    getDemandResponseEvents: builder.query<DemandResponseEventInfoDTO[], GetDemandResponseEventsRequestDTO>({
      query: (param) => ({
        url: '/DemandResponse/GetDemandResponseEvents',
        method: 'POST',
        body: param
      }),
      transformResponse: (response: DemandResponseEventInfoDTO[]) => {
        // Sort the response data here
        return response.sort((a, b) => {
          // Replace this with your actual sorting logic
          // This example sorts by an 'id' field in ascending order
          return new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
        });
      }
    }),
    getDemandResponseEvent: builder.query<DemandResponseEventResultDTO, { eventId: string, showParticipants: boolean }>({
      query: ({ eventId, showParticipants }) => {
        let path = `/DemandResponse/GetDemandResponseEvent/demand-response-event/id/${eventId}?showParticipants=${showParticipants}`;
        return path;
      }
    }),
    getChargeLocationSubMeters: builder.query<ChargeLocationSubMeterDTO[], { programCode: string }>({
      query: ({ programCode }) => {
        let path = `/ChargingLocation/GetChargeLocationSubMeters/program-code/${programCode}`;
        return path;
      },
      transformResponse: (response: ChargeLocationSubMeterDTO[]) => {
        // remove rows with duplicate ids
        return response.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);
      }
    }),
    setChargeLocationSubMeter: builder.mutation<void, { id: number, programCode: string, processCMEPData: boolean, }>({
      query: ({ id, programCode, processCMEPData }) => ({
        url: "/ChargingLocation/UpdateChargingLocationSubmeter",
        method: 'PUT',
        body: {
          Id: id,
          ProgramCode: programCode,
          ProcessCMEPData: processCMEPData
        },
      }),

      async onQueryStarted({ id, programCode, processCMEPData }, { dispatch, queryFulfilled }) {
        // Optimistic update
        const patchResult = dispatch(
          api.util.updateQueryData('getChargeLocationSubMeters', { programCode }, (draft) => {
            const subMeterToUpdate = draft.find(subMeter => subMeter.id === id);
            if (subMeterToUpdate) {
              subMeterToUpdate.processCMEPData = processCMEPData;
            }
          })
        );
        try {
          await queryFulfilled;
        } catch {
          // If the mutation fails, revert the optimistic update
          patchResult.undo();
        }
      },
    }),
  })
});

/*
      if (hashedDeviceIds && hashedDeviceIds.length > 0) {
        // add all hashedDeviceIds to the path in their own query strings hashedDeviceId=hash1&hashedDeviceId=hash2&hashedDeviceId=hash3
        // must encode because hashed device ids can have + and /
        path += "?" + hashedDeviceIds.map((hashedDeviceId: string) => (`hashedDeviceIds=${encodeURIComponent(hashedDeviceId)}`)).join('&');
      }
      */

export const {
  useEnergySummaryQuery,
  useGetDevicesQuery,
  useSetUtilityAccountIdMutation,
  useGetDailyMetricsQuery,
  useGetHourlyMetricsQuery,
  useGetDailyChargingEnergyQuery,
  useGetImpersonationTokenQuery,
  useGetDemandResponseEventsQuery,
  useGetDemandResponseEventQuery,
  useGetChargeLocationSubMetersQuery,
  useSetChargeLocationSubMeterMutation
} = api;

export default api;
