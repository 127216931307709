import React from "react";
import { Outlet } from "react-router-dom";
import getEnv from "../env";
import { RootLayout } from "./RootLayout";

export const Root = ({ wide }: { wide?: boolean }) => {
  var brand = getEnv().BRAND;
  return (
    <RootLayout wide={wide} brand={brand}>
      <Outlet />
    </RootLayout>
  );
};
