// Spinner taken from https://tailwind-elements.com/docs/standard/components/spinners/
export const Spinner = ({ size = "sm", offset = false }: { size?: "sm" | "lg", offset?: boolean | undefined }) => {
  return (
    <div className={`flex justify-center h-full ${offset ? "p-[100px]" : "items-center"}`}>
      <div
        className={`inline-block ${size === "sm" ? "h-8 w-8 border-4" : "h-16 w-16 border-8"} animate-spin rounded-full border-solid border-action border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]`}
        role="status">
        <span
          className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
        >Waiting...</span
        >
      </div>
    </div >
  );
};
