// import { config } from 'process';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useBrandConfig from '../../hooks/useBrandConfig';//../hooks/useBrandConfig';

const TermsAndConditionsText = ({ program, inScrollBox }: { program: string, inScrollBox: boolean }) => {
  const [legalContent, setLegalContent] = useState('');
  const { i18n } = useTranslation();
  const config = useBrandConfig();

  useEffect(() => {
    // Fetch the HTML content from the legal.html file
    const languageCode = i18n.language === 'es' && config.termsAndConditionsTranslated ? ("_" + i18n.language) : "";
    fetch(`/docs/termsandconditions_${program.toLocaleLowerCase()}${languageCode}.htm`)
      .then((response) => response.text())
      .then((htmlContent) => setLegalContent(htmlContent))
      .catch((error) => console.error('Error fetching legal content:', error));
  }, [program, config.termsAndConditionsTranslated, i18n.language]);

  return (
    <div className="bg-white flex flex-col">
      <div className={inScrollBox ? "border  p-[20px] max-h-[216px] w-full overflow-y-auto " : ""}
        dangerouslySetInnerHTML={{ __html: legalContent }}
      />
    </div>

  );
};

export default TermsAndConditionsText;
