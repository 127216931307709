export const IconButton = ({ image, onClick, altText }: { image: string; onClick: () => void; altText: string; }) => {
  return (
    <button
      aria-label={altText}
      className={"p-0 bg-transparent border-none outline-none"}
      onClick={onClick}
    >
      <img className={"w-full h-full"} src={image} alt={altText} title={altText} />
    </button>
  );
};

export default IconButton;