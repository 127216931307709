import { useEffect } from "react";
import { useAuth } from "../hooks/useAuth/useAuth";
import { useNavigate } from "react-router-dom";

// for now this is just a page that redirect back to add vehicle when the tesla auth is finish
export const Redirect = () => {
  const { user } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    // jump over to addvehicle page    
    if (user && window?.location?.href?.includes("/redirect")) {
      const redirectUrl = window.location.href;
      navigate("/addvehicle", { state: { redirectUrl } });
    }
  }, [user, navigate]);

  return null;
};