import isDayBeforeMonth from "./isDayBeforeMonth";

const formatDate = (date?: Date, showDay: boolean = true, dots: boolean = true) => {
  if (!date) {
    return '';
  }

  // Get the year, month, and day from the Date object
  const year = date.getFullYear().toString().slice(-2); // Get last two digits of the year
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
  const day = date.getDate().toString().padStart(2, '0');
  const sep = dots ? '.' : '/';

  if (isDayBeforeMonth()) {
    // Concatenate the formatted year, month, and day with the period separator
    // If Spanish, format the date with Spanish format
    return showDay ? `${day}${sep}${month}${sep}${year}` : `${month}${sep}${year}`;
  } else {
    // If not Spanish, format the date with default format
    return showDay ? `${month}${sep}${day}${sep}${year}` : `${month}${sep}${year}`;
  }
};

export default formatDate;