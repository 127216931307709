// renders a historgram chart of user charging for a week using recharts
import React from "react";
import { BarChart, Bar, XAxis, YAxis, Legend, LegendProps, ResponsiveContainer, Label } from "recharts";
import { useTranslation } from 'react-i18next';

interface ChargingChartProps {
  data?: Array<{ date: string; offPeak: number, onPeak: number }>;
  height?: number;  // pixel height of the chart
  margin?: object;
  barSize?: number;
  barGap?: number;
  barCategoryGap?: number;
}

/* this didn't work well
const RoundedBar = (props: {
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  offPeak?: number;
  onPeak?: number;
  fill?: string;
  radius?: number;
  animation?: boolean;
}) => {
  const { x, y, width, height, offPeak, onPeak } = props;

  if (!x || !y || !width || height === undefined || (!onPeak && !offPeak)) return null;

  const zeroedOffPeak = offPeak || 0;
  const zeroedOnPeak = onPeak || 0;

  // Define the radius for rounding the top corners
  const radius = width / 2;

  const total = zeroedOffPeak + zeroedOnPeak;
  const heightOffPeak = (zeroedOffPeak / total) * height;

  return (
    <g>
      <rect x={x} y={y} width={width} height={height} fill="#EE7251" rx={radius}
        ry={radius} />
      {zeroedOnPeak &&
        <rect x={x} y={y + height - heightOffPeak} width={width} height={heightOffPeak / 2} fill="#76E4A9" />
      }
      <rect x={x} y={y + height - heightOffPeak} width={width} height={heightOffPeak} fill="#76E4A9" rx={radius}
        ry={radius} />
    </g>
  );


    // Create a custom SVG path to form a rectangle with rounded tops
    // const path = `M${x},${y + radius}
    //               L${x},${y + height}
    //               L${x + width},${y + height}
    //               L${x + width},${y + radius}
    //               Q${x + width},${y} ${x + width - radius},${y}
    //               L${x + radius},${y}
    //               Q${x},${y} ${x},${y + radius}
    //               Z`;
  
    // return <path d={path} fill={fill} />;

};
*/

const axisTextStyle = {
  fontSize: '13px', // Specify the font size
  //fontFamily: 'Arial, sans-serif', // Specify the font family
  fill: '#000', // Specify the text color
  //fontWeight: 'bold', // Specify the font weight
};

interface CustomLegendProps {
  payload?: LegendProps['payload'];
}

const ChargingChart: React.FC<ChargingChartProps> = ({
  data,
  height,
  margin,
  barSize,
  barGap,
  barCategoryGap,
}) => {
  const { t } = useTranslation();
  const legendItemsToShow = ['offPeak', 'onPeak']; // Specify the legend items you want to show

  const CustomLegend: React.FC<CustomLegendProps> = ({ payload }) => {
    if (!payload) return null;
    const filteredPayload = payload.filter(item => legendItemsToShow.includes(item.value));

    return (
      <div className="flex flex-row  justify-center w-full">
        {
          filteredPayload.map((payload, index) => (
            <div key={payload.value} className="flex flex-row items-center mx-[10px] ">
              <div className="text-xs">
                {payload.value === "offPeak" ? t("Off-Peak") : payload.value === "onPeak" ? t("On-Peak") : t("Shifted")}
              </div>
              <div className="w-[20px] h-[5px] ml-[5px] rounded" style={{ backgroundColor: payload.color }} />
            </div>
          ))
        }
      </div >
    );
  };

  return (
    <ResponsiveContainer width="100%" height={height}>
      <BarChart
        height={height}
        data={data}
        margin={margin}
        barSize={barSize}
        barGap={barGap}
        barCategoryGap={barCategoryGap}
      >
        <XAxis dataKey="date" axisLine={false} tick={{ style: axisTextStyle }} tickLine={false} />
        <YAxis axisLine={false} tick={{ style: axisTextStyle }} tickLine={false} type="number" scale="linear" width={70} tickCount={4}>
          <Label value="kWh" position="insideLeft" angle={-90} fill="#000" style={{ textAnchor: 'middle', fontSize: '13px', fontWeight: 'normal' }}
            offset={20} />
        </YAxis>
        <Legend content={<CustomLegend />} />
        <Bar dataKey="offPeak" fill="#76E4A9" stackId="energy" />
        <Bar dataKey="onPeak" fill="#EE7251" stackId="energy" />
        <Bar dataKey="shifted" fill="#71BAF1" stackId="energy" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ChargingChart;