import getEnv from "./env";
import { getCurrentLanguageCode } from "./i18n/config";
import Cookies from 'js-cookie';

//
// shared config types
//

// This is a global config for the brands variations in the application
export type RewardsCardConfig = {
  title: string,
  amount?: string,
  text: string,
  subtext?: string,
  row: number;
}

// BrandConfigSettings are config setting that should be the same regardless of language
type BrandConfigSettings = {
  testMode: boolean,
  utilityName: string,
  distributionUtilityName: string,
  defaultCountry: string,
  defaultCountryCode: string,
  rateSchedule: string,
  programName: string,
  hasLandingPage: boolean,
  headerImageAutoScale: boolean,
  hasSignUpReferralInput: boolean,
  termsAndConditionsShowOnPage: boolean,
  termsAndConditionsConsentRequired: boolean,
  addVehicleOnlyShowManaged: boolean,
  addVehicleHiddenMakes: string[],
  dashboardEnableOverride: boolean,
  dashboardDeleteVehicleText: boolean,
  dashboardDemandResponse: boolean,
  dashboardShowVirtualKeyMessage: boolean,
  logoLinkToRewardsPage: boolean,
  hasAccountIdInput: boolean,
  accountIdRequired: boolean,
  hasDeleteMyData: boolean
  hasPhoneNumberInput: boolean,
  phoneNumberRequired: boolean,
  dashboardChargingCharts: boolean,
  hasProgramInfo: boolean, // has a component in the routes/ProgramInfo folder to put on the Rewards page.
  termsAndConditionsTranslated: boolean,
  flagNewConnector: boolean
  flagTeslaFleet: boolean,
  adminShowSubmeters: boolean;
}

// BrandConfigText is all of the configurable text on the site, which must be translated to different languages
type BrandConfigText = {
  websiteTitle: string,
  websiteMetaDescription: string,
  headerLeftImageAlt: string,
  headerRightImageAlt: string,
  loginHeadlineOne: string,
  loginHeadlineTwo: string,
  loginForgotPasswordText: string,
  loginForgotPasswordLink: string,
  resetPasswordHeadline: string,
  termsAndConditionText: string,
  termsAndConditionsLink: string,
  termsAndConditionsLinkText: string,
  termsAndConditionsTitle: string,
  accountCreatedHeadlineOne: string,
  accountCreatedHeadlineTwo: string,
  signUpStepOne: string,
  signUpStepTwo: string,
  addLocationText: string,
  addLocationHeader: string,
  locationInstructionsHeadlineOne: string,
  locationInstructionsHeadlineTwo: string,
  locationAddedHeadlineOne: string,
  locationAddedHeadlineTwo: string,
  locationAddedButtonText: string;
  locationAccountIdName: string,
  locationAccountIdLabel: string,
  locationAccountIdDescription: string,
  welcomeToText: string,
  welcomeButtonText: string,
  addVehicleHeader: string,
  addVehicleInstructions: string,
  addVehicleHeadlineOne: string,
  addVehicleHeadlineTwo: string,
  addVehicleOtherModalText: string,
  dashboardHeadline: string,
  dashboardProgramStatus: string,
  dashboardManagedChargingText: string,
  rewardsHeadline: string,
  smsConsentTerms: string,
  rewardsCards: RewardsCardConfig[],
  demandResponseName: string,
  headerProgramNameLineOne: string,
  headerProgramNameLineTwo: string,
}

// The final BrandConfig consumed externally is a combination of the settings and text
export type BrandConfig = BrandConfigSettings & BrandConfigText;

const defaultSettings: BrandConfigSettings = {
  testMode: false,
  utilityName: "",
  distributionUtilityName: "",
  defaultCountry: "United States",
  defaultCountryCode: "US",
  rateSchedule: "Residential",
  programName: "",
  hasLandingPage: false,
  headerImageAutoScale: true,
  hasSignUpReferralInput: false,
  termsAndConditionsShowOnPage: false,
  termsAndConditionsConsentRequired: false,
  addVehicleOnlyShowManaged: false,
  addVehicleHiddenMakes: [],
  dashboardEnableOverride: false,
  dashboardDeleteVehicleText: false,
  dashboardDemandResponse: true,
  dashboardShowVirtualKeyMessage: true,
  logoLinkToRewardsPage: false,
  hasAccountIdInput: false,
  accountIdRequired: false,
  hasPhoneNumberInput: false,
  hasDeleteMyData: false,
  phoneNumberRequired: false,
  dashboardChargingCharts: true,
  hasProgramInfo: false,
  termsAndConditionsTranslated: false,
  flagNewConnector: true,
  flagTeslaFleet: false,
  adminShowSubmeters: false,
};

const defaultEnglishText: BrandConfigText = {
  websiteTitle: "",
  websiteMetaDescription: "",
  headerLeftImageAlt: "",
  headerRightImageAlt: "",
  loginHeadlineOne: "More green in your car",
  loginHeadlineTwo: "means more green in your pocket.",
  loginForgotPasswordText: "I forgot my account password",
  loginForgotPasswordLink: "Reset Password",
  resetPasswordHeadline: "Forgot Password?",
  termsAndConditionText: "By joining I agree to the terms above",
  termsAndConditionsLink: "",
  termsAndConditionsLinkText: "",
  termsAndConditionsTitle: "Terms and Conditions Agreement",
  accountCreatedHeadlineOne: "You have a managed charging rewards account.",
  accountCreatedHeadlineTwo: "Now let's connect your electric vehicle.",
  signUpStepOne: "Connect your EV",
  signUpStepTwo: "Add a location",
  locationInstructionsHeadlineOne: "Almost there!",
  locationInstructionsHeadlineTwo: "Add a charging location.",
  addLocationText: "The program requires reliable cell service/signal at the home charging location.",
  addLocationHeader: "Home Charging Address",
  locationAddedHeadlineOne: "Congratulations!",
  locationAddedHeadlineTwo: "Your charging location has been added.",
  locationAddedButtonText: "Continue",
  locationAccountIdName: "Utility Account Id",
  locationAccountIdLabel: "",
  locationAccountIdDescription: "",
  welcomeToText: "to your program!",
  welcomeButtonText: "Start Smart Charging!",
  addVehicleHeader: "Which EV do you drive?",
  addVehicleHeadlineOne: "Yes!",
  addVehicleHeadlineTwo: "Your electric vehicle is connected.",
  addVehicleInstructions: "Select the manufacturer of the EV you want to register.",
  addVehicleOtherModalText: "Please specify the make and model of your EV so we can work on including your vehicle in the future.",
  dashboardHeadline: "My Dashboard",
  dashboardProgramStatus: "Program Status",
  dashboardManagedChargingText: "Managed Charging",
  smsConsentTerms: "You are consenting to receive text messages for upcoming energy Demand Responses events and connection issues with any vehicle registered to a service address within the program you are participating in. Messages and data rates may apply. For Terms and Use, visit",
  rewardsHeadline: "My Rewards",
  rewardsCards: [],
  demandResponseName: "Demand Response Event",
  headerProgramNameLineOne: "",
  headerProgramNameLineTwo: "",
};

// TODO: translate this completely
const defaultSpanishText: BrandConfigText = {
  websiteTitle: "",
  websiteMetaDescription: "",
  headerLeftImageAlt: "",
  headerRightImageAlt: "",
  loginHeadlineOne: "Más verde en tu coche",
  loginHeadlineTwo: "significa más verde en tu bolsillo.",
  loginForgotPasswordText: "Olvidé la contraseña de mi cuenta.",
  loginForgotPasswordLink: "Restablecer Contraseña",
  resetPasswordHeadline: "¿Olvidaste tu contraseña?",
  termsAndConditionText: "Al unirme, acepto los términos anteriores.",
  termsAndConditionsLink: "",
  termsAndConditionsLinkText: "",
  termsAndConditionsTitle: "Acuerdo de Términos y Condiciones",
  accountCreatedHeadlineOne: "Tienes una cuenta de recompensas por carga gestionada.",
  accountCreatedHeadlineTwo: "Ahora vamos a conectar tu vehículo eléctrico.",
  signUpStepOne: "Conecta tu vehículo eléctrico",
  signUpStepTwo: "Agregar una ubicación",
  locationInstructionsHeadlineOne: "Casi hemos llegado.",
  locationInstructionsHeadlineTwo: "Agregar una ubicación de carga.",
  addLocationText: "El programa requiere un servicio/señal celular confiable en el lugar de carga del hogar.",
  addLocationHeader: "Dirección de carga en casa",
  locationAddedHeadlineOne: "¡Felicidades!",
  locationAddedHeadlineTwo: "Tu ubicación de carga ha sido agregada.",
  locationAddedButtonText: "Continuar",
  locationAccountIdName: "",
  locationAccountIdLabel: "",
  locationAccountIdDescription: "",
  welcomeToText: "a tu programa!",
  welcomeButtonText: "¡Comienza la Carga Inteligente!",
  addVehicleHeader: "¿Qué vehículo eléctrico conduces?",
  addVehicleHeadlineOne: "Sí",
  addVehicleHeadlineTwo: "Tu vehículo eléctrico está conectado.",
  addVehicleInstructions: "Selecciona el fabricante del vehículo eléctrico que deseas registrar.",
  addVehicleOtherModalText: "Especifica la marca y el modelo de tu vehículo eléctrico para que podamos trabajar en incluir tu vehículo en el futuro.",
  dashboardHeadline: "Mi Tablero",
  dashboardProgramStatus: "Estado del Programa",
  dashboardManagedChargingText: "Carga Gestionada",
  rewardsHeadline: "Mi Recompensa",
  smsConsentTerms: "Estás consintiendo en recibir mensajes de texto para eventos futuros de Respuestas de Demanda de Energía y problemas de conexión con cualquier vehículo registrado en una dirección de servicio dentro del programa en el que estás participando. Se pueden aplicar tarifas por mensajes y datos. Para los Términos de Servicio, visita",
  rewardsCards: [],
  demandResponseName: "Evento de Respuesta de Demanda",
  headerProgramNameLineOne: "",
  headerProgramNameLineTwo: "",
};

//
//  specific brand configs
//

//#region EVISION
const evisionSettings: BrandConfigSettings = {
  ...defaultSettings,
  testMode: true,
  programName: "EVISION",
  dashboardEnableOverride: true,
};

const evisionEnglish: BrandConfig = {
  ...evisionSettings,
  ...defaultEnglishText,
  websiteTitle: "EVision Admin Portal",
  websiteMetaDescription: "EVision Admin Portal",
  headerRightImageAlt: "EVision Admin Portal",
};

const evisionSpanish: BrandConfig = {
  ...evisionSettings,
  ...defaultSpanishText,
  // Spanish translations
  websiteTitle: "Portal de administración de EVision",
  websiteMetaDescription: "Portal de administración de EVision",
  headerRightImageAlt: "Portal de administración de EVision",
};

//#endregion

//#region MY

const mySettings: BrandConfigSettings = {
  ...defaultSettings,
  hasLandingPage: true,
  utilityName: "Universal Signup",
  programName: "MY",
  headerImageAutoScale: false,
  dashboardEnableOverride: true,
};

const myEnglishText: BrandConfigText = {
  ...defaultEnglishText,
  websiteTitle: "FlexCharging Rewards",
  websiteMetaDescription: "FlexCharging Rewards",
  loginHeadlineOne: "Enroll in our smart charging platform now",
  loginHeadlineTwo: "to start saving money and the planet.",
  accountCreatedHeadlineOne: "You have a Smart Charging Rewards account.",

};

const myEnglish: BrandConfig = {
  ...mySettings,
  ...myEnglishText,
};

const mySpanish: BrandConfig = {
  ...mySettings,
  ...defaultSpanishText,
  // spanish translations
  websiteTitle: "Recompensas de carga flexible",
  websiteMetaDescription: "Recompensas de carga flexible",
  loginHeadlineOne: "Inscríbase en nuestra plataforma de carga inteligente ahora",
  loginHeadlineTwo: "para comenzar a ahorrar dinero y el planeta.",
  accountCreatedHeadlineOne: "Tienes una cuenta de recompensas de carga inteligente.",
};

//#endregion

//#region PVREA
const pvreaSettings: BrandConfigSettings = {
  ...defaultSettings,
  testMode: false,
  utilityName: "Poudre Valley Rural Electric Association",
  programName: "PVREA",
  headerImageAutoScale: false,
  termsAndConditionsConsentRequired: true,
  termsAndConditionsShowOnPage: true,
  dashboardEnableOverride: true,
  dashboardDeleteVehicleText: true,
  logoLinkToRewardsPage: true,
  addVehicleHiddenMakes: ["JEEP", "CHEVROLET", "FORD"],
  adminShowSubmeters: true
};

const pvreaEnglishText: BrandConfigText = {
  ...defaultEnglishText,
  websiteTitle: "DrivEV Smart Charging Rewards",
  websiteMetaDescription: "Poudre Valley DrivEV Smart Charging Rewards",
  headerLeftImageAlt: "DrivEV Smart Charging Rewards",
  headerRightImageAlt: "Poudre Valley Logo",
  loginHeadlineOne: "Get rewarded",
  loginHeadlineTwo: "for flexible EV charging.",
  termsAndConditionsLink: "",
  termsAndConditionText: "I agree to the terms above",
  termsAndConditionsLinkText: "Terms and Conditions",
  termsAndConditionsTitle: "Electric Vehicle Charging Rewards Program Terms and Conditions Agreement",
  accountCreatedHeadlineOne: "You have a DrivEV Smart Charging Rewards account.",
  accountCreatedHeadlineTwo: "Now let's connect your electric vehicle.",
  dashboardManagedChargingText: "DrivEV Managed Charging",
  rewardsCards: [
    {
      title: "Enrollment",
      amount: "$50",
      text: "Earn a $50 enrollment incentive.",
      subtext: "Single payout",
      row: 0,
    },
    {
      title: "Avoid On\u2011Peak",
      amount: "$0.02/kWh",
      text: "DrivEV Rewards of $0.02 per kilowatt-hour (kWh) on-bill credit will be paid for all home EV charging Monday through Saturday between 12 am (midnight) and 3 pm (15-hour window) and anytime on Sunday.",
      row: 1,
    }
  ]
};

const pvreaEnglish: BrandConfig = {
  ...pvreaSettings,
  ...pvreaEnglishText,
};

const pvreaSpanish: BrandConfig = {
  ...pvreaSettings,
  ...defaultSpanishText,
  // spanish translations
  websiteTitle: "Recompensas de carga inteligente DrivEV",
  websiteMetaDescription: "Recompensas de carga inteligente DrivEV de Poudre Valley",
  headerLeftImageAlt: "Recompensas de carga inteligente DrivEV",
  headerRightImageAlt: "Logotipo del valle de Poudre",
  loginHeadlineOne: "Ser recompensado",
  loginHeadlineTwo: "para una carga flexible de vehículos eléctricos.",
  termsAndConditionsLink: "",
  termsAndConditionText: "Acepto los términos anteriores",
  termsAndConditionsLinkText: "Términos y condiciones",
  termsAndConditionsTitle: "Acuerdo de términos y condiciones del programa de recompensas de carga de vehículos eléctricos",
  accountCreatedHeadlineOne: "Tienes una cuenta DrivEV Smart Charging Rewards.",
  accountCreatedHeadlineTwo: "Ahora conectemos su vehículo eléctrico.",
  dashboardManagedChargingText: "Carga Gestionada DrivEV",
  rewardsCards: [
    {
      title: "Inscripción",
      amount: "$50",
      text: "Gane un incentivo de inscripción de $50.",
      subtext: "Pago único",
      row: 0,
    },
    {
      title: "Evite las horas pico",
      amount: "$0.02/kWh",
      text: "Las recompensas de DrivEV de $0.02 por kilovatio-hora (kWh) en créditos en la factura se pagarán por todas las cargas de vehículos eléctricos en el hogar de lunes a sábado, entre las 12 a. m. (medianoche) y las 3 p. m. (ventana de 15 horas), y en cualquier momento los domingos.",
      row: 1,
    }
  ]
};
//#endregion

//#region PVREA-DR
const pvreaDrSettings: BrandConfigSettings = {
  ...pvreaSettings,
  addVehicleOnlyShowManaged: true,
  addVehicleHiddenMakes: ["FORD"],
  programName: "PVREA-DR",
  testMode: true,
  dashboardEnableOverride: false,
};

const pvreaDrEnglishText: BrandConfigText = {
  ...pvreaEnglishText,
  websiteTitle: "DrivEV Demand Response Rewards",
  websiteMetaDescription: "Poudre Valley DrivEV Demand Response Rewards",
  headerLeftImageAlt: "DrivEV Demand Response Rewards",
  termsAndConditionsTitle: "Electric Vehicle Demand Response Rewards Program Terms and Conditions Agreement",
  accountCreatedHeadlineOne: "You have a DrivEV Demand Response Rewards account.",
};

const pvreaDrEnglish: BrandConfig = {
  ...pvreaDrSettings,
  ...pvreaDrEnglishText,
};

const pvreaDrSpanish: BrandConfig = {
  ...pvreaSpanish,
  ...pvreaDrSettings,
  // spanish translations 
  websiteTitle: "Recompensas de respuesta a la demanda DrivEV",
  websiteMetaDescription: "Recompensas de respuesta a la demanda DrivEV de Poudre Valley",
  headerLeftImageAlt: "Recompensas de respuesta a la demanda DrivEV",
  termsAndConditionsTitle: "Acuerdo de términos y condiciones del programa de recompensas de respuesta a la demanda de vehículos eléctricos",
  accountCreatedHeadlineOne: "Tienes una cuenta de recompensas de respuesta a la demanda DrivEV.",
};

//#region  Vermont
const vermontSettings: BrandConfigSettings = {
  ...defaultSettings,
  utilityName: "Vermont Electric Coop",
  programName: "VEC",
  headerImageAutoScale: false,
  termsAndConditionsConsentRequired: true,
  termsAndConditionsShowOnPage: true,
  dashboardEnableOverride: false,
  hasAccountIdInput: true,
};

const vermontEnglishText: BrandConfigText = {
  ...defaultEnglishText,
  websiteTitle: "Vermont Electric Cooperative Flexible Load",
  websiteMetaDescription: "Vermont Electric Cooperative Flexible Load",
  headerLeftImageAlt: "Flexible Load",
  headerRightImageAlt: "Vermont Electric Cooperative Logo",
  termsAndConditionsLink: "",
  termsAndConditionsLinkText: "Vermont Electric Cooperative Terms and Conditions",
  termsAndConditionsTitle: "VEC Electric Vehicle Flexible Load Program Member Participation Agreement",
  accountCreatedHeadlineOne: "You have a Flexible Load account.",
  accountCreatedHeadlineTwo: "Now let's connect your electric vehicle.",
  addLocationHeader: "Home Information",
  locationAccountIdName: "Account Number",
  locationAccountIdLabel: "Add your VEC account number",
  locationAccountIdDescription: "If you don't have or don't know your account number, then leave this blank.",
  rewardsCards: [
    {
      title: "Flex Load Management Bill Credit",
      amount: "$8/month",
      text: "Now that you’re enrolled in the program, VEC will request that your vehicle not charge during peak events, five to six times per month for three to four hours each. You can opt out of individual events if needed, and you will receive an $8/month credit if you do not opt out of events.",
      subtext: "",
      row: 0,
    }
  ]
};

const vermontEnglish: BrandConfig = {
  ...vermontSettings,
  ...vermontEnglishText,
};

const vermontSpanish: BrandConfig = {
  ...vermontSettings,
  ...defaultSpanishText,
  // spanish translations
  termsAndConditionsLink: "",
  termsAndConditionsTitle: "Acuerdo de participación de miembros del programa de carga flexible de vehículos eléctricos VEC",
  termsAndConditionsLinkText: "Vermont Electric Cooperative Términos y Condiciones",
  locationAccountIdName: "Número de cuenta",
  locationAccountIdLabel: "Agregue su número de cuenta de VEC",
  locationAccountIdDescription: "Si no tiene o no conoce su número de cuenta, deje esto en blanco.",
  rewardsCards: [
    {
      title: "Crédito de factura de gestión de carga flexible",
      amount: "$8/mes",
      text: "Ahora que está inscrito en el programa, VEC solicitará que su vehículo no se cargue durante los eventos pico, de cinco a seis veces al mes durante tres a cuatro horas cada una. Puede optar por no participar en eventos individuales si es necesario y recibirá un crédito de $8 al mes si no se excluye de los eventos.",
      subtext: "",
      row: 0,
    }
  ]
};
//#endregion

//#region MinnesotaValley

const minnesotaValleySettings: BrandConfigSettings = {
  ...defaultSettings,
  utilityName: "Minnesota Valley Electric Coop",
  programName: "MVEC",
  headerImageAutoScale: false,
  dashboardEnableOverride: false,
};

const minnesotaValleyEnglishText: BrandConfigText = {
  ...defaultEnglishText,
  websiteTitle: "Minnesota Valley Electric Cooperative - MVEC EV Data Research Pilot",
  websiteMetaDescription: "Minnesota Valley Electric Cooperative - MVEC EV Data Research Pilot",
  headerLeftImageAlt: "MVEC EV Data Research Pilot",
  headerRightImageAlt: "Minnesota Valley Electric Cooperative",
  termsAndConditionsLink: "",
  termsAndConditionsLinkText: "",
  accountCreatedHeadlineOne: "You have an MVEC EV Data Research Pilot account.",
  accountCreatedHeadlineTwo: "Now let's connect your electric vehicle.",
  rewardsCards: [],
};

const minnesotaValleyEnglish: BrandConfig = {
  ...minnesotaValleySettings,
  ...minnesotaValleyEnglishText,
};

const minnesotaValleySpanishText: BrandConfigText = {
  ...defaultSpanishText,
};

const minnesotaValleySpanish: BrandConfig = {
  ...minnesotaValleySettings,
  ...minnesotaValleySpanishText,
  // spanish translations
  websiteTitle: "Minnesota Valley Electric Cooperative - Piloto de Investigación de Datos de Vehículos Eléctricos (EV) de MVEC",
  websiteMetaDescription: "Minnesota Valley Electric Cooperative - Piloto de Investigación de Datos de Vehículos Eléctricos (EV) de MVEC",
  headerLeftImageAlt: "Piloto de Investigación de Datos de Vehículos Eléctricos (EV) de MVEC",
  headerRightImageAlt: "Minnesota Valley Electric Cooperative",
  termsAndConditionsLink: "",
  termsAndConditionsLinkText: "",
  accountCreatedHeadlineOne: "Tienes una cuenta del Piloto de Investigación de Datos de Vehículos Eléctricos (EV) de MVEC.",
  accountCreatedHeadlineTwo: "Ahora vamos a conectar tu vehículo eléctrico.",
  rewardsCards: [],
};

//#endregion

//#region Eversource
const eversourceSettings: BrandConfigSettings = {
  ...defaultSettings,
  utilityName: "Eversource Massachusetts",
  programName: "EVERSOURCE",
  headerImageAutoScale: false,
  dashboardEnableOverride: true,
  addVehicleHiddenMakes: ["VOLVO", "RENAULT", "NISSAN", "LINCOLN", "CHRYSLER", "JEEP", "FORD", "MERCEDESBENZ", "RIVIAN", "CHEVROLET", "PORSCHE", "AUDI", "CADILLAC"]
};

const eversourceEnglishText: BrandConfigText = {
  ...defaultEnglishText,
  websiteTitle: "Eversource Massachusetts EV Charging Pilot",
  websiteMetaDescription: "Eversource Massachusetts EV Charging Pilot",
  loginHeadlineOne: "Thank you for your interest in our pilot",
  loginHeadlineTwo: "",
  headerLeftImageAlt: "Eversource Massachusetts EV Charging Pilot",
  headerRightImageAlt: "Eversource",
  termsAndConditionsLink: "",
  termsAndConditionsLinkText: "",
  accountCreatedHeadlineOne: "You have an EV Charging Pilot account.",
  accountCreatedHeadlineTwo: "Now let's connect your electric vehicle.",
  signUpStepTwo: "Add most frequent charging location",
  addLocationText: "The pilot requires reliable cell service/signal at the charging location.",
  addLocationHeader: "Charging Address",
  addVehicleHeadlineTwo: "Your electric vehicle is connected. One more step to go!",
  locationAddedHeadlineTwo: "You will receive a welcome email confirming your acceptance into the program with additional details on the pilot.",
  welcomeButtonText: "View Enrolled Vehicle(s)",
  rewardsCards: [],
};


const eversourceEnglish: BrandConfig = {
  ...eversourceSettings,
  ...eversourceEnglishText,
};

const eversourceSpanish: BrandConfig = {
  ...eversourceSettings,
  ...defaultSpanishText,
  // spanish translations
  websiteTitle: "Piloto de carga de vehículos eléctricos de Eversource Massachusetts",
  websiteMetaDescription: "Piloto de carga de vehículos eléctricos de Eversource Massachusetts",
  loginHeadlineOne: "Gracias por su interés en nuestro piloto.",
  loginHeadlineTwo: "",
  headerLeftImageAlt: "Piloto de carga de vehículos eléctricos de Eversource Massachusetts",
  headerRightImageAlt: "Eversource",
  termsAndConditionsLink: "",
  termsAndConditionsLinkText: "",
  accountCreatedHeadlineOne: "Tienes una cuenta de piloto de carga de vehículos eléctricos.",
  accountCreatedHeadlineTwo: "Ahora conectemos su vehículo eléctrico.",
  signUpStepTwo: "Agregar la ubicación de carga más frecuente",
  addLocationText: "El piloto requiere señal/servicio celular confiable en el lugar de carga.",
  addLocationHeader: "Dirección de carga",
  addVehicleHeadlineTwo: "Tu vehículo eléctrico está conectado. ¡Un paso más por recorrer!",
  locationAddedHeadlineTwo: "Recibirá un correo electrónico de bienvenida confirmando su aceptación en el programa con detalles adicionales sobre el piloto.",
  welcomeButtonText: "Ver vehículos inscritos",
  rewardsCards: [],
};
//#endregion

//#region BPE
const bpeSettings: BrandConfigSettings = {
  ...defaultSettings,
  testMode: true,
  utilityName: "Bright Path Energy",
  programName: "BPE",
  headerImageAutoScale: false,
  dashboardEnableOverride: true,
  dashboardDemandResponse: true,
  flagTeslaFleet: true,
  addVehicleHiddenMakes: ["FORD"]
};

const bpeEnglishText: BrandConfigText = {
  ...defaultEnglishText,
  websiteTitle: "Bright Path Energy EV Charging Rewards",
  websiteMetaDescription: "Bright Path Energy EV Charging Rewards",
  headerLeftImageAlt: "EV Charging Rewards",
  headerRightImageAlt: "Bright Path Energy",
  termsAndConditionsLink: "",
  termsAndConditionsLinkText: "",
  accountCreatedHeadlineOne: "You have an EV Charging Rewards account.",
  accountCreatedHeadlineTwo: "Now let's connect your electric vehicle.",
  rewardsCards: [
    {
      title: "Enrollment",
      amount: "$50",
      text: "Earn a $50 enrollment incentive.",
      subtext: "Single payout",
      row: 0,
    },
    {
      title: "Avoid On\u2011Peak",
      amount: "$0.02/kWh",
      text: "Rewards of $0.02 per kilowatt-hour (kWh) on-bill credit will be paid for all home EV charging Monday through Saturday between 12 am (midnight) and 3 pm (15-hour window) and anytime on Sunday.",
      row: 1,
    }
  ]
};

const bpeEnglish: BrandConfig = {
  ...bpeSettings,
  ...bpeEnglishText,
};

const bpeSpanish: BrandConfig = {
  ...bpeSettings,
  ...defaultSpanishText,
  // spanish translations
  rewardsCards: [
    {
      title: "Inscripción",
      amount: "$50",
      text: "Gane un incentivo de inscripción de $50.",
      subtext: "Pago único",
      row: 0,
    },
    {
      title: "Evite las horas pico",
      amount: "$0.02/kWh",
      text: "Recompensas de $0.02 por kilovatio-hora (kWh) en créditos en la factura serán pagadas por todas las cargas de vehículos eléctricos en el hogar de lunes a sábado entre las 12 a. m. (medianoche) y las 3 p. m. (ventana de 15 horas), y en cualquier momento los domingos.",
      row: 1,
    }
  ]
};

const bpedemoEnglish: BrandConfig = {
  ...bpeEnglish,
  programName: "BPE-DEMO"
};

const bpedemoSpanish: BrandConfig = {
  ...bpeSpanish,
  programName: "BPE-DEMO"
};
//#endregion

//#region EPB
const epbSettings: BrandConfigSettings = {
  ...defaultSettings,
  utilityName: "EPB Chattanooga",
  programName: "EPB",
  headerImageAutoScale: false,
  dashboardEnableOverride: true,
};

const epbEnglishText: BrandConfigText = {
  ...defaultEnglishText,
  websiteTitle: "EPB Flex Charge",
  websiteMetaDescription: "EPB Flex Charge",
  headerLeftImageAlt: "Energy Hero EV Home Charge",
  headerRightImageAlt: "EPB",
  termsAndConditionsLink: "",
  termsAndConditionsLinkText: "",
  accountCreatedHeadlineOne: "You have an EPB Flex Charge account.",
  accountCreatedHeadlineTwo: "Now let's connect your electric vehicle.",
  rewardsHeadline: "Program Information",
  rewardsCards: [
    {
      title: "",
      amount: "",
      text: "Thank you for participating in EPB's Flex Charge pilot. EPB may periodically schedule Demand Response test events to better understand managed charging capabilities.",
      subtext: "",
      row: 0,
    },
  ],
};

const epbEnglish: BrandConfig = {
  ...epbSettings,
  ...epbEnglishText,
};

const epbSpanish: BrandConfig = {
  ...epbSettings,
  ...defaultSpanishText,
  // Spanish translations
  websiteTitle: "Carga flexible EPB",
  websiteMetaDescription: "Carga flexible EPB",
  headerLeftImageAlt: "Carga doméstica Energy Hero EV",
  headerRightImageAlt: "EPB",
  termsAndConditionsLink: "",
  termsAndConditionsLinkText: "",
  accountCreatedHeadlineOne: "Tienes una cuenta EPB Flex Charge.",
  accountCreatedHeadlineTwo: "Ahora conectamos tu vehículo eléctrico.",
  rewardsHeadline: "Información del Programa",
  rewardsCards: [
    {
      title: "",
      amount: "",
      text: "Gracias por participar en el programa piloto Flex Charge de EPB. EPB puede programar periódicamente eventos de prueba de Respuesta a la Demanda para comprender mejor las capacidades de carga administradas.",
      subtext: "",
      row: 0,
    },
  ],
};
//#endregion

//#region Core
const coreSettings: BrandConfigSettings = {
  ...defaultSettings,
  utilityName: "CORE Electric Cooperative",
  programName: "CORE",
  rateSchedule: "Residential Time-of-use Demand A",
  addVehicleHiddenMakes: ["FORD", "LINCOLN", "RENAULT"],
  dashboardEnableOverride: false,
  dashboardDemandResponse: true,
  testMode: true,
  termsAndConditionsShowOnPage: true,
  termsAndConditionsConsentRequired: true,
};

const coreEnglishText: BrandConfigText = {
  ...defaultEnglishText,
  websiteTitle: "COREV Residential Electric Vehicles",
  websiteMetaDescription: "COREV Residential Electric Vehicles",
  headerLeftImageAlt: "COREV Residential Electric Vehicles",
  headerRightImageAlt: "CORE Electric Cooperative",
  loginHeadlineOne: "The Energy to Thrive™",
  loginHeadlineTwo: "",
  termsAndConditionsLink: "",
  termsAndConditionsLinkText: "",
  accountCreatedHeadlineOne: "You have a COREV account.",
  accountCreatedHeadlineTwo: "Now let's connect your electric vehicle.",
  rewardsHeadline: "Program Information",
  welcomeToText: "to COREV Connect!",
  rewardsCards: [
    {
      title: "",
      amount: "",
      text: "CORE appreciates you participating in this program.  The EV charging data that CORE collects will help us learn more about EV charging behaviors, how EVs affect CORE’s distribution network, and help CORE develop new EV programs.  In return, you will receive a bill credit of $15 after a full 12 months of being connected to the program.  Thank You!",
      subtext: "",
      row: 0,
    },
  ],
};

const coreEnglish: BrandConfig = {
  ...coreSettings,
  ...coreEnglishText,
};

const coreSpanish: BrandConfig = {
  ...coreSettings,
  ...defaultSpanishText,
  // spanish translations
  websiteTitle: "Colaboración COREV",
  websiteMetaDescription: "Colaboración COREV",
  headerLeftImageAlt: "Colaboración COREV",
  headerRightImageAlt: "Cooperativa Eléctrica CORE",
  loginHeadlineOne: "La energía para prosperar",
  loginHeadlineTwo: "",
  termsAndConditionsLink: "",
  termsAndConditionsLinkText: "",
  accountCreatedHeadlineOne: "Tienes una cuenta COREV Collaborate.",
  accountCreatedHeadlineTwo: "Ahora conectemos tu vehículo eléctrico.",
  rewardsHeadline: "Información del Programa",
  welcomeToText: "a COREV Connect!",
  rewardsCards: [
    {
      title: "",
      amount: "",
      text: "CORE aprecia su participación en este programa. Los datos de carga de vehículos eléctricos que CORE recopila nos ayudarán a aprender más sobre los comportamientos de carga de vehículos eléctricos, cómo los vehículos eléctricos afectan la red de distribución de CORE y ayudarán a CORE a desarrollar nuevos programas de vehículos eléctricos. A cambio, recibirá un crédito en la factura de $15 después de 12 meses completos de estar conectado al programa. ¡Gracias!",
      subtext: "",
      row: 0,
    },
  ],
};
//#endregion

//#region Coopusa
const coopusaSettings: BrandConfigSettings = {
  ...defaultSettings,
  utilityName: "coopusa",
  programName: "COOPUSA",
  headerImageAutoScale: false,
  termsAndConditionsConsentRequired: false,
  dashboardEnableOverride: true
};

const coopusaEnglishText: BrandConfigText = {
  ...defaultEnglishText,
  websiteTitle: "Cooperative USA - EV Charging Rewards",
  websiteMetaDescription: "Cooperative USA EV Charging Rewards, FlexCharging EVision Demo",
  headerLeftImageAlt: "Cooperative USA EV Charging Rewards",
  headerRightImageAlt: "Cooperative USA Logo",
  termsAndConditionsLink: "https://pvrea.coop/wp-content/uploads/2021/04/Terms-and-Conditions_woSignature-03162023.pdf",
  termsAndConditionsLinkText: "Coop USA Terms and Conditions",
  accountCreatedHeadlineOne: "You have a EV Charging Rewards Flexible account.",
  accountCreatedHeadlineTwo: "Now let's connect your electric vehicle.",
  rewardsCards: [
    {
      title: "Enrollment",
      amount: "$50",
      text: "Earn a $50 enrollment incentive.",
      subtext: "Single payout",
      row: 0,
    },
    {
      title: "Avoid On\u2011Peak",
      amount: "$0.02/kWh",
      text: "Rewards of $0.02 per kilowatt-hour (kWh) on-bill credit will be paid for all home EV charging Monday through Saturday between 12 am (midnight) and 3 pm (15-hour window) and anytime on Sunday.",
      row: 1,
    }
  ]
};

const coopusaEnglish: BrandConfig = {
  ...coopusaSettings,
  ...coopusaEnglishText,
};

const coopusaSpanish: BrandConfig = {
  ...coopusaSettings,
  ...defaultSpanishText,
  // spanish translations
  websiteTitle: "Cooperativa USA - Recompensas por Carga de Vehículos Eléctricos",
  websiteMetaDescription: "Cooperativa USA - Recompensas por Carga de Vehículos Eléctricos, Demostración de FlexCarga EVision.",
  headerLeftImageAlt: "Recompensas por Carga de Vehículos Eléctricos de Cooperative USA",
  headerRightImageAlt: "Logotipo de Cooperative USA",
  termsAndConditionsLink: "https://pvrea.coop/wp-content/uploads/2021/04/Terms-and-Conditions_woSignature-03162023.pdf",
  termsAndConditionsLinkText: "Términos y Condiciones de Coop USA",
  accountCreatedHeadlineOne: "Tienes una cuenta de Recompensas por Carga de Vehículos Eléctricos Flexible.",
  accountCreatedHeadlineTwo: "Ahora conectemos tu vehículo eléctrico.",
  rewardsCards: [
    {
      title: "Inscripción",
      amount: "$50",
      text: "Gane un incentivo de inscripción de $50.",
      subtext: "Pago único",
      row: 0,
    },
    {
      title: "Evitar las horas pico",
      amount: "$0.02/kWh",
      text: "Se pagarán recompensas de $0,02 por kilovatio-hora (kWh) de crédito en la factura por todas las cargas de vehículos eléctricos en el hogar de lunes a sábado entre las 12 a. m. (medianoche) y las 3 p. m. (período de 15 horas) y en cualquier momento los domingos.",
      row: 1,
    }
  ]
};
//#endregion

//#region Sdge
const sdgeSettings: BrandConfigSettings = {
  ...defaultSettings,
  utilityName: "San Diego Gas & Electric",
  programName: "SDGE",
  rateSchedule: "Residential EV-TOU-5",
  dashboardEnableOverride: true,
  addVehicleHiddenMakes: ["FORD", "LINCOLN"],
};

const sdgeEnglishText: BrandConfigText = {
  ...defaultEnglishText,
  websiteTitle: "Electric Vehicle Managed Charging Demonstration",
  websiteMetaDescription: "Electric Vehicle Managed Charging Demonstration",
  headerLeftImageAlt: "Electric Vehicle Managed Charging Demonstration",
  headerRightImageAlt: "San Diego Gas & Electric",
  termsAndConditionsLink: "",
  termsAndConditionsLinkText: "",
  accountCreatedHeadlineOne: "You have an Electric Vehicle Managed Charging Demonstration account.",
  accountCreatedHeadlineTwo: "Now let's connect your electric vehicle.",
  rewardsHeadline: "Program Information",
  rewardsCards: [
    {
      title: "",
      amount: "",
      text: "The Demonstration will monitor baseline EV charging patterns and include periodic Demand Response events or managed charging",
      subtext: "",
      row: 0,
    },
  ],
};

const sdgeEnglish: BrandConfig = {
  ...sdgeSettings,
  ...sdgeEnglishText,
};

const sdgeSpanish: BrandConfig = {
  ...sdgeSettings,
  ...defaultSpanishText,
  // spanish translations
  websiteTitle: "Demonstración de Carga Gestionada para Vehículos Eléctricos",
  websiteMetaDescription: "Demonstración de Carga Gestionada para Vehículos Eléctricos",
  headerLeftImageAlt: "Demonstración de Carga Gestionada para Vehículos Eléctricos",
  headerRightImageAlt: "San Diego Gas & Electric",
  termsAndConditionsLink: "",
  termsAndConditionsLinkText: "",
  accountCreatedHeadlineOne: "Tienes una cuenta de Demostración de Carga Gestionada para Vehículos Eléctricos.",
  accountCreatedHeadlineTwo: "Ahora conectemos tu vehículo eléctrico.",
  rewardsHeadline: "Información del Programa",
};
//#endregion

const whSettings: BrandConfigSettings = {
  ...defaultSettings,
  utilityName: "Wright-Hennepin Cooperative Electric Association",
  programName: "WH",
  rateSchedule: "General Service",
  termsAndConditionsShowOnPage: true,
  termsAndConditionsConsentRequired: true,
  termsAndConditionsTranslated: true,
  hasPhoneNumberInput: true,
  addVehicleHiddenMakes: ["FORD"]

};

const whEnglishText: BrandConfigText = {
  ...defaultEnglishText,
  websiteTitle: "Wright-Hennepin – Quick Cash Charging Pilot",
  websiteMetaDescription: "Wright-Hennepin Cooperative Electric Association Quick Cash Charging Pilot",
  headerLeftImageAlt: "Quick Cash Charging Pilot",
  headerRightImageAlt: "Wright-Hennepin Cooperative Electric Association",
  loginHeadlineOne: "Save energy. Save money",
  loginHeadlineTwo: "and help WH explore this new tech!",
  termsAndConditionsLink: "",
  termsAndConditionsLinkText: "",
};

const whEnglish: BrandConfig = {
  ...whSettings,
  ...whEnglishText,
};

const whSpanish: BrandConfig = {
  ...whSettings,
  ...defaultSpanishText,
  // spanish 
  websiteTitle: "Wright-Hennepin – Piloto de Carga Rápida",
  websiteMetaDescription: "Wright-Hennepin Cooperative Electric Association Piloto de Carga Rápida",
  headerLeftImageAlt: "Piloto de Carga Rápida",
  headerRightImageAlt: "Wright-Hennepin Cooperative Electric Association",
  loginHeadlineOne: "Ahorre energía. Ahorre dinero",
  loginHeadlineTwo: "y ayude a WH a explorar esta nueva tecnología!",
};

const betaSettings: BrandConfigSettings = {
  ...defaultSettings,
  defaultCountry: "Ireland",
  hasPhoneNumberInput: true,
  utilityName: "FlexCharging Beta Testing Program",
  programName: "BETA",
  dashboardEnableOverride: true,
  testMode: true
};

const betaEnglishText: BrandConfigText = {
  ...defaultEnglishText,
  websiteTitle: "FlexCharging Beta Testing Program",
  websiteMetaDescription: "FlexCharging Beta Testing Program",
  loginHeadlineOne: "Enroll your vehicle to become a beta tester with FlexCharging",
  loginHeadlineTwo: "",
  headerLeftImageAlt: "FlexCharging Beta Testing Program",
  headerRightImageAlt: "FlexCharging Beta Testing Program",
  termsAndConditionsLink: "",
  termsAndConditionsLinkText: "",
  accountCreatedHeadlineOne: "You have a Beta testing account.",
  accountCreatedHeadlineTwo: "Now let's connect your electric vehicle.",
  signUpStepTwo: "Add most frequent charging location",
  addLocationText: "The pilot requires reliable cell service/signal at the charging location.",
  addLocationHeader: "Charging Address",
  addVehicleHeadlineTwo: "Your electric vehicle is connected. One more step to go!",
  locationAddedHeadlineTwo: "You will receive a welcome email confirming your acceptance into the beta testing program with additional details.",
  welcomeButtonText: "View Enrolled Vehicle(s)",
  rewardsCards: [],
};


const betaEnglish: BrandConfig = {
  ...betaSettings,
  ...betaEnglishText,
};

const betaSpanish: BrandConfig = {
  ...betaSettings,
  ...defaultSpanishText,
  // spanish translations
};

//#region Ireland
const irelandSettings: BrandConfigSettings = {
  ...defaultSettings,
  utilityName: "ESB Networks", // would like this blank but API currently requires it.
  rateSchedule: "Home Electric+ Night Boost EV",
  distributionUtilityName: "ESB Networks",
  programName: "IRELAND",
  hasLandingPage: true,
  hasSignUpReferralInput: true,
  termsAndConditionsShowOnPage: true,
  termsAndConditionsConsentRequired: true,
  // BMW, Cupra, Ford [probably launch without this], Hyundai, Jaguar, Kia, Land Rover, Mini, Tesla, Volkswagen 
  addVehicleHiddenMakes: ["CADILLAC", "CHEVROLET", "CHRYSLER", "FORD", "JEEP", "LEXUS", "LINCOLN", "RIVIAN", "TOYOTA"],
  dashboardEnableOverride: true,
  hasPhoneNumberInput: true,
  hasDeleteMyData: true,
  phoneNumberRequired: true,
  hasAccountIdInput: true,
  accountIdRequired: true,
  defaultCountry: "Ireland",
  defaultCountryCode: "IE",
  hasProgramInfo: true,
};

const irelandEnglishText: BrandConfigText = {
  ...defaultEnglishText,
  websiteTitle: "FlexCharging Ireland",
  websiteMetaDescription: "FlexCharging Ireland",
  termsAndConditionsTitle: "Terms and Conditions",
  locationAccountIdName: "MPRN",
  addLocationText: "All fields are required in order to continue with sign up",
  locationAccountIdDescription: "Your MPRN is a unique 11-digit number that identifies the electricity meter at your home. It can be found on your electricity bill.  It always starts with the digits '10'.",
  headerLeftImageAlt: "FlexCharging Ireland",
  accountCreatedHeadlineOne: "You have a FlexCharging Ireland account.",
  rewardsHeadline: "Program",
  rewardsCards: [
    {
      title: "Enrollment Reward",
      amount: "€150",
      text: "Earn €150 at end of pilot as long as your car participates in at least 50% of events.",
      subtext: "Single payout",
      row: 0,
    },
  ],
  demandResponseName: "Charging Avoidance Window"
};

const irelandSpanishText: BrandConfigText = {
  ...defaultSpanishText,
  termsAndConditionsTitle: "Términos y Condiciones",
  locationAccountIdDescription: "Su MPRN es un número único de 11 dígitos que identifica el medidor de electricidad en su hogar. Se puede encontrar en su factura de electricidad. Siempre comienza con los dígitos '10'.",
  accountCreatedHeadlineOne: "Tienes una cuenta de FlexCharging Ireland.",
  rewardsHeadline: "Programa",
  addLocationText: "Todos los campos son obligatorios para continuar con la inscripción",
  rewardsCards: [
    {
      title: "Inscripción",
      amount: "€150",
      text: "Gane €150 al final del piloto siempre que su automóvil participe en al menos el 50% de los eventos.",
      subtext: "Pago único",
      row: 0,
    },
  ],
  demandResponseName: "Ventana de Evitación de Carga"
};

const irelandEnglish: BrandConfig = {
  ...irelandSettings,
  ...irelandEnglishText,
};

const irelandSpanish: BrandConfig = {
  ...irelandSettings,
  ...irelandSpanishText,
};
//#endregion

//#region Connexus
const connexusSettings: BrandConfigSettings = {
  ...defaultSettings,
  utilityName: "Connexus Energy",
  programName: "CONNEXUS",
  headerImageAutoScale: false,
  dashboardEnableOverride: true,
  addVehicleOnlyShowManaged: true,
  testMode: true,
};

const connexusEnglishText: BrandConfigText = {
  ...defaultEnglishText,
  websiteTitle: "Connexus Energy Smart Charging Rewards",
  websiteMetaDescription: "Connexus Energy Smart Charging Rewards",
  headerLeftImageAlt: "Smart Charging Rewards",
  headerRightImageAlt: "Connexus Energy",
  termsAndConditionsLink: "",
  termsAndConditionsLinkText: "",
  accountCreatedHeadlineOne: "You have a Smart Charging Rewards account.",
  accountCreatedHeadlineTwo: "Now let's connect your electric vehicle.",
  rewardsCards: []
};

const connexusSpanishText: BrandConfigText = {
  ...defaultSpanishText,
  websiteTitle: "Recompensas de Carga Inteligente de Connexus Energy",
  websiteMetaDescription: "Recompensas de Carga Inteligente de Connexus Energy",
  headerLeftImageAlt: "Recompensas de Carga Inteligente",
  headerRightImageAlt: "Connexus Energy",
  termsAndConditionsLink: "",
  termsAndConditionsLinkText: "",
  accountCreatedHeadlineOne: "Tienes una cuenta de Recompensas de Carga Inteligente.",
  accountCreatedHeadlineTwo: "Ahora conectemos tu vehículo eléctrico."
};

const connexusEnglish: BrandConfig = {
  ...connexusSettings,
  ...connexusEnglishText,
};

const connexusSpanish: BrandConfig = {
  ...connexusSettings,
  ...connexusSpanishText,
};
//#endregion

//#region WRECC
const wreccSettings: BrandConfigSettings = {
  ...defaultSettings,
  utilityName: "Warren Rural Electric Cooperative Corporation",
  programName: "WRECC",
  headerImageAutoScale: false,
  dashboardEnableOverride: true,
  addVehicleHiddenMakes: ["FORD", "LINCOLN"],
  testMode: true,
};

const wreccEnglishText: BrandConfigText = {
  ...defaultEnglishText,
  websiteTitle: "Warren RECC Smart Charging Rewards",
  websiteMetaDescription: "Warren Rural Electric Cooperative Corporation WRECC Smart Charging Rewards",
  headerLeftImageAlt: "Smart Charging Rewards",
  headerRightImageAlt: "Warren RECC",
  termsAndConditionsLink: "",
  termsAndConditionsLinkText: "",
  accountCreatedHeadlineOne: "You have an Smart Charging Rewards account.",
  rewardsCards: []
};

const wreccSpanishText: BrandConfigText = {
  ...defaultSpanishText,
  websiteTitle: "Recompensas de Carga Inteligente de Warren RECC",
  websiteMetaDescription: "Warren Rural Electric Cooperative Corporation WRECC Recompensas de Carga Inteligente",
  headerLeftImageAlt: "Recompensas de Carga Inteligente",
  headerRightImageAlt: "Warren RECC",
  termsAndConditionsLink: "",
  termsAndConditionsLinkText: "",
  accountCreatedHeadlineOne: "Tienes una cuenta de Recompensas de Carga Inteligente.",
};

const wreccEnglish: BrandConfig = {
  ...wreccSettings,
  ...wreccEnglishText,
};

const wreccSpanish: BrandConfig = {
  ...wreccSettings,
  ...wreccSpanishText,
};
//#endregion

//#region Coserv
const coservSettings: BrandConfigSettings = {
  ...defaultSettings,
  utilityName: "CoServ",
  programName: "COSERV",
  headerImageAutoScale: false,
  dashboardEnableOverride: true,
  addVehicleHiddenMakes: ["FORD", "LINCOLN"],
  testMode: true,
};

const coservEnglishText: BrandConfigText = {
  ...defaultEnglishText,
  websiteTitle: "CoServ Smart Charging Rewards",
  websiteMetaDescription: "CoServ Smart Charging Rewards",
  headerLeftImageAlt: "Smart Charging Rewards",
  headerRightImageAlt: "CoServ",
  termsAndConditionsLink: "",
  termsAndConditionsLinkText: "",
  accountCreatedHeadlineOne: "You have an Smart Charging Rewards account.",
  rewardsCards: []
};

const coservSpanishText: BrandConfigText = {
  ...defaultSpanishText,
  websiteTitle: "Recompensas de Carga Inteligente de CoServ",
  websiteMetaDescription: "CoServ Recompensas de Carga Inteligente",
  headerLeftImageAlt: "Recompensas de Carga Inteligente",
  headerRightImageAlt: "CoServ",
  termsAndConditionsLink: "",
  termsAndConditionsLinkText: "",
  accountCreatedHeadlineOne: "Tienes una cuenta de Recompensas de Carga Inteligente.",
};

const coservEnglish: BrandConfig = {
  ...coservSettings,
  ...coservEnglishText,
};

const coservSpanish: BrandConfig = {
  ...coservSettings,
  ...coservSpanishText,
};
//#endregion

//#region Sussexrec
const sussexrecSettings: BrandConfigSettings = {
  ...defaultSettings,
  utilityName: "Sussex Rural Electric Cooperative",
  programName: "SUSSEXREC",
  headerImageAutoScale: false,
  dashboardEnableOverride: true,
  addVehicleHiddenMakes: ["FORD", "LINCOLN"],
  testMode: true,
};

const sussexrecEnglishText: BrandConfigText = {
  ...defaultEnglishText,
  websiteTitle: "Sussex REC Smart Charging Rewards",
  websiteMetaDescription: "Sussex Rural Electric Cooperative Inc Smart Charging Rewards",
  headerLeftImageAlt: "Smart Charging Rewards",
  headerRightImageAlt: "Sussex Rural Electric Cooperative Inc",
  termsAndConditionsLink: "",
  termsAndConditionsLinkText: "",
  accountCreatedHeadlineOne: "You have an Smart Charging Rewards account.",
  rewardsCards: []
};

const sussexrecSpanishText: BrandConfigText = {
  ...defaultSpanishText,
  websiteTitle: "Recompensas de Carga Inteligente de Sussex REC",
  websiteMetaDescription: "Sussex Rural Electric Cooperative Sussex REC Recompensas de Carga Inteligente",
  headerLeftImageAlt: "Recompensas de Carga Inteligente",
  headerRightImageAlt: "Sussex REC",
  termsAndConditionsLink: "",
  termsAndConditionsLinkText: "",
  accountCreatedHeadlineOne: "Tienes una cuenta de Recompensas de Carga Inteligente.",
};

const sussexrecEnglish: BrandConfig = {
  ...sussexrecSettings,
  ...sussexrecEnglishText,
};

const sussexrecSpanish: BrandConfig = {
  ...sussexrecSettings,
  ...sussexrecSpanishText,
};

//#endregion

//#region UCS
const ucsSettings: BrandConfigSettings = {
  ...defaultSettings,
  utilityName: "United Cooperative Services",
  programName: "UCS",
  headerImageAutoScale: false,
  dashboardEnableOverride: true,
  addVehicleHiddenMakes: ["FORD", "LINCOLN"],
  testMode: true,
};

const ucsEnglishText: BrandConfigText = {
  ...defaultEnglishText,
  websiteTitle: "United Cooperative Services Smart Charging Rewards",
  websiteMetaDescription: "United Cooperative Services Smart Charging Rewards",
  headerLeftImageAlt: "Smart Charging Rewards",
  headerRightImageAlt: "United Cooperative Services",
  termsAndConditionsLink: "",
  termsAndConditionsLinkText: "",
  accountCreatedHeadlineOne: "You have an Smart Charging Rewards account.",
  rewardsCards: []
};

const ucsSpanishText: BrandConfigText = {
  ...defaultSpanishText,
  websiteTitle: "Recompensas de Carga Inteligente de United Cooperative Services",
  websiteMetaDescription: "United Cooperative Services Recompensas de Carga Inteligente",
  headerLeftImageAlt: "Recompensas de Carga Inteligente",
  headerRightImageAlt: "United Cooperative Services",
  termsAndConditionsLink: "",
  termsAndConditionsLinkText: "",
  accountCreatedHeadlineOne: "Tienes una cuenta de Recompensas de Carga Inteligente.",
};

const ucsEnglish: BrandConfig = {
  ...ucsSettings,
  ...ucsEnglishText,
};

const ucsSpanish: BrandConfig = {
  ...ucsSettings,
  ...ucsSpanishText,
};

//#endregion

//#region Trico
const tricoSettings: BrandConfigSettings = {
  ...defaultSettings,
  utilityName: "Trico Electric Cooperative",
  programName: "TRICO",
  headerImageAutoScale: false,
  dashboardEnableOverride: true,
  testMode: true,
  addVehicleHiddenMakes: ["FORD", "LINCOLN"],
};

const tricoEnglishText: BrandConfigText = {
  ...defaultEnglishText,
  websiteTitle: "Trico Electric Cooperative Smart Charging Rewards",
  websiteMetaDescription: "Trico Electric Cooperative Smart Charging Rewards",
  headerLeftImageAlt: "Smart Charging Rewards",
  headerRightImageAlt: "Trico Electric Cooperative",
  termsAndConditionsLink: "",
  termsAndConditionsLinkText: "",
  accountCreatedHeadlineOne: "You have an Smart Charging Rewards account.",
  rewardsCards: []
};

const tricoSpanishText: BrandConfigText = {
  ...defaultSpanishText,
  websiteTitle: "Recompensas de Carga Inteligente de Trico Electric Cooperative",
  websiteMetaDescription: "Trico Electric Cooperative Recompensas de Carga Inteligente",
  headerLeftImageAlt: "Recompensas de Carga Inteligente",
  headerRightImageAlt: "",
  termsAndConditionsLink: "",
  termsAndConditionsLinkText: "",
  accountCreatedHeadlineOne: "",
};

const tricoEnglish: BrandConfig = {
  ...tricoSettings,
  ...tricoEnglishText,
};

const tricoSpanish: BrandConfig = {
  ...tricoSettings,
  ...tricoSpanishText,
};

//#endregion

//#region GVEC
const gvecSettings: BrandConfigSettings = {
  ...defaultSettings,
  utilityName: "Guadalupe Valley Electric Cooperative",
  programName: "GVEC",
  headerImageAutoScale: false,
  dashboardEnableOverride: true,
  testMode: true,
  addVehicleHiddenMakes: ["FORD", "LINCOLN"],
};

const gvecEnglishText: BrandConfigText = {
  ...defaultEnglishText,
  websiteTitle: "Guadalupe Valley Electric Cooperative Smart Charging Rewards",
  websiteMetaDescription: "Guadalupe Valley Electric Cooperative Smart Charging Rewards",
  headerLeftImageAlt: "Smart Charging Rewards",
  headerRightImageAlt: "Guadalupe Valley Electric Cooperative",
  termsAndConditionsLink: "",
  termsAndConditionsLinkText: "",
  accountCreatedHeadlineOne: "You have an Smart Charging Rewards account.",
  rewardsCards: []
};

const gvecSpanishText: BrandConfigText = {
  ...defaultSpanishText,
  websiteTitle: "Recompensas de Carga Inteligente de Guadalupe Valley Electric Cooperative",
  websiteMetaDescription: "Guadalupe Valley Electric Cooperative Recompensas de Carga Inteligente",
  headerLeftImageAlt: "Recompensas de Carga Inteligente",
  headerRightImageAlt: "",
  termsAndConditionsLink: "",
  termsAndConditionsLinkText: "",
  accountCreatedHeadlineOne: "",
};

const gvecEnglish: BrandConfig = {
  ...gvecSettings,
  ...gvecEnglishText,
};

const gvecSpanish: BrandConfig = {
  ...gvecSettings,
  ...gvecSpanishText,
};

//#endregion

//#region Dakota
const dakotaSettings: BrandConfigSettings = {
  ...defaultSettings,
  utilityName: "Dakota Electric Association",
  programName: "DAKOTA",
  headerImageAutoScale: false,
  dashboardEnableOverride: true,
  testMode: true,
  addVehicleHiddenMakes: ["FORD", "LINCOLN"],
};

const dakotaEnglishText: BrandConfigText = {
  ...defaultEnglishText,
  websiteTitle: "Dakota Electric Association Smart Charging Rewards",
  websiteMetaDescription: "Dakota Electric Association Smart Charging Rewards",
  headerLeftImageAlt: "Smart Charging Rewards",
  headerRightImageAlt: "Dakota Electric Association",
  termsAndConditionsLink: "",
  termsAndConditionsLinkText: "",
  accountCreatedHeadlineOne: "You have an Smart Charging Rewards account.",
  rewardsCards: []
};

const dakotaSpanishText: BrandConfigText = {
  ...defaultSpanishText,
  websiteTitle: "Recompensas de Carga Inteligente de Dakota Electric Association",
  websiteMetaDescription: "Dakota Electric Association Recompensas de Carga Inteligente",
  headerLeftImageAlt: "Recompensas de Carga Inteligente",
  headerRightImageAlt: "",
  termsAndConditionsLink: "",
  termsAndConditionsLinkText: "",
  accountCreatedHeadlineOne: "",
};

const dakotaEnglish: BrandConfig = {
  ...dakotaSettings,
  ...dakotaEnglishText,
};

const dakotaSpanish: BrandConfig = {
  ...dakotaSettings,
  ...dakotaSpanishText,
};

//#endregion

//#region unitil
const unitilSettings: BrandConfigSettings = {
  ...defaultSettings,
  utilityName: "Unitil",
  programName: "UNITIL",
  headerImageAutoScale: false,
  dashboardEnableOverride: true,
  testMode: true,
  addVehicleHiddenMakes: ["FORD", "LINCOLN"],
};

const unitilEnglishText: BrandConfigText = {
  ...defaultEnglishText,
  websiteTitle: "Unitil Smart Charging Rewards",
  websiteMetaDescription: "Unitil Smart Charging Rewards",
  headerLeftImageAlt: "Smart Charging Rewards",
  headerRightImageAlt: "Unitil",
  termsAndConditionsLink: "",
  termsAndConditionsLinkText: "",
  accountCreatedHeadlineOne: "You have an Smart Charging Rewards account.",
  rewardsCards: []
};

const unitilSpanishText: BrandConfigText = {
  ...defaultSpanishText,
  websiteTitle: "Recompensas de Carga Inteligente de Unitil",
  websiteMetaDescription: "Unitil Recompensas de Carga Inteligente",
  headerLeftImageAlt: "Recompensas de Carga Inteligente",
  headerRightImageAlt: "",
  termsAndConditionsLink: "",
  termsAndConditionsLinkText: "",
  accountCreatedHeadlineOne: "",
};

const unitilEnglish: BrandConfig = {
  ...unitilSettings,
  ...unitilEnglishText,
};

const unitilSpanish: BrandConfig = {
  ...unitilSettings,
  ...unitilSpanishText,
};

//#endregion

//#region Jones Onslow EMC
const joemcSettings: BrandConfigSettings = {
  ...defaultSettings,
  utilityName: "Jones-Onslow EMC",
  programName: "JOEMC",
  headerImageAutoScale: false,
  dashboardEnableOverride: true,
  testMode: true,
  addVehicleHiddenMakes: ["FORD", "LINCOLN"],
};

const joemcEnglishText: BrandConfigText = {
  ...defaultEnglishText,
  websiteTitle: "Jones-Onslow EMC Smart Charging Rewards",
  websiteMetaDescription: "Jones-Onslow EMC Smart Charging Rewards",
  headerLeftImageAlt: "Smart Charging Rewards",
  headerRightImageAlt: "Jones-Onslow EMC",
  termsAndConditionsLink: "",
  termsAndConditionsLinkText: "",
  accountCreatedHeadlineOne: "You have an Smart Charging Rewards account.",
  rewardsCards: []
};

const joemcSpanishText: BrandConfigText = {
  ...defaultSpanishText,
  websiteTitle: "Recompensas de Carga Inteligente de Jones-Onslow EMC",
  websiteMetaDescription: "Jones-Onslow EMC Recompensas de Carga Inteligente",
  headerLeftImageAlt: "Recompensas de Carga Inteligente",
  headerRightImageAlt: "",
  termsAndConditionsLink: "",
  termsAndConditionsLinkText: "",
  accountCreatedHeadlineOne: "",
};

const joemcEnglish: BrandConfig = {
  ...joemcSettings,
  ...joemcEnglishText,
};

const joemcSpanish: BrandConfig = {
  ...joemcSettings,
  ...joemcSpanishText,
};

//#endregion

//#region OPALCO
const opalcoSettings: BrandConfigSettings = {
  ...defaultSettings,
  utilityName: "Orca's Power & Light Cooperative",
  programName: "OPALCO",
  headerImageAutoScale: false,
  dashboardEnableOverride: true,
  testMode: true,
  hasPhoneNumberInput: true,
  phoneNumberRequired: true,
  hasAccountIdInput: true,
  accountIdRequired: true,
  addVehicleHiddenMakes: ["FORD", "LINCOLN"],
};

const opalcoEnglishText: BrandConfigText = {
  ...defaultEnglishText,
  websiteTitle: "Orca's Power & Light Cooperative Smart Charging Rewards",
  websiteMetaDescription: "Orca's Power & Light Cooperative Smart Charging Rewards",
  headerLeftImageAlt: "Smart Charging Rewards",
  headerRightImageAlt: "Orca's Power & Light Cooperative",
  termsAndConditionsLink: "",
  termsAndConditionsLinkText: "",
  accountCreatedHeadlineOne: "You have an Smart Charging Rewards account.",
  rewardsCards: []
};

const opalcoSpanishText: BrandConfigText = {
  ...defaultSpanishText,
  websiteTitle: "Recompensas de Carga Inteligente de Orca's Power & Light Cooperative",
  websiteMetaDescription: "Orca's Power & Light Cooperative Recompensas de Carga Inteligente",
  headerLeftImageAlt: "Recompensas de Carga Inteligente",
  headerRightImageAlt: "",
  termsAndConditionsLink: "",
  termsAndConditionsLinkText: "",
  accountCreatedHeadlineOne: "",
};

const opalcoEnglish: BrandConfig = {
  ...opalcoSettings,
  ...opalcoEnglishText,
};

const opalcoSpanish: BrandConfig = {
  ...opalcoSettings,
  ...opalcoSpanishText,
};

//#endregion

//#region KPCOOP
const kpcoopSettings: BrandConfigSettings = {
  ...defaultSettings,
  utilityName: "Kandiyohi Power Cooperative",
  programName: "KPCOOP",
  headerImageAutoScale: false,
  dashboardEnableOverride: true,
  hasPhoneNumberInput: true,
  hasAccountIdInput: true,
  addVehicleHiddenMakes: ["FORD", "LINCOLN"],
};

const kpcoopEnglishText: BrandConfigText = {
  ...defaultEnglishText,
  websiteTitle: "Kandiyohi Power Cooperative Smart Charging Rewards",
  websiteMetaDescription: "Kandiyohi Power Cooperative Smart Charging Rewards",
  headerLeftImageAlt: "Smart Charging Rewards",
  headerRightImageAlt: "Kandiyohi Power Cooperative",
  termsAndConditionsLink: "",
  termsAndConditionsLinkText: "",
  accountCreatedHeadlineOne: "You have an Smart Charging Rewards account.",
  rewardsCards: []
};

const kpcoopSpanishText: BrandConfigText = {
  ...defaultSpanishText,
  websiteTitle: "Recompensas de Carga Inteligente de Kandiyohi Power Cooperative",
  websiteMetaDescription: "Kandiyohi Power Cooperative Recompensas de Carga Inteligente",
  headerLeftImageAlt: "Recompensas de Carga Inteligente",
  headerRightImageAlt: "",
  termsAndConditionsLink: "",
  termsAndConditionsLinkText: "",
  accountCreatedHeadlineOne: "",
};

const kpcoopEnglish: BrandConfig = {
  ...kpcoopSettings,
  ...kpcoopEnglishText,
};

const kpcoopSpanish: BrandConfig = {
  ...kpcoopSettings,
  ...kpcoopSpanishText,
};

//#endregion

//United Power Region 
const unitedpowerSettings: BrandConfigSettings = {
  ...defaultSettings,
  utilityName: "United Power",
  programName: "UNITEDPOWER",
  headerImageAutoScale: false,
  dashboardEnableOverride: true,
  testMode: true,
  hasPhoneNumberInput: true,
  hasAccountIdInput: true,
  addVehicleHiddenMakes: ["FORD", "LINCOLN"],
};

const unitedpowerEnglishText: BrandConfigText = {
  ...defaultEnglishText,
  websiteTitle: "United Power Smart Charging Rewards",
  websiteMetaDescription: "United Power Smart Charging Rewards",
  headerLeftImageAlt: "Smart Charging Rewards",
  headerRightImageAlt: "United Power",
  termsAndConditionsLink: "",
  termsAndConditionsLinkText: "",
  accountCreatedHeadlineOne: "You have an Smart Charging Rewards account.",
  rewardsCards: []
};

const unitedpowerSpanishText: BrandConfigText = {
  ...defaultSpanishText,
  websiteTitle: "Recompensas de Carga Inteligente de United Power",
  websiteMetaDescription: "United Power Recompensas de Carga Inteligente",
  headerLeftImageAlt: "Recompensas de Carga Inteligente",
  headerRightImageAlt: "",
  termsAndConditionsLink: "",
  termsAndConditionsLinkText: "",
  accountCreatedHeadlineOne: "",
};

const unitedpowerEnglish: BrandConfig = {
  ...unitedpowerSettings,
  ...unitedpowerEnglishText,
};

const unitedpowerSpanish: BrandConfig = {
  ...unitedpowerSettings,
  ...unitedpowerSpanishText,
};

//#endregion

//PNM Region 
const pnmSettings: BrandConfigSettings = {
  ...defaultSettings,
  utilityName: "PNM",
  programName: "pnm",
  headerImageAutoScale: false,
  dashboardEnableOverride: true,
  dashboardDemandResponse: false,
  hasPhoneNumberInput: true,
  hasAccountIdInput: true,
  testMode: true,
  addVehicleHiddenMakes: ["FORD", "LINCOLN"],
};

const pnmEnglishText: BrandConfigText = {
  ...defaultEnglishText,
  websiteTitle: "PNM Managed Charging Rewards",
  websiteMetaDescription: "PNM Managed Charging Rewards",
  headerLeftImageAlt: "Managed Charging Rewards",
  headerRightImageAlt: "United Power",
  termsAndConditionsLink: "",
  termsAndConditionsLinkText: "",
  accountCreatedHeadlineOne: "You have a Managed Charging Rewards account.",
  rewardsCards: [
    {
      title: "Enrollment Reward",
      amount: "$75",
      text: "Get $75 for signing up and an additional $75 for your second year of participation!  Must not opt out of charging more than 15% of the time.",
      subtext: "",
      row: 0,
    }
  ]
};

const pnmSpanishText: BrandConfigText = {
  ...defaultSpanishText,
  websiteTitle: "Recompensas de Carga Inteligente de PNM",
  websiteMetaDescription: "PNM Recompensas de Carga Inteligente",
  headerLeftImageAlt: "Recompensas de Carga Inteligente",
  headerRightImageAlt: "",
  termsAndConditionsLink: "",
  termsAndConditionsLinkText: "",
  accountCreatedHeadlineOne: "Tienes una cuenta de Recompensas de Carga Inteligente",
  rewardsCards: [
    {
      title: "Recompensa de Inscripción",
      amount: "$75",
      text: "¡Obtenga $75 por inscribirse y $75 adicionales por su segundo año de participación! No debe optar por no cargar más del 15% del tiempo.",
      subtext: "",
      row: 0,
    }
  ]
};

const pnmEnglish: BrandConfig = {
  ...pnmSettings,
  ...pnmEnglishText,
};

const pnmSpanish: BrandConfig = {
  ...pnmSettings,
  ...pnmSpanishText,
};

//#endregion

//#region Mountain View Electric Association
const mveaSettings: BrandConfigSettings = {
  ...defaultSettings,
  utilityName: "Mountain View Electric Association",
  programName: "MVEA",
  headerImageAutoScale: true,
  dashboardEnableOverride: true,
  testMode: true,
  addVehicleHiddenMakes: ["FORD", "LINCOLN"],
  hasAccountIdInput: true,
  accountIdRequired: false
};

const mveaEnglishText: BrandConfigText = {
  ...defaultEnglishText,
  websiteTitle: "Mountain View Electric Association Managed Charging Rewards",
  websiteMetaDescription: "Mountain View Electric Association Managed Charging Rewards",
  headerLeftImageAlt: "Managed Charging Rewards",
  headerRightImageAlt: "Mountain View Electric Association",
  termsAndConditionsLink: "",
  termsAndConditionsLinkText: "",
  accountCreatedHeadlineOne: "You have an Managed Charging Rewards account.",
  headerProgramNameLineOne: "Managed Charging",
  headerProgramNameLineTwo: "Rewards",
  rewardsCards: []
};

const mveaSpanishText: BrandConfigText = {
  ...defaultSpanishText,
  websiteTitle: "Recompensas de Carga Inteligente de Mountain View Electric Association",
  websiteMetaDescription: "Mountain View Electric Association Recompensas de Carga Inteligente",
  headerLeftImageAlt: "Recompensas de Carga Inteligente",
  headerRightImageAlt: "",
  termsAndConditionsLink: "",
  termsAndConditionsLinkText: "",
  accountCreatedHeadlineOne: "",
  headerProgramNameLineOne: "Recompensas de Carga",
  headerProgramNameLineTwo: "Inteligente",
};

const mveaEnglish: BrandConfig = {
  ...mveaSettings,
  ...mveaEnglishText,
};

const mveaSpanish: BrandConfig = {
  ...mveaSettings,
  ...mveaSpanishText,
};


// dictionary of brand code to brand config
const brandConfig = {
  'evision': {
    en: evisionEnglish,
    es: evisionSpanish
  },
  'my': {
    en: myEnglish,
    es: mySpanish
  },
  'pvrea': {
    en: pvreaEnglish,
    es: pvreaSpanish
  },
  'pvrea-dr': {
    en: pvreaDrEnglish,
    es: pvreaDrSpanish
  },
  'vec': {
    en: vermontEnglish,
    es: vermontSpanish
  },
  'coopusa': {
    en: coopusaEnglish,
    es: coopusaSpanish
  },
  'mvec': {
    en: minnesotaValleyEnglish,
    es: minnesotaValleySpanish
  },
  'eversource': {
    en: eversourceEnglish,
    es: eversourceSpanish
  },
  'bpe': {
    en: bpeEnglish,
    es: bpeSpanish
  },
  'bpe-demo': {
    en: bpedemoEnglish,
    es: bpedemoSpanish
  },
  'epb': {
    en: epbEnglish,
    es: epbSpanish
  },
  'core': {
    en: coreEnglish,
    es: coreSpanish
  },
  'sdge': {
    en: sdgeEnglish,
    es: sdgeSpanish
  },
  'beta': {
    en: betaEnglish,
    es: betaSpanish
  },
  'wh': {
    en: whEnglish,
    es: whSpanish
  },
  "ireland": {
    en: irelandEnglish,
    es: irelandSpanish
  },
  "connexus": {
    en: connexusEnglish,
    es: connexusSpanish
  },
  "wrecc": {
    en: wreccEnglish,
    es: wreccSpanish
  },
  "coserv": {
    en: coservEnglish,
    es: coservSpanish
  },
  "sussexrec": {
    en: sussexrecEnglish,
    es: sussexrecSpanish
  },
  "ucs": {
    en: ucsEnglish,
    es: ucsSpanish
  },
  "trico": {
    en: tricoEnglish,
    es: tricoSpanish
  },
  "dakota": {
    en: dakotaEnglish,
    es: dakotaSpanish
  },
  "gvec": {
    en: gvecEnglish,
    es: gvecSpanish
  },
  "joemc": {
    en: joemcEnglish,
    es: joemcSpanish
  },
  "unitil": {
    en: unitilEnglish,
    es: unitilSpanish
  },
  "opalco": {
    en: opalcoEnglish,
    es: opalcoSpanish
  },
  "kpcoop": {
    en: kpcoopEnglish,
    es: kpcoopSpanish
  },
  "unitedpower": {
    en: unitedpowerEnglish,
    es: unitedpowerSpanish
  },
  "pnm": {
    en: pnmEnglish,
    es: pnmSpanish
  },
  "mvea": {
    en: mveaEnglish,
    es: mveaSpanish
  }
};

export type BrandConfigKey = keyof typeof brandConfig;

export const getBrandConfig: (brandKey?: BrandConfigKey) => BrandConfig = (brandKey?: BrandConfigKey) => {

  // brand cookie is for handling the band on the add vehicle page
  const brandCookie = window.location.hostname === "evision.flexcharging.com" ? Cookies.get('brand')?.toLowerCase() : undefined;
  const brand = brandKey || brandCookie || getEnv().BRAND?.toLowerCase() || 'coopusa';

  // check if brandConfig has a key brandKey
  if (!brandConfig[brand as BrandConfigKey]) {
    // this isn't a site, just go to flexcharging.com    
    window.location.href = "https://www.flexcharging.com";
  }
  return brandConfig[brand as BrandConfigKey][getCurrentLanguageCode()];
};

export const isValidBrandConfigKey = (key: string): key is BrandConfigKey => {
  return key in brandConfig;
};

export const getAllBrandConfigs = () => {
  return Object.keys(brandConfig).map((key) => {
    return brandConfig[key as BrandConfigKey][getCurrentLanguageCode()];
  });
};

export const ADMIN_BRAND = "admin";

export const getImageURL = (type: "logo" | "rewardsProgram" | "addLocation" | "vehicleConnected" | "rewardsSuccess", brand: string) => {
  // admin program maps the evision website
  const folder = brand === ADMIN_BRAND ? "evision" : brand.toLocaleLowerCase();
  return `https://assets.flexcharging.com/${folder}/evision/${type}@2x.png`;
};


