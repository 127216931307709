export const ResponsiveLayout = ({ children }:
  {
    children: JSX.Element;
  }) => {
  // at medium, we are in the middle 10 columns of the 12 column grid
  return (
    <div className="md:grid md:grid-cols-8">
      <div className="md:col-start-2 md:col-span-6">
        {children}
      </div>
    </div>
  );
};
