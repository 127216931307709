import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './rootState';
import { useSelector } from 'react-redux';
import { DemandResponseEventAndMembersDTO } from '../apis/energyNetTypes';
import { resetState } from './actions';

export interface DemandResponseState {
  demandResponseEventAndMembers?: DemandResponseEventAndMembersDTO[];
  loading: boolean;
};

const initialState: DemandResponseState = {
  demandResponseEventAndMembers: undefined,
  loading: true
};

type DemandResponseEventOptOut = {
  eventId: string;
  optOut: boolean;
};

const demandResponseSlice = createSlice({
  name: 'demandResponseSlice',
  initialState,
  reducers: {
    updateDemandResponseEventAndMembers: (state, action: PayloadAction<DemandResponseEventAndMembersDTO[] | undefined>) => {
      state.demandResponseEventAndMembers = action.payload;
    },
    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    updateDemandResponseEventOptOut: (state, action: PayloadAction<DemandResponseEventOptOut>) => {
      if (state.demandResponseEventAndMembers) {
        const event = state.demandResponseEventAndMembers.find((event) => event.DemandResponseEvent.EventId === action.payload.eventId);
        if (event) {
          event.Members.forEach((member) => {
            member.OptedOut = action.payload.optOut;
          });
        }
      }
    }
  },
  extraReducers: (builder) => builder.addCase(resetState, () => initialState),
});

export const { updateDemandResponseEventAndMembers, updateLoading: updateDemandResponseLoading, updateDemandResponseEventOptOut } = demandResponseSlice.actions;
export default demandResponseSlice.reducer;

export const useDemandResponseEventAndMembers = (): DemandResponseEventAndMembersDTO[] | undefined => useSelector((state: RootState) => state.demandResponseSlice.demandResponseEventAndMembers);
export const useDemandResponseEventAndMembersLoading = (): boolean => useSelector((state: RootState) => state.demandResponseSlice.loading);
