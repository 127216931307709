import React, { ChangeEventHandler } from 'react';

const Checkbox = ({ selected, id, onChange }: { selected: boolean, id: string, onChange?: ChangeEventHandler<HTMLInputElement> }) => {
  return (
    <input
      className={`relative inline-block mt-[2px] mr-[15px] h-[24px] w-[24px]                    
      border border-solid border-action border-transparent ring-action ring-1 ring-inset bg-white
      appearance-none outline-none before:pointer-events-none                                         
      checked:border-action checked:bg-action checked:before:opacity-[0.16] checked:after:absolute 
      checked:after:mt-[2px] checked:after:ml-[8px] checked:after:block checked:after:h-[0.8125rem] 
      checked:after:scale-150
      checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:border-[0.125rem] checked:after:border-l-0 
      checked:after:border-t-0 checked:after:border-solid checked:after:border-white checked:after:bg-transparent 
      checked:after:content-[''] 
      hover:cursor-pointer
      focus:outline-black
    `}
      type="checkbox"
      name={id}
      id={id}
      checked={selected}
      onChange={onChange || (() => { })}
    />
  );
};

export default Checkbox;