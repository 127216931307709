import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './rootState';
import { useSelector } from 'react-redux';
import { ChargingLocationDTO } from '../apis/energyNetTypes';
import { resetState } from './actions';

export interface ChargingLocationState {
  chargingLocation?: ChargingLocationDTO;
  loading: boolean;
}

const initialState: ChargingLocationState = {
  chargingLocation: undefined,
  loading: false
};

const chargingLocationSlice = createSlice({
  name: 'chargingLocationSlice',
  initialState,
  reducers: {
    updateChargingLocation: (state, action: PayloadAction<ChargingLocationDTO | undefined>) => {
      state.chargingLocation = action.payload;
    },
    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => builder.addCase(resetState, () => initialState),
});

export const { updateChargingLocation, updateLoading: updateChargingLocationLoading } = chargingLocationSlice.actions;
export default chargingLocationSlice.reducer;

export const useChargingLocation = (): ChargingLocationDTO | undefined => useSelector((state: RootState) => state.chargingLocationSlice.chargingLocation);
export const useChargingLocationLoading = (): boolean => useSelector((state: RootState) => state.chargingLocationSlice.loading);

